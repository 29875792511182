import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaBriefcase, FaMoneyBill, FaUsers } from "react-icons/fa";
import { MdOutlineTransgender } from "react-icons/md";
import {RiMedalFill} from "react-icons/ri"
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../components/Button";
import { useAppSelector } from "../../hooks/hook";
import careerService from "../../services/career";
import { Recruit } from "../../types";
import { hostBE } from "../../types/host";


export default function DetailCareer () {
    const [t] = useTranslation();
    const navigate = useNavigate();
    const [careerData, setCareer] = useState<Recruit>();
    const params = useParams();
    const translate = useAppSelector(state => state.translateSlice);
    const showModalCreer = () => {
        navigate(`/tuyen-dung/bieu-mau/${params.id+""}`)

    }

    useEffect(()=> {
        careerService.getById(parseInt(params.id+"")).then((data)=> {
            setCareer(data);
        })
    }, [params])


    const dayExpires = useMemo(()=> {
   
        return (new Date(careerData?.dateExpiration+"").getTime() - Date.now())/(1000*60*60*24)
    }, [careerData])


    return (
        <div>
            <div className="p-[24px] rounded-xl bg-[#F0F4FF] flex justify-between sc991:flex-col sc991:items-center">
                <div>
                        <h5 className="lssm:text-px20 sc>768:text-center md:text-[32px] text-black-color font-bold">{translate.isEnglish ? careerData?.titleVi : careerData?.titleEn}</h5>
                        <ul className="lssm:text-px14 md:text-px16 ml-[24px] list-disc mt-[24px]">
                            <li>{dayExpires < 0 ? t('career.detail.oneDay') : t("career.detail.moreDay", {day: Math.ceil(dayExpires)})}</li>
                        </ul>
                </div>
                <div className="flex items-center sc991:mt-[24px]">
                      <Button color="primary" className="h-[60px] font-bold text-px16 min-w-[220px]" onClick={showModalCreer}>
                                <span className="flex h-fit"><img src={`${hostBE}/fe/tuyen_dung.svg`} className="mr-[24px]" alt="" /> {t("career.btn_recruit")}</span>
                    </Button>

                </div>
            </div>

            <div className="sm:px-[24px] mt-[70px]">
                    <h5 className="text-[32px] font-bold text-black-color mb-[40px]">{t("career.detail.Job")}</h5>
                    <div className="p-[24px] rounded-xl bg-[#F0F4FF]">
                            <h6 className="text-px20 font-bold w-fit border-b-[1px] border-solid border-text-primary text-text-primary">{t("career.detail.information")}</h6>
                            <div className="lssm:mt-[10px] md:mt-[38px] grid lssm:grid-cols-1 lsm-380:grid-cols-2 gap-y-[32px]">
                                    <div className="flex items-center">
                                            <div className=" lssm:w-[30px] lssm:h-[30px] sm:w-[70px] sm:h-[70px] flex items-center text-primary lssm:text-px14 sm:text-px20 md:text-[32px] bg-white-color rounded-[50%] lssm:mr-[6px] md:mr-[25px] justify-center">
                                                  <FaMoneyBill />
                                            </div>
                                            <div className="lssm:text-[14px] sm:text-px16">
                                                <h5 className="font-bold lssm:text-px14 md:text-px20">{t('career.detail.wage')}</h5>
                                                <p>{translate.isEnglish ? careerData?.salaryVi.match(/^[0-9]{0,}$/) ? new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(parseInt(careerData.salaryVi+"")) : careerData?.salaryVi :
                                                careerData?.salaryEn.match(/^[0-9]{0,}$/) ? new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(parseInt(careerData.salaryEn+"")) : careerData?.salaryEn
                                                
                                            }</p>
                                            </div>
                                    </div>

                                    <div className="flex items-center">
                                    <div className=" lssm:w-[30px] lssm:h-[30px] sm:w-[70px] sm:h-[70px] flex items-center text-primary lssm:text-px14 sm:text-px20 md:text-[32px] bg-white-color rounded-[50%] lssm:mr-[6px] md:mr-[25px]  justify-center">
                                                  <FaUsers />
                                            </div>
                                            <div className="lssm:text-[14px] sm:text-px16">
                                                <h5 className="font-bold lssm:text-px14 md:text-px20">{t('career.detail.quantity')}</h5>
                                                <p>{careerData?.numPerson}</p>
                                            </div>
                                    </div>

                                    <div className="flex items-center">
                                    <div className=" lssm:w-[30px] lssm:h-[30px] sm:w-[70px] sm:h-[70px] flex items-center text-primary lssm:text-px14 sm:text-px20 md:text-[32px] bg-white-color rounded-[50%] lssm:mr-[6px] md:mr-[25px]  justify-center">
                                                  <FaBriefcase />
                                            </div>
                                            <div className="lssm:text-[14px] sm:text-px16">
                                                <h5 className="font-bold lssm:text-px14 md:text-px20">{t('career.detail.working')}</h5>
                                                <p >{translate.isEnglish ? careerData?.workingFormVi : careerData?.workingFormEn}</p>
                                            </div>
                                    </div>

                                    <div className="flex items-center">
                                            <div className=" lssm:w-[30px] lssm:h-[30px] sm:w-[70px] sm:h-[70px] flex items-center text-primary lssm:text-px14 sm:text-px20 md:text-[32px] bg-white-color rounded-[50%] lssm:mr-[6px] md:mr-[25px]  justify-center">
                                                  <RiMedalFill />
                                            </div>
                                            <div className="lssm:text-px16 sm:text-px16">
                                                <h5 className="font-bold lssm:text-px14 md:text-px20">{t('career.detail.level')}</h5>
                                                <p>{translate.isEnglish ? careerData?.levelVi : careerData?.levelEn}</p>
                                            </div>
                                    </div>

                                    
                                    <div className="flex items-center">
                                            <div className=" lssm:w-[30px] lssm:h-[30px] sm:w-[70px] sm:h-[70px] flex items-center text-primary lssm:text-px14 sm:text-px20 md:text-[32px] bg-white-color rounded-[50%] lssm:mr-[6px] md:mr-[25px]  justify-center">
                                                  <MdOutlineTransgender />
                                            </div>
                                            <div className="lssm:text-px14 sm:text-px16">
                                                <h5 className="font-bold lssm:text-px14 md:text-px20">{t('career.detail.gender')}</h5>
                                                <p>{translate.isEnglish ? careerData?.genderVi : careerData?.genderEn}</p>
                                            </div>
                                    </div>

                                    
                                    <div className="flex items-center">
                                            <div className="lssm:w-[30px] lssm:h-[30px] sm:w-[70px] sm:h-[70px] flex items-center bg-white-color rounded-[50%] lssm:mr-[6px] md:mr-[25px]  justify-center">
                                                    <img src={`${hostBE}/fe/exprientce_svg.svg`} className="lsm:max-w-[8px] h-auto  object-cover sm:max-w-auto" alt="" />
                                            </div>
                                            <div className="lssm:text-[14px] sm:text-px16">
                                                <h5 className="font-bold lssm:text-px14 md:text-px20">{t('career.detail.experience')}</h5>
                                                <p>{translate.isEnglish ? careerData?.experienceVi : careerData?.experienceEn}</p>
                                            </div>
                                    </div>
                            </div>
                    </div>

                    <div className="p-[24px] pb-[50px] rounded-xl mt-[24px] bg-[#F0F4FF]">
                        <h6 className="lssm:text-px16 md:text-px20 font-bold w-fit border-b-[1px] border-solid border-text-primary text-text-primary">{t("career.detail.address")}</h6>
                        <p className="lssm:text-px14  text-px16 lssm:mt-[10px] md:mt-[38px]">{ translate.isEnglish ? careerData?.workplaceVi : careerData?.workplaceEn}</p>
                    </div>


                    <div className="p-[24px] pb-[50px] rounded-xl mt-[24px] bg-[#F0F4FF]">
                        <h6 className="lssm:text-px14 md:text-px20 font-bold w-fit border-b-[1px] border-solid border-text-primary text-text-primary">{t("career.detail.description")}</h6>
    
                        <pre  className="lssm:text-px14 md:text-px16 lssm:mt-[10px] md:mt-[38px] whitespace-pre-wrap">
                            {
                                translate.isEnglish ? careerData?.describeVi : careerData?.describeEn 
                            }
                        </pre>
                    </div>

                    <div className="grid grid-cols-2 sc<992:gap-6 sc991:grid-cols-1">
                    <div className="p-[24px] pb-[50px] rounded-xl mt-[24px] bg-[#F0F4FF]">
                        <h6 className="lssm:text-px14 md:text-px20 font-bold border-b-[1px] w-fit border-solid border-text-primary text-text-primary">{t("career.detail.required")}</h6>
                        <pre className="lssm:text-px14 md:text-px16 lssm:mt-[10px] md:mt-[38px] whitespace-pre-wrap">
                        {
                            translate.isEnglish ? careerData?.requireVi + "" : careerData?.requireEn + ""
                        }

                        </pre>
                    </div>
                    <div className="p-[24px] pb-[50px] rounded-xl mt-[24px] bg-[#F0F4FF]">
                        <h6 className="lssm:text-px14 md:text-px20 font-bold w-fit border-b-[1px] border-solid border-text-primary text-text-primary">{t("career.detail.right")}</h6>
                        <pre className="lssm:text-px14 md:text-px16 lssm:mt-[10px] md:mt-[38px] whitespace-pre-wrap">
                        {
                             translate.isEnglish ? careerData?.interestVi + "" : careerData?.interestEn + ""
                        }
                        </pre>
                    </div>

                    </div>  
            </div>
        </div>
    ) 
}