import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Pagination from "../../components/Pagination";
import ProjectItem from "../../components/ProjectItem";
import { useAppDispatch, useAppSelector } from "../../hooks/hook"
import { getProjects, setCurrentPage } from "../../reducers/project";
const LIMIT = 10;
export default function Project () {
    const projectData = useAppSelector(state => state.projectSlice);
    const dispatch = useAppDispatch();
    const translate = useAppSelector(state => state.translateSlice);
    const [t] = useTranslation()
    const total = useMemo(() => {

        return Math.ceil(projectData.total/LIMIT)
    }, [projectData.total])

    useEffect(() => {
        dispatch(getProjects({
            page: projectData.currentPage,
            limit: LIMIT
        }))
    }, [projectData.currentPage])

    return   <div className="2xl:px-[162px] mb-[100px] sc991:px-[24px] sc<992:px-[24px]">
        <div className="flex justify-center">
        <h2 className="text-text-primary lssm:text-px20 uppercase w-fit sm-480:text-px20 md:text-[48px] font-bold  lssm:mb-[30px] lg:mb-[133px] pl-3">{t("project.title")}</h2>

            </div>
        <div className="grid sc<992:grid-cols-2 sc991:grid-cols-1 text-text-gray sc<992:gap-x-6 xl:gap-x-9 w-1920:gap-x-[45px]">
            {
                projectData.projects.length > 0 ?
                projectData.projects.map((project) => {
                    return <ProjectItem key={project.id} project={project} />
                }): <div className="h-[300px] col-span-2 flex justify-center items-center">{translate.isEnglish ? "Không có dữ liệu" : "No data"}</div>
            }

            </div>
            {
                projectData.projects.length > 0  && (
                     <div className="mt-[50px]">
                    <Pagination currenPage={projectData.currentPage} setCurrentPage={setCurrentPage} total={total} />
                    </div>

                )
            }

        

</div>
}