import clsx from "clsx";
import { useTranslation } from "react-i18next";
import Button from "../../components/Button";
import useInView from "../../hooks/useInView";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { hostBE } from "../../types/host";
import { showModal } from "../../reducers/modal";
import BourchureIndex from "../../components/Brouchure/BourchureIndex";

export default function General() {
  const [t] = useTranslation();
  const topInView = useInView();
  const bottomInView = useInView();
  const dispatch = useAppDispatch();
  const translate = useAppSelector((state) => state.translateSlice);

  const showBouchure = () => {
    dispatch(showModal(<BourchureIndex />));
  };

  return (
    <div className="relative h-auto">
      <h2 className="lssm:text-px20 md:text-[40px] xl:text-[48px] uppercase text-text-title text-center font-bold my-[95px] sc>768:my-[40px] animate__animated  animate__fadeInDown">
        {t("about.general.title")}
      </h2>
      <div className="mt-[60px] ">
        <div className="flex sc991:flex-col text-justify">
          <div className="flex-1 animate__animated animate__fadeInLeft">
            <img
              src={`${hostBE}/fe/gioithieuAnnfico.JPG`}
              className="sc991:w-full rounded-xl"
              alt=""
            />
          </div>
          <div className="flex-1 sc<992:ml-[50px]  animate__animated animate__fadeInRight">
            <h3 className="lssm:mb-[20px] sc>768:text-center md:mb-[40px] text-[32px] sc>768:mt-[24px] sc>768:text-px16 text-text-primary uppercase font-medium">
              {t("about.general.about_us")}
            </h3>
            <div
              className="lssm:text-px14 md:text-px16 text-justify"
              dangerouslySetInnerHTML={{
                __html: t("about.general.we_description"),
              }}
            ></div>
            {/* <div className="mt-[40px]">
              <h3 className="mt-[24px] mb-[40px] text-[32px] sc>768:text-px16 sc>768:text-center text-text-primary uppercase font-medium">
                {t("about.general.slide_introduction")}
              </h3>
              <div
                className="w-max cursor-pointer sc>768:w-[100%] sc>768:flex sc>768:flex-col sc>768:items-center"
                onClick={showBouchure}
              >
                <div>
                  <img
                    src={`${hostBE}/fe/${
                      translate.isEnglish ? "brochure" : "brochureEn"
                    }/1.jpg`}
                    alt=""
                    className="w-[210px] h-[300px] object-cover"
                  />
                </div>
                <p className="uppercase lssm:text-px16 md:text-[32px] text-text-primary mt-[24px] font-medium text-center">
                  BROCHURE{" "}
                </p>
              </div>
            </div> */}
          </div>
        </div>

        <div
          className="flex sc991:flex-col sc991:mt-[100px] sc<992:mt-[100px] md:mb-[80px]"
          ref={topInView.ref}
        >
          <div
            className={clsx("flex-1 sc991:order-2", {
              "animate__animated animate__fadeInLeft": topInView.isInView,
            })}
          >
            <h3 className="mt-[24px] lssm:mb-[20px] md:mb-[40px] text-[32px] sc>768:text-center sc>768:text-px16 text-text-primary uppercase font-medium">
              {t("about.general.product_us")}
            </h3>
            <pre className="whitespace-pre-wrap lssm:text-px14 md:text-px16  text-justify">
              {t("about.general.product_us_description")}
            </pre>
            <div className="flex flex-col items-start lssm:text-px14 md:text-px16  text-justify">
              <p>{t("about.general.products_us.item1")}</p>
              <p>{t("about.general.products_us.item2")}</p>
              <p>{t("about.general.products_us.item3")}</p>
              <p>{t("about.general.products_us.item4")}</p>
            </div>
          </div>

          <div
            className={clsx("flex-1 sc<992:ml-[50px] sc991:order-1", {
              "animate__animated animate__fadeInRight": topInView.isInView,
            })}
          >
            <img
              src={`${hostBE}/fe/introduce_product.jpg`}
              className="rounded-xl"
              alt=""
            />
          </div>
        </div>
        <div
          className="w-full lssm:mb-[21px] md:mb-[147px]"
       
        >
          <div className="lssm:mt-[21px] md:mt-[40px] w-full mb-[24px]">
            <h3 className="mt-[24px] lssm:mb-[20px] md:mb-[40px] text-[32px] sc>768:text-px16 sc>768:text-center text-text-primary uppercase font-medium">
              {t("about.general.employe_count")}
            </h3>
            <pre className="w-full lssm:text-px14 text-justify whitespace-pre-wrap md:text-px16 ">
              {t("about.general.employe_count_description")}
            </pre>
          </div>
          <div ref={bottomInView.ref}>
          <img
            
            className={clsx("w-full rounded-[10px]", {
              "animate__animated animate__pulse": bottomInView.isInView,
            })}
            src={`${hostBE}/fe/members_bg.jpg`}
            alt=""
          />
            </div>
        </div>
      </div>

      <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-[-1]">
        <img src={`${hostBE}/fe/contac-world.png`} alt="" />
      </div>
    </div>
  );
}
