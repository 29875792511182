import { Link } from "react-router-dom";
import clsx from "clsx";
import { Project } from "../../types";
import getDate from "../../common/getDate";
import { useAppSelector } from "../../hooks/hook";

type Props = {
  type?: boolean;
  project: Project;
};

export default function ProjectItem({ type, project }: Props) {
  // const params = useParams();

  const translate = useAppSelector((state) => state.translateSlice);

  return (
    <Link
      to={`/du-an/${project.id}`}
      className={clsx(
        "flex pb-[38px] lssm:flex-col lsm-380:flex-row border-b-[1px] border-solid mb-[38px]",
        { "border-white-color": type, "border-border_primary": !type }
      )}
    >
      <div className="lssm:w-full lssm:flex lssm:justify-center lsm-380:block lsm-380:min-w-[150px] lsm-380:max-w-[150px] lssm:h-auto lsm-380:h-[100px] sm:h-auto sm:min-w-[303px] sm:max-w-[303px] max-h-auto rounded-[10px] overflow-hidden mr-[24px]">
        <img
          src={project.avatarUrl}
          alt="news1"
          className="w-full h-full max-h-[184px] rounded-[10px] object-cover"
        />
      </div>
      <div className="lssm:mt-3 lsm-380:mt-0">
        <h3
          className={clsx(
            "lssm:text-px16  sm:text-px18 lssm:mb-1 2xl:text-px20 line-clamp-2 xl:text-px18 sc>768:text-px18 font-bold xl:mb-[18px] 2xl:mb-[23px]",
            { "text-text-primary": !type, "text-white-color": type }
          )}
        >
          {translate.isEnglish
              ? project.titleVi
            : project.titleEn
            }
        </h3>
        <p className="lssm:text-px14 line-clamp-2 2xl:text-px16 md:text-px14">
          {translate.isEnglish

              ? project.descriptionVi
            : project.descriptionEn
            }
        </p>
      </div>
    </Link>
  );
}
