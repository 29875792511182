import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import getDate from "../../common/getDate";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { deleteCareer } from "../../reducers/career";
import { showModal } from "../../reducers/modal";
import { Recruit } from "../../types";
import { hostBE } from "../../types/host";
import Button from "../Button";
import DialogConfirm from "../DialogConfirm";

type Props = {
  career: Recruit;
};

export default function ManagerCareerItem({ career }: Props) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {isEnglish} = useAppSelector(state => state.translateSlice);
  const [t] = useTranslation();

  const navigateDetail = () => {
    navigate(`view/${career.id}`);
  };

  const showDialogConfirm = () => {
    dispatch(
      showModal(
        <DialogConfirm
          message="Bạn chắc chắn xóa tuyển dụng này không?"
          onClick={handleDelete}
        />
      )
    );
  };

  const handleDelete = () => {
    dispatch(deleteCareer(parseInt(career.id + "")));
  };

  return (
    <div className="flex justify-between xl:flex-row flex-col pb-[38px] mb-[38px] border-b-[1px] border-solid border-[#1d70b94d]">
      <div className="flex lssm:flex-col sm:flex-row lg:mr-3">
        <div className="flex items-center justify-center p-1 border-[1px] border-solid ] lssm:h-[200px] w-[250px] sc>768:w-auto rounded-md sm:mr-[25px]">
          <img
            src={career.avatarUrl ? career.avatarUrl : `${hostBE}/fe/anficoLogo.png`}
            className="w-full h-full rounded"
            alt="tuyen dung"
          />
        </div>
        <div className="text-px18 text-text-decoration lssm:mb-1 sm:mb-0">
          <h4 className="text-px20 mb-[30px] font-semibold lssm:mt-4 sm:mt-0 lssm:text-center sm:text-left">
            {isEnglish ? career.titleVi : career.titleEn}
          </h4>

          <p className="lssm:text-px14 md:lssm:text-px14 md:text-px16">
            {t('dashboard.recruitment.create.form.wage')}:{" "}
            <span className="text-[#DE2020] lssm:text-px14 md:text-px16">
            {isEnglish ? (career?.salaryVi.match(/^[0-9]{0,}$/) ? new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(parseInt(career?.salaryVi+"")) : career?.salaryVi) :
             (career?.salaryEn.match(/^[0-9]{0,}$/) ? new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(parseInt(career?.salaryEn+"")) : career?.salaryEn)
            }
            </span>
          </p>
          <p className="my-[12px] lssm:text-px14 md:text-px16">
          {t('dashboard.recruitment.create.form.date')}: {getDate(career.dateExpiration)}
          </p>
          <p className="lssm:text-px14 md:text-px16">{t('dashboard.recruitment.create.form.work_place')}: {isEnglish ? career.workplaceVi : career.workplaceEn}</p>
        </div>
      </div>

      <div className="flex lssm:flex-col sm:flex-row xl:flex-col lssm:items-center xl:justify-center lg:justify-between  justify-center xl:mt-0 mt-[50px]">
        <Button
          onClick={navigateDetail}
          color="primary"
          className="h-[60px] lssm:text-px14 md:text-px16 lssm:min-w-full sm:min-w-[280px]"
        >
          <span className="flex">
            <img
              src={`${hostBE}/fe/eye.png`}
              className="mr-[20px] object-contain"
              alt=""
            />{" "}
           {t('dashboard.recruitment.view_recui_btn')}
          </span>
        </Button>

        <Button
          onClick={showDialogConfirm}
          color="empty"
          className="lssm:ml-0 sm:ml-[24px] md:ml-4 lg:ml-0 py-[20px] text-text-primary mt-[24px] lssm:mt-[24px] sm:mt-0 xl:mt-[24px] sc991:mt-0 lssm:text-px14 md:text-px16 lssm:min-w-full sm:min-w-[280px] h-[60px]"
        >
          <div className="flex items-center justify-center ">
            <span className="mr-[20px]">
              <img src={`${hostBE}/fe/delete_icon.png`} alt="" />
            </span>{" "}
            {t('dashboard.recruitment.delete_recui_btn')}
          </div>
        </Button>
      </div>
    </div>
  );
}
