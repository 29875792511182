
import Button from "../../../components/Button"
import Input from "../../../components/InputImage"
import clsx from "clsx"
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import {Navigation, Thumbs, Zoom} from "swiper";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup"
import uploadService from "../../../services/uploadImage";
import { useAppDispatch } from "../../../hooks/hook";
import { createCareerSuccess } from "../../../reducers/career";
import { FaBriefcase, FaMoneyBill, FaUsers } from "react-icons/fa";
import { RiMedalFill } from "react-icons/ri";
import { MdAddPhotoAlternate, MdOutlineImage, MdOutlineTransgender } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import careerService from "../../../services/career";
import { pushPopup } from "../../../reducers/popupSlice";
import { useTranslation } from "react-i18next";

export default function CreateCareer() {
    const navigationPrevRef = React.useRef(null)
    const navigationNextRef = React.useRef(null)

    const [file, setFile] = useState<File | null>(null)
    const [urlImagePreview, setUrlPreview] = useState<string>("");
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [t] = useTranslation();


    const formik = useFormik({
        initialValues: {
            titleEn: "",
            titleVi: "",
            workplaceEn: "",
            workplaceVi: "",
            dateExpiration: "",
            describeVi: "",
            interestVi: "",
            requireVi: "",
            describeEn: "",
            interestEn: "",
            requireEn: "",
            salaryEn: "",
            salaryVi: "",
            numPerson: "",
            levelEn: "",
            levelVi: "",
            genderVi: "",
            genderEn: "",
            experienceVi: "",
            experienceEn: "",
            workingFormEn: "",
            workingFormVi: ""
        },
        validationSchema: Yup.object({
            titleEn: Yup.string().required(t("validate.error.required")),
            titleVi: Yup.string().required(t("validate.error.required")),
            workplaceEn: Yup.string().required(t("validate.error.required")),
            workplaceVi: Yup.string().required(t("validate.error.required")),
            dateExpiration: Yup.string().required(t("validate.error.required")),
            describeVi: Yup.string().required(t("validate.error.required")),
            interestVi: Yup.string().required(t("validate.error.required")),
            requireVi: Yup.string().required(t("validate.error.required")),
            describeEn: Yup.string().required(t("validate.error.required")),
            interestEn: Yup.string().required(t("validate.error.required")),
            requireEn: Yup.string().required(t("validate.error.required")),
            salaryVi: Yup.string().required(t("validate.error.required")),
            salaryEn: Yup.string().required(t("validate.error.required")),
            numPerson: Yup.string().required(t("validate.error.required")).matches(/^[0-9]*$/, t("validate.error.pattern",{name: t("dashboard.recruitment.create.form.number")})),
            levelVi: Yup.string().required(t("validate.error.required")),
            levelEn: Yup.string().required(t("validate.error.required")),
            genderVi: Yup.string().required(t("validate.error.required")),
            genderEn: Yup.string().required(t("validate.error.required")),
            experienceVi: Yup.string().required(t("validate.error.required")),
            experienceEn: Yup.string().required(t("validate.error.required")),
            workingFormVi: Yup.string().required(t("validate.error.required")),
            workingFormEn: Yup.string().required(t("validate.error.required")),
            
        }) ,
        onSubmit: async (values) => {
            const formData = new FormData();
            let fileUrl;
            if(file) {
                formData.append("file", file);
                 fileUrl = await uploadService.upload(formData)

            }
         
            careerService.create({
                ...values,
                avatarUrl: fileUrl?.list[0].image_url ?? "",
                avatarPath : fileUrl?.list[0].image_path ?? ""
            }).then((data) => {
                dispatch(createCareerSuccess(data))
                dispatch(pushPopup({
                    type: "SUCCESS",
                    message: t("notify.success", {name: t("button.add")})
                }));
                navigate(-1);
            }).catch(() => {

                    dispatch(pushPopup({
                        type: "ERROR",
                        message: t("notify.error", {name:  t("button.add")})
                    }));
            })

            

        }
    })



    const handleChangeFile = (event:ChangeEvent<HTMLInputElement>) => {
        const file = event.currentTarget.files![0]
        if(file.size < 26675200) {
            setFile(file)
            setUrlPreview(URL.createObjectURL(file));
        }else {
            dispatch(pushPopup({
                message: t("notify.min_image", {size: "25MB"}),
                type: "WARNING"
            }))
        }
    }

    const slideToTop = () => {
        window.scrollBy(0, -document.documentElement.scrollTop);
    }

    const onInputClick = (e:React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        e.currentTarget.value = ""
    }

    return <form onSubmit={formik.handleSubmit}>                   
                <Swiper
                    slidesPerView={1}
                    allowTouchMove={false}
                    navigation={{
                        // Both prevEl & nextEl are null at render so this does not work
                        prevEl: navigationPrevRef.current,
                        nextEl: navigationNextRef.current,
                    }}
                    onSwiper={(swiper:any) => {
                        // Delay execution for the refs to be defined
                        setTimeout(() => {
                        // Override prevEl & nextEl now that refs are defined
                        swiper.params.navigation.prevEl = navigationPrevRef.current
                        swiper.params.navigation.nextEl = navigationNextRef.current
                
                        // Re-init navigation
                        swiper.navigation.destroy()
                        swiper.navigation.init()
                        swiper.navigation.update()
                        })
                    }}
            
                    // navigation={true}
                    grabCursor={true}
                    modules={[ Navigation]}
                    className="w-full"
                >                   
                             <SwiperSlide className="w-full">
                                <div>
                                <h2 className="uppercase text-[48px] sc>768:text-px20 text-text-primary font-medium text-center py-[74px] lssm:pb-[32px] md:pb-[117px]">Tạo tin tuyển dụng mới</h2>

                                    <div className=" mb-[80px] flex">
                                        <div className="grid lssm:grid-cols-1 md:grid-cols-2 gap-x-5 w-full">
                                        <div className="md:col-span-2">
                                            <label className="lssm:text-px14 md:text-px20 text-text-primary font-bold mb-4 block">Tiêu đề tuyển dụng</label>
                                          <Input value={formik.values.titleVi} onChange={formik.handleChange}  name="titleVi" />
                                          {formik.errors.titleVi &&  <small className="mt-1 text-px12 text-error_color">{formik.errors.titleVi}</small>}
                                        </div>

                                        <div className="md:col-span-2 mt-[32px]">
                                            <label className="lssm:text-px14 md:text-px20 font-bold text-text-primary mb-4 block">Tải ảnh tuyển dụng</label>
                                            <div className="border border-solid border-border-color p-[20px] grid lssm:grid-cols-1 md:grid-cols-2 outline-none rounded-[5px]">
                                                    <div className="">
                                                            <p className="text-[#636363] mb-[30px] lssm:text-px14 md:text-px16 font-medium">{t("dashboard.recruitment.create.form.update_format")}</p>
                                                    
                                                        <label htmlFor='career-banner' className="sc>768:mt-2 lssm:w-full md:w-[220px] h-[120px] border-2 border-dashed border-primary text-text-primary flex flex-col items-center justify-center rounded-[5px]">
                                                            <span className="text-primary text-[48px]"><MdAddPhotoAlternate /></span>
                                                            <span className="mt-[32px]">{t("dashboard.recruitment.create.form.upload_i")}</span>
                                                            <input onChange={handleChangeFile} onClick={onInputClick} hidden type="file" id="career-banner" />
                                                        </label>
                                                    </div>


                                                    <div className="sc>768:mt-[24px]">
                                                            <p className="text-[#636363] mb-[30px] lssm:text-px14 md:text-px16 font-medium">{t("dashboard.recruitment.create.form.preview_i")}:</p>
                                                    
                                                        <div  className="lssm:w-full md:w-[220px] h-[120px] bg-[#6363631a] border-primary text-text-primary flex flex-col items-center justify-center rounded-[5px] overflow-hidden">
                                                            {
                                                                urlImagePreview !== "" ? (
                                                                    <img src={urlImagePreview} alt="" className="w-full h-full object-cover" />
                                                                ): (
                                                                    <span className="text-[#DADADA] text-[48px]"><MdOutlineImage /></span>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                            </div>
                                        </div>

                                        <div className="mt-[32px]">
                                            <label className="lssm:text-px14 md:text-px20 font-bold text-text-primary mb-4 block">Mức lương</label>
                                        <Input value={formik.values.salaryVi} name="salaryVi" onChange={formik.handleChange} label={<FaMoneyBill />}/>
                                        {formik.errors.salaryVi &&  <small className="mt-1 text-px12 text-error_color">{formik.errors.salaryVi}</small>}

                                        </div>

                                        <div className="mt-[32px]">
                                            <label className="lssm:text-px14 md:text-px20 font-bold text-text-primary mb-4 block">Số lượng tuyển dụng</label>
                                            <Input value={formik.values.numPerson} name="numPerson" onChange={formik.handleChange} label={<FaUsers />} />
                                               {formik.errors.numPerson &&  <small className="mt-1 text-px12 text-error_color">{formik.errors.numPerson}</small>}

                                            </div>

                                        <div className="mt-[32px]">
                                            <label className="lssm:text-px14 md:text-px20 font-bold text-text-primary mb-4 block">Hình thức làm việc</label>
                                        <Input value={formik.values.workingFormVi} name="workingFormVi" onChange={formik.handleChange}  label={<FaBriefcase />}/>
                                        {formik.errors.workingFormVi &&  <small className="mt-1 text-px12 text-error_color">{formik.errors.workingFormVi}</small>}

                                        </div>

                                        <div className="mt-[32px]">
                                            <label className="lssm:text-px14 md:text-px20 font-bold text-text-primary mb-4 block">Cấp bậc</label>
                                            <Input value={formik.values.levelVi} name="levelVi" onChange={formik.handleChange} label={ <RiMedalFill />} />
                                        {formik.errors.levelVi &&  <small className="mt-1 text-px12 text-error_color">{formik.errors.levelVi}</small>}

                                        </div>

                                        <div className="mt-[32px]">
                                            <label className="lssm:text-px14 md:text-px20 font-bold text-text-primary mb-4 block">Giới tính</label>
                                        <Input value={formik.values.genderVi} name="genderVi" onChange={formik.handleChange} label={ <MdOutlineTransgender />} />
                                        {formik.errors.genderVi &&  <small className="mt-1 text-px12 text-error_color">{formik.errors.genderVi}</small>}

                                        </div>

                                        <div className="mt-[32px]">
                                            <label className="lssm:text-px14 md:text-px20 font-bold text-text-primary mb-4 block">Kinh nghiệm</label>
                                            <Input value={formik.values.experienceVi} name="experienceVi" onChange={formik.handleChange} image="exprientce_svg.svg" />
                                        {formik.errors.experienceVi &&  <small className="mt-1 text-px12 text-error_color">{formik.errors.experienceVi}</small>}

                                        </div>  

                                        <div className="mt-[32px] md:col-span-2">
                                            <label className="lssm:text-px14 md:text-px20 font-bold text-text-primary mb-4 block">Địa chỉ làm việc</label>
                                            <Input value={formik.values.workplaceVi} name="workplaceVi" onChange={formik.handleChange} />
                                        {formik.errors.workplaceVi &&  <small className="mt-1 text-px12 text-error_color">{formik.errors.workplaceVi}</small>}

                                        </div>

                                        <div className="mt-[32px] md:col-span-2">
                                            <label className="lssm:text-px14 md:text-px20 font-bold text-text-primary mb-4 block">Mô tả công việc</label>
                                        <textarea value={formik.values.describeVi} name="describeVi" onChange={formik.handleChange} className="w-full p-2 lssm:h-[400px] md:h-[500px] border border-solid border-border-color focus-within:border-blue-500 outline-none rounded-[5px]">

                                        </textarea>
                                        {formik.errors.describeVi &&  <small className="mt-1 text-px12 text-error_color">{formik.errors.describeVi}</small>}

                                        </div>


                                        <div className="mt-[32px] md:col-span-2">
                                            <label className="lssm:text-px14 md:text-px20 font-bold text-text-primary mb-4 block">Yêu cầu ứng viên</label>
                                        <textarea value={formik.values.requireVi} name="requireVi" onChange={formik.handleChange} className="w-full p-2 h-[300px] border border-solid border-border-color focus-within:border-blue-500 outline-none rounded-[5px]">

                                        </textarea>
                                        {formik.errors.requireVi &&  <small className="mt-1 text-px12 text-error_color">{formik.errors.requireVi}</small>}
                                        
                                        </div>

                                        <div className="mt-[32px] md:col-span-2">
                                            <label className="lssm:text-px14 md:text-px20 font-bold text-text-primary mb-4 block">Quyển lợi được hưởng</label>
                                        <textarea value={formik.values.interestVi} name="interestVi" onChange={formik.handleChange} className="w-full p-2 h-[300px] border border-solid border-border-color focus-within:border-blue-500 outline-none rounded-[5px]">

                                        </textarea>
                                        {formik.errors.interestVi &&  <small className="mt-1 text-px12 text-error_color">{formik.errors.interestVi}</small>}

                                        </div>

                                        <div className="mt-[32px]">
                                            <label className="lssm:text-px14 md:text-px20 font-bold text-text-primary mb-4 block">Ngày hết hạn</label>
                                            <Input value={formik.values.dateExpiration} onChange={formik.handleChange} name="dateExpiration" type="date"  />
                                        {formik.errors.dateExpiration &&  <small className="mt-1 text-px12 text-error_color">{formik.errors.dateExpiration}</small>}

                                        </div>  

                                        <div className="md:col-span-2 flex justify-end mt-[32px]">
                                            <Button onClick={() => navigate(-1)} color="empty" className="sc>768:flex-1 sc>768:text-px14">{t("button.button_prev")}</Button>
                                            <div onClick={slideToTop} ref={navigationNextRef} className="sc>768:flex-1 md:w-[165px]  flex justify-center items-center bg-primary text-white-color lssm:text-px14 md:text-px16 rounded-[5px] ml-3 h-[50px]">{t("button.button_next")}</div>
                                        </div>

                                    </div>
                                </div>
                                </div>
                             </SwiperSlide>

                            <SwiperSlide className="w-full">
                                <div>
                                    <h2 className="uppercase text-[48px] sc>768:text-px20 text-text-primary font-medium text-center py-[74px] lssm:pb-[32px] md:pb-[117px]">ADD NEW JOB RECRUITMENT</h2>

                                    <div className=" mb-[80px] flex">
                                        <div className="grid lssm:grid-cols-1 md:grid-cols-2 gap-x-5 w-full">
                                        <div className="md:col-span-2">
                                            <label className="lssm:text-px14 md:text-px20 font-bold text-text-primary mb-4 block">Recruitment title</label>
                                           <Input value={formik.values.titleEn} onChange={formik.handleChange} name="titleEn" />
                                           {formik.errors.titleEn &&  <small className="mt-1 text-px12 text-error_color">{formik.errors.titleEn}</small>}
                                        </div> 

                                        <div className="mt-[32px]">
                                            <label className="lssm:text-px14 md:text-px20 font-bold text-text-primary mb-4 block">Wage offer</label>
                                        <Input value={formik.values.salaryEn} name="salaryEn" onChange={formik.handleChange} label={<FaMoneyBill />}/>
                                        {formik.errors.salaryEn &&  <small className="mt-1 text-px12 text-error_color">{formik.errors.salaryEn}</small>}

                                        </div>

                                        <div className="mt-[32px]">
                                            <label className="lssm:text-px14 md:text-px20 font-bold text-text-primary mb-4 block">Number of vacancies</label>
                                            <Input value={formik.values.numPerson} name="numPerson" onChange={formik.handleChange} label={<FaUsers />} />
                                               {formik.errors.numPerson &&  <small className="mt-1 text-px12 text-error_color">{formik.errors.numPerson}</small>}

                                        </div>

                                        <div className="mt-[32px]">
                                            <label className="lssm:text-px14 md:text-px20 font-bold text-text-primary mb-4 block">Working form</label>
                                        <Input value={formik.values.workingFormEn} name="workingFormEn" onChange={formik.handleChange}  label={<FaBriefcase />}/>
                                        {formik.errors.workingFormEn &&  <small className="mt-1 text-px12 text-error_color">{formik.errors.workingFormEn}</small>}

                                        </div>

                                        <div className="mt-[32px]">
                                            <label className="lssm:text-px14 md:text-px20 font-bold text-text-primary mb-4 block">Work rank</label>
                                            <Input value={formik.values.levelEn} name="levelEn" onChange={formik.handleChange} label={ <RiMedalFill />} />
                                        {formik.errors.levelEn &&  <small className="mt-1 text-px12 text-error_color">{formik.errors.levelEn}</small>}

                                        </div>

                                        <div className="mt-[32px]">
                                            <label className="lssm:text-px14 md:text-px20 font-bold text-text-primary mb-4 block">Gender</label>
                                        <Input value={formik.values.genderEn} name="genderEn" onChange={formik.handleChange} label={ <MdOutlineTransgender />} />
                                        {formik.errors.genderEn &&  <small className="mt-1 text-px12 text-error_color">{formik.errors.genderEn}</small>}

                                        </div>

                                        <div className="mt-[32px]">
                                            <label className="lssm:text-px14 md:text-px20 font-bold text-text-primary mb-4 block">Experience</label>
                                            <Input value={formik.values.experienceEn} name="experienceEn" onChange={formik.handleChange} image="exprientce_svg.svg" />
                                        {formik.errors.experienceEn &&  <small className="mt-1 text-px12 text-error_color">{formik.errors.experienceEn}</small>}

                                        </div>  

                                        <div className="mt-[32px] md:col-span-2">
                                            <label className="lssm:text-px14 md:text-px20 font-bold text-text-primary mb-4 block">Work location</label>
                                            <Input value={formik.values.workplaceEn} name="workplaceEn" onChange={formik.handleChange} />
                                        {formik.errors.workplaceEn &&  <small className="mt-1 text-px12 text-error_color">{formik.errors.workplaceEn}</small>}

                                        </div>                    

                                        <div className="mt-[32px] md:col-span-2">
                                            <label className="lssm:text-px14 md:text-px20 font-bold text-text-primary mb-4 block">Job description</label>
                                        <textarea  value={formik.values.describeEn} name="describeEn" onChange={formik.handleChange}  className="w-full p-2 lssm:h-[400px] md:h-[500px] border border-solid border-border-color focus-within:border-blue-500 outline-none rounded-[5px]">

                                        </textarea>
                                        {formik.errors.describeEn &&  <small className="mt-1 text-px12 text-error_color">{formik.errors.describeEn}</small>}

                                        </div>


                                        <div className="mt-[32px] md:col-span-2">
                                            <label className="lssm:text-px14 md:text-px20 font-bold text-text-primary mb-4 block">Employee requirements</label>
                                        <textarea value={formik.values.requireEn} name="requireEn" onChange={formik.handleChange}  className="w-full p-2 h-[300px] border border-solid border-border-color focus-within:border-blue-500 outline-none rounded-[5px]">

                                        </textarea>
                                        {formik.errors.requireEn &&  <small className="mt-1 text-px12 text-error_color">{formik.errors.requireEn}</small>}

                                        </div>

                                        <div className="mt-[32px] md:col-span-2">
                                            <label className="lssm:text-px14 md:text-px20 font-bold text-text-primary mb-4 block">Employee benefits</label>
                                        <textarea value={formik.values.interestEn} name="interestEn" onChange={formik.handleChange}  className="w-full p-2 h-[300px] border border-solid border-border-color focus-within:border-blue-500 outline-none rounded-[5px]">

                                        </textarea>
                                        {formik.errors.interestEn &&  <small className="mt-1 text-px12 text-error_color">{formik.errors.interestEn}</small>}

                                        </div>

                                    <div className="md:col-span-2 flex justify-end mt-[32px]">
                                         
                                            <div ref={navigationPrevRef} onClick={slideToTop} className="sc>768:flex-1 md:w-[165px] flex justify-center items-center border border-text-red  text-text-red mr-3 lssm:text-px14 md:text-px16  rounded-[5px] ml-3 h-[50px]">{t("button.button_prev")}</div>
                                            

                                            <Button type="submit" color="primary" className="sc>768:flex-1 md:w-[292px] sc>768:text-px14">{t("dashboard.recruitment.create.form.save")}</Button>
                                        </div>

                                    </div>
                                </div>
                                </div>
                            </SwiperSlide>             
                </Swiper>
            </form>
}