import { useEffect } from "react";
import { Link } from "react-router-dom";
import getDate from "../../common/getDate";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { getProjectsActive } from "../../reducers/project";
import ProjectItem from "../../components/ProjectItem";
import { hostBE } from "../../types/host";
export default function Banner() {
  const dispatch = useAppDispatch();
  const projectData = useAppSelector((state) => state.projectSlice);
  const translate = useAppSelector((state) => state.translateSlice);

  useEffect(() => {
    dispatch(getProjectsActive());
  }, []);

  return (
    <div className="sc991:h-auto md:w-auto banner_home_primary_images text-white-color">
      <img src={`${hostBE}/fe/project_banner.jpg`} className="w-full object-cover banner_home_primary_images" />
    </div>
  ) 
}
