import Map from "../components/Map";
import MultiContact from "../components/MultiContact";
import FormContact from "../containers/Contact/Form";


export default function Contact() {


    return (
        <div className="lssm:px-[24px]  md:px-[100px] xl:pl-[80px] xl:pr-0 w-1920:pl-[162px] ">
                <div className="grid grid-cols-1 xl:grid-cols-2 xl:gap-x-[50px] w-1920:gap-x-[90px]">
                        <FormContact />
                        <div className="lssm:h-[590px] xl:h-[100%]">

                            <Map width={"100%"} height={"100%"} />
                         </div>
                </div>
                <MultiContact />
        </div>
    )
}