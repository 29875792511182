import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Pagination from "../../components/Pagination";
import CareerList from "../../containers/Dashboard/Career/CareerList";
import CareerSearch from "../../containers/Dashboard/Career/CareerSearh";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { getCareerReduce, searchCareer, setCurrentPage } from "../../reducers/career";

const LIMIT = 10;
export default function Career() {
    const career = useAppSelector(state => state.careerSlice)
    const dispatch = useAppDispatch();
    const [value, setValue] = useState<string>();
    const [t] = useTranslation();

    useEffect(()=> {
        if(value === undefined || value.trim() === "" ) {
            dispatch(getCareerReduce({page: career.currentPage, limit: LIMIT}))

        }else {
            dispatch(searchCareer({keyword: value, type: 'vi' ,option: {page: career.currentPage, limit: LIMIT}}))
        }
    }, [career.currentPage]);
    
    const total = useMemo(()=> {

        return Math.ceil(career.total/LIMIT);
    }, [career.total])

    return (
        <div className="w-full ">
             <h2 className="uppercase text-[48px] sc>768:text-px20 text-text-primary font-bold text-center lssm:py-[40px] md:py-[74px]">{t("dashboard.recruitment.title")}</h2>
              <CareerSearch value={value ?? ""} setValue={setValue} />
              <CareerList />
              <div className="sc>768:mb-[32px]">
              {
                total > 0 && (
                    <Pagination currenPage={career.currentPage} setCurrentPage={setCurrentPage} total={total} />
                )
              }
            </div>
        </div>
    )
}