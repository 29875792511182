import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import "swiper/modules/grid";
import "swiper/css/navigation";
import { Autoplay, Navigation, Grid } from "swiper";
import React, { useMemo } from "react";
import { useAppSelector } from "../../hooks/hook";
import {
  MdOutlineArrowBackIosNew,
  MdOutlineArrowForwardIos,
} from "react-icons/md";

export default function Slider() {
  const partner = useAppSelector((state) => state.partnerSlice);

  const width = useMemo(() => {
    return window.innerWidth;
  }, []);

  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  return (
    <div className="flex-1 flex items-center  relative">
      <Swiper
        slidesPerView={
          width >= 1850
            ? 6
            : width >= 992
            ? 4
            : width >= 380
            ? 2
            : width >= 330
            ? 2
            : 1
        }
        spaceBetween={30}
        // slidesPerGroup={1}
        loop={false}
        loopFillGroupWithBlank={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        navigation={{
          // Both prevEl & nextEl are null at render so this does not work
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        onSwiper={(swiper: any) => {
          // Delay execution for the refs to be defined
          setTimeout(() => {
            // Override prevEl & nextEl now that refs are defined
            swiper.params.navigation.prevEl = navigationPrevRef.current;
            swiper.params.navigation.nextEl = navigationNextRef.current;

            // Re-init navigation
            swiper.navigation.destroy();
            swiper.navigation.init();
            swiper.navigation.update();
          });
        }}
        modules={[Navigation, Autoplay]}
        className="slider-company-home"
      >
        {partner.partner.map((item, index) => {
          if ((index + 1) % 2 === 0 && width < 768) return "";
          return width < 768 ? (
            <SwiperSlide className="slider-company-home-item " key={item.id}>
              <div className="sc>768:max-h-[74px]">
                <img
                  src={item?.imageUrl ?? ""}
                  className="max-h-[90%]  object-contain w-[90%] h-full"
                  alt={item?.imageUrl ?? ""}
                />
              </div>
              {
                index + 1 < partner.partner.length && (
              <div className="mt-[24px] sc>768:max-h-[74px]">
                <img
                  src={partner.partner[index + 1]?.imageUrl ?? ""}
                  className="max-h-[90%] object-contain w-[90%] h-full"
                  alt={partner.partner[index + 1]?.imageUrl ?? ""}
                />
              </div>

                )
              }
            </SwiperSlide>
          ) : (
            <SwiperSlide className="slider-company-home-item" key={item.id}>
              <div className="">
                <img className="object-contain w-[90%] h-full max-h-[90%]" src={item?.imageUrl ?? ""} alt={item?.imageUrl ?? ""} />
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>

      <div
        ref={navigationPrevRef}
        className="absolute top-[50%] lssm:left-[-24px] md:left-[-50px] lg:left-[-60px]cursor-pointer translate-y-[-50%] text-text-primary sc>768:text-white-color sc>768:text-[24px] md:text-[48px]"
      >
        <MdOutlineArrowBackIosNew />
      </div>
      <div
        ref={navigationNextRef}
        className="absolute top-[50%] lssm:right-[-24px] md:right-[-50px] lg:right-[-60px] cursor-pointer translate-y-[-50%] text-text-primary sc>768:text-white-color sc>768:text-[24px] md:text-[48px]"
      >
        <MdOutlineArrowForwardIos />
      </div>
    </div>
  );
}
