import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import Loadding from "../../components/Loadding/Loadding";
import {  useAppSelector } from "../../hooks/hook";

import projectServiece from "../../services/project";
import { Project, ResponsiveData } from "../../types";
const LIMIT = 3;

export default function Relatedproject() {
  const [t] = useTranslation();


  const translate = useAppSelector((state) => state.translateSlice);
  const [listProject, setListProject] = useState<Project[]>([])
  const [currenPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getProjects(currenPage)

  }, [currenPage]);

  const getProjects = async (page:number) => {
    setIsLoading(true)
    const project:ResponsiveData<Project> = await projectServiece.get({page, limit: LIMIT});
    setListProject([...listProject, ...project.list]);
    setTotal(project.total)
    setIsLoading(false)
  }

  return (
    <div className="sc<992:pl-[40px]  border-l-[1px] h-full border-solid border-border-color sc991:border-none">
      <h2 className="py-[45px] text-[36px] sc>768:text-[20px] sc>768:text-text-primary font-bold uppercase">
        {t("project.project_link")}
      </h2>

      <div className=" w-1920:w-[540px] sc991:flex sc991:flex-wrap 2xl:w-[450px] md:w-[370px]">
        {listProject.map((project) => {
          return (
            <Link
              to={`/du-an/${project.id}`}
              key={project.id}
              className="flex w-full pb-[38px] mb-[38px] border-b-[1px] border-solid border-[#1a73e852]"
            >
              <div className="flex-1 w-full max-h-auto rounded-[10px] overflow-hidden mr-[24px]">
                <img
                  src={project.avatarUrl}
                  alt="project1"
                  className="w-full h-[110px] object-cover"
                />
              </div>
              <div className="flex-1">
                {/* <p className="my-[15px] 2xl:text-px15 md:text-px14 sc>768:text-px15">{getDate(project.createdDate+"")} - Anfico</p> */}

                <p className="2xl:text-px16 font-medium line-clamp-4 md:text-px18">
                  {translate.isEnglish
                      ? project.titleVi 
                      : project.titleEn
                  }
                </p>
              </div>
            </Link>
          );
        })}
        {
          isLoading ? (<Loadding />) : (
            listProject.length !== total && (
            <div className="flex w-full justify-center pb-[24px]">
              <Button onClick={() => setCurrentPage(currenPage + 1)} color="primary">{t("button.see_more")}</Button>
            </div>
            )
          )
        }
      </div>
    </div>
  );
}
