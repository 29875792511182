import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import "swiper/modules/grid";
import "swiper/css/navigation";
import { Autoplay, Navigation, Grid } from "swiper";
import React, { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import {
  MdOutlineArrowBackIosNew,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import { getProjectHome, getProjectsActive, setCurrentPageHome } from "../../reducers/project";
import { Link } from "react-router-dom";

const LIMIT = 6;

export default function SliderProject() {


  const {projectListActive} = useAppSelector(state => state.projectSlice)
  const translate = useAppSelector(state => state.translateSlice);
  const dispatch = useAppDispatch();

  const width = useMemo(() => {
    return window.innerWidth;
  }, []);


  useEffect(() => {

      dispatch(getProjectsActive());
  
  }, [])

  // useEffect(() => {
  //   if(currentPageHome + 1 < total) {
  //     dispatch(getProjectHome({
  //       page: currentPageHome + 1, 
  //       limit: LIMIT
  //     }))
  //   }
  // }, [currentPageHome])

  // useEffect(()=> {
  //   if(currentElement === projectsHome.length - 3) {
  //     if(currentPageHome < total) {
  //       dispatch(setCurrentPageHome(currentPageHome + 1));
  //     }
  //   }
  // }, [currentElement])



    const navigationPrevRef = React.useRef(null)
    const navigationNextRef = React.useRef(null)
    return (
        <div className="relative">
        <Swiper
        slidesPerView={width >= 1280 ? 3 : (width >= 768 ? 2 : (width >= 380 ? 1 : (width >= 330  ? 1 : 1)) )}
        spaceBetween={30}
        // slidesPerGroup={1}
        // onRealIndexChange={(i)=> { setCurrentElement(i.activeIndex)}}
        loop={false}
        loopFillGroupWithBlank={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        navigation={{
          // Both prevEl & nextEl are null at render so this does not work
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        onSwiper={(swiper: any) => {
          // Delay execution for the refs to be defined
          setTimeout(() => {
            // Override prevEl & nextEl now that refs are defined
            swiper.params.navigation.prevEl = navigationPrevRef.current;
            swiper.params.navigation.nextEl = navigationNextRef.current;

            // Re-init navigation
            swiper.navigation.destroy();
            swiper.navigation.init();
            swiper.navigation.update();
          });
        }}
        modules={[Navigation, Autoplay]}
        className="h-auto"
      >
      
        {
            projectListActive.map((item, index)=> {              
                return (
                    <SwiperSlide className="" key={item.id}>
                      <Link to={`/du-an/${item.id}`}>
                        <div>
                            <img src={`${item?.avatarUrl}`} className="lssm:min-h-[432px] xl:min-h-[432px] block  max-h-[432px]  min-w-full object-cover rounded-[10px] mt-[24px]" alt={item?.avatarPath ?? ""}  />
                        </div>
                        <div className="">
                        <p className="text-text-primary text-[20px]  sc>768:text-px16 line-clamp-2 text-left lssm:w-fit sc<992:w-[50%] mt-[24px] flex">{translate.isEnglish ?  item.titleVi  : item.titleEn }</p>

                        </div>

                      </Link>
                    </SwiperSlide>       
                )      
            })
        }   
        </Swiper>
        <div ref={navigationPrevRef} className="absolute top-[50%] lssm:left-[-24px] md:left-[-50px] lg:left-[-60px] cursor-pointer translate-y-[-50%] text-text-primary sc>768:text-[24px] md:text-[48px]">
          <MdOutlineArrowBackIosNew />
        </div>
      <div ref={navigationNextRef} className="absolute top-[50%] lssm:right-[-24px] md:right-[-50px] lg:right-[-60px]  cursor-pointer translate-y-[-50%] text-text-primary sc>768:text-[24px] md:text-[48px]">
          <MdOutlineArrowForwardIos />
        </div>
    </div>
  );
}


