import { ChangeEvent } from "react";
import { hostBE } from "../../types/host";

type Props = {
  image?: string;
  value?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  name?: string;
  id?: string;
  readOnly?: boolean;
  className?: string;
  label?: JSX.Element;
};

export default function Input({
  image,
  value,
  onChange,
  type,
  id,
  name,
  readOnly,
  className,
  label,
}: Props) {
  return (
    <div
      className={`flex items-center h-[60px] w-full rounded-[5px] overflow-hidden  border border-solid border-border-color focus-within:border-primary ${className}`}
    >
      {image && (
        <span className="pl-[27px]">
          <img src={`${hostBE}/fe/${image}`} alt="" />
        </span>
      )}
      {label && (
        <span className="pl-[27px] text-primary lssm:text-[20px] sm:text-[24px] md:text-[32px]">
          {label}
        </span>
      )}
      <input
        type={type ? type : "text"}
        id={id}
        name={name}
        value={value}
        readOnly={readOnly ? readOnly : false}
        onChange={onChange}
        className={`flex-1 h-full text-px16 px-2 outline-none inputImage`}
      />
    </div>
  );
}
