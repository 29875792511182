import axios from "axios";
import UserService from "../../services/UserService";

const axiosClient = axios.create()


const getAccessToken = async () => {
  if (localStorage.getItem("token")) return localStorage.getItem("token");

  return new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {
      reject(null);

    }, 10000);

    const token = localStorage.getItem("token");
    resolve(token);

    clearTimeout(timeout);
  });
};

const configure = () => {
  axiosClient.interceptors.request.use(async (config: any) => {
    const { method, url } = config;


    if ((method === "get" && url === "/api/contact" )|| url === "/api/histories") {
      return config;
    }

    if (UserService.isLoggedIn()) {
      const cb = () => {
        config.headers.Authorization = `Bearer ${UserService.getToken()}`;
        return Promise.resolve(config);
      };
    
        return UserService.updateToken(cb);

      
    }

    const token = await getAccessToken();
    if (token) {
      config.headers.Authorization = "Bearer " + token;
    }

    return config;
  });
};

axiosClient.interceptors.response.use(

  async (response) => {
    return response.data;
  },
  function (error) {
    const { status } = error.response;
    if(window.document.location.pathname.includes("/quanly")) {

      if (status === 401 || status === 403) {
      //  UserService.doLogin();
      }

    }
    return Promise.reject(error);
  }
);

const HttpService = {
  configure,
  axiosClient,
};

export default HttpService;
