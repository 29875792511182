import clsx from "clsx";
import { useAppSelector } from "../../hooks/hook";
import useInView from "../../hooks/useInView";
import { HistoryState } from "../../types";
import { hostBE } from "../../types/host";

type Props = {
  index: number;
  his: HistoryState;
};

export default function HistoryItem({ index, his }: Props) {
  const { isInView, ref } = useInView();
  const translate = useAppSelector((state) => state.translateSlice);
  return index % 2 !== 0 ? (
    <div
      className={clsx("grid grid-cols-2", {
        "mt-[50px] pt-[50px]": index !== 0,
        "history_animate-active": isInView,
      })}
      ref={ref}
    >
      <div className="flex min-w-full items-center justify-end mr-[50px]">
        <div className="flex flex-col text-px14 justify-center mr-[50px] font-light  h-full ">
          <img className="w-[314px] h-[209px] object-cover rounded-xl" src={his.imageUrl} alt="" />
        </div>
      </div>
      <div className="flex min-w-full items-center ">
        <div className="mr-[24px] w-full  order-2 ml-[24px]">
          <h3 className="text-[48px] mb-[16px] text-left text-text-primary font-bold">
            {his.year}
          </h3>
          <p className="text-px16 min-w-full text-justify">
            {translate.isEnglish ? his.descriptionVi : his.descriptionEn}
          </p>
        </div>
        <div className="order-1 md:ml-[-25px] lg:ml-[-30px] 2xl:ml-[-33px]">
          <img src={`${hostBE}/fe/history_right.svg`} alt="" />
        </div>
      </div>
    </div>
  ) : (
    <div
      className={clsx("grid grid-cols-2", {
        "mt-[50px] pt-[50px]": index !== 0,
        "history_animate-active": isInView,
      })}
      ref={ref}
    >
      <div className="flex items-center justify-end min-w-full">
        <div className="mr-[24px] w-full">
          <h3 className="text-[48px] mb-[16px] text-right text-text-primary font-bold">
            {his.year}
          </h3>
          <p className="text-px16 text-justify">
            {translate.isEnglish ? his.descriptionVi : his.descriptionEn}
          </p>
        </div>
        <div className="md:mr-[-27px]  lg:mr-[-30px] 2xl:mr-[-34px]">
          <img  src={`${hostBE}/fe/history_left.svg`} alt="" />
        </div>
      </div>
      <div className="ml-[24px] min-w-full">
        <div className="flex flex-col text-px14 justify-center font-light h-full ml-[30px]">
          <img className="w-[314px] h-[209px] object-cover rounded-xl" src={his.imageUrl} alt="" />
        </div>
      </div>
    </div>
  );
}