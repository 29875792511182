import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { ProducPublicType } from "../../typeProps/Productype";
import Button from "../Button";
import { BiEdit } from "react-icons/bi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { hostBE } from "../../types/host";
import { putProducts } from "../../reducers/products";

type Props = {
  cartContent: ProducPublicType;
  isGrid?: boolean;
  outstanding?: boolean;
  onClick?: () => void;
  onClickImage?: () => void;
  onClickDelete?: () => void;
  propsClas?: string;
};

function CartProduct({
  cartContent,
  onClick,
  onClickImage,
  onClickDelete,
  outstanding,
  isGrid,
  propsClas,
}: Props) {
  const checkClass = isGrid;
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { isEnglish } = useAppSelector((state) => state.translateSlice);
  const navigate = useNavigate();
  const handleString = (titleString: string, lengthEnnable: number) => {
    if (titleString.length > lengthEnnable - 3) {
      const newTitle = titleString.slice(0, lengthEnnable - 3) + "...";
      return newTitle;
    } else {
      return titleString;
    }
  };
  // const handleEditProduct = (id: number) => {
  //   dispatch(putProducts(id));
  // };
  const handleContact = () => {
    navigate("/lien-he");
  };
  return (
    <div
      className={
        propsClas
          ? propsClas
          : clsx(
              "px-[5px] m992:px-[10px] ",
              outstanding !== undefined
                ? "xl:px-[10px] md:px-3"
                : "xl:px-[25px] ",
              checkClass
                ? outstanding !== undefined
                  ? "w-1920:w-1/4 xl:w-2/6 lg:w-2/4 m992:w-4/12 sm-480:w-2/4 w-full"
                  : "xl:w-1/3 md:w-1/3 sm-480:w-6/12 sm-390:w-6/12 w-full"
                : "w-full"
            )
      }
    >
      <div
        className={clsx(
          "w-full border rounded border-border-box  2xl:mb-12 mb-8",
          !checkClass &&
            "sm-390:flex sm-390:items-center sm-390:justify-between"
        )}
      >
        <div
          onClick={onClickImage}
          className={clsx(
            "bg-border-box",
            outstanding == undefined && "cursor-pointer",
            checkClass
              ? "w-full 2xl:h-[360px] xl:h-[280px] sm-480:h-[200px] relative h-[160px]"
              : "sm-390:w-2/5 w-full 2xl:h-[300px] xl:h-[230px] sm-480:h-[220px] h-[180px]"
          )}
        >
          {/* {outstanding !== undefined && (
            <div className="w-[30px] h-[30px] absolute top-4 left-4">
              <img
                onClick={() => handleEditProduct(cartContent.id)}
                className="w-full h-full cursor-pointer"
                src={
                  outstanding && cartContent.priority
                    ? `${hostBE}/fe/star.png`
                    : `${hostBE}/fe/star1.png`
                }
                alt="outstanding"
              />
            </div>
          )} */}
          <img
            className="w-full h-full rounded-t"
            src={
              cartContent
                ? cartContent.avatarUrl
                : `${hostBE}/fe/anhspanfico.JPG`
            }
            alt="ảnh"
          />
        </div>
        <div
          className={clsx(
            "flex flex-col",
            outstanding
              ? "justify-between px-3 pb-6 2xl:min-h-[390px] xl:min-h-[350px] sm-480:min-h-[370px] min-h-[330px]"
              : isGrid
              ? "justify-between px-3 pb-6 2xl:min-h-[330px] m992:min-h-[320px] sm-480:min-h-[300px] sm-390:min-h-[280px] min-h-[250px]"
              : "justify-center",
            checkClass
              ? "w-full sm-480:pt-3 pt-1 xl:px-7 "
              : "sm-390:w-4/5 w-full sm-480:py-0 px-4 sm-390:py-1 md:px-12"
          )}
        >
          <div>
            <p
              className={clsx(
                " mt-5 h-auto  font-bold 2xl:mb-9 text-base sm-480:text-xl mb-3 text-bg_blue_bold line-clamp-1"
              )}
            >
              {isEnglish ? cartContent.titleVi : cartContent.titleEn}
            </p>
            <p
              className={clsx(
                "sm-480:text-base text-sm font-medium text-text-gray 2xl:mb-5 sm:mb-4  mb-3",
                !checkClass && "sm-390:mb-1"
              )}
            >
              {t("cart.description")}
            </p>
            <p
              className={clsx(
                "sm-480:text-base text-sm h-auto font-normal 2xl:mb-9 mb-4 text-text-gray line-clamp-3"
              )}
            >
              {isEnglish
                ? cartContent.descriptionVi
                : cartContent.descriptionEn}
            </p>
          </div>
          <div>
            {!propsClas && outstanding === undefined && (
              <Button
                color="empty"
                onClick={handleContact}
                className={clsx(
                  "w-full  w-1920:px-8 2xl:py-3  flex",
                  checkClass
                    ? "lg:py-3 xl:px-2 sm-480:p-[10px] px-3 py-3"
                    : " md:p-3 sm-480:px-2 py-2"
                )}
                disabled={false}
                type="button"
              >
                <img
                  className="sm-480:w-6 sm-480:h-6 mr-2 w-[11px] h-[11px]"
                  src={`${hostBE}/fe/Vectorlienhe.png`}
                  alt=""
                />
                <p className="sm-480:text-base text-sm font-normal sm-480:text-center text-left text-bg_blue_bold">
                  {t("cart.button")}
                </p>
              </Button>
            )}
            {outstanding !== undefined && (
              <>
                <Button
                  color="primary"
                  onClick={onClick}
                  className={clsx(
                    "w-full bg-bg_blue_bold w-1920:px-4 2xl:py-3 sm-390:py-3  xl:text-sm sm:px-0 sm-390:px-6 px-2 py-[6px] text-xs mb-[10px] flex"
                  )}
                  disabled={false}
                  type="button"
                >
                  <BiEdit className="text-3xl sm-480:w-6 sm-480:h-6 md:mr-5 sm-390:mr-5 mr-2 sm-390:w-[22px] sm-390:h-[22px]" />
                  <p className="2xl:text-base xl:text-sm sm:text-base font-normal text-sm text-white">
                    {t("cart.editProduct")}
                  </p>
                </Button>
                <Button
                  color="empty"
                  onClick={onClickDelete}
                  className={clsx(
                    "w-full  w-1920:px-4 2xl:py-3 sm-390:py-3 sm-390:px-6 px-2 py-[6px] text-xs flex border-text-red"
                  )}
                  disabled={false}
                  type="button"
                >
                  <RiDeleteBin6Line className="text-[28px] sm-480:w-6 sm-480:h-6 md:mr-5 sm-390:mr-5 mr-2 sm-390:w-[22px] sm-390:h-[22px] text-text-red" />
                  <p className="sm:text-base font-normal text-sm text-text-red">
                    {t("cart.deleteproduct")}
                  </p>
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CartProduct;
