import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Pagination from "../../components/Pagination";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { getDevice, setCurrentPage } from "../../reducers/device";

const LIMIT = 12;

export default function Equipment() {
    const dispatch = useAppDispatch();
    const deviceData = useAppSelector(state => state.DeviceSlice);
    const translate = useAppSelector(state => state.translateSlice);

    useEffect(() => {
        dispatch(getDevice({
            page: deviceData.currentPage,
            limit: LIMIT
        }));
    }, [deviceData.currentPage])

    const total = useMemo(() => {
        return Math.ceil(deviceData.total/LIMIT);
    }, [deviceData.total])

    const [t] = useTranslation();

    return (<div>
        <h2 className="uppercase lssm:text-px20 md:text-[40px] xl:text-[48px] font-bold lssm:my-[24px] md:my-[56px]  text-text-title text-center animate__animated animate__fadeInDown ">{t("about.equipment.title")}</h2>
            <p className="text-justify lssm:px-0  md:px-[80px] lg:px-[120px] 2xl:px-[298px] lssm:text-px14 md:text-px20">
                 {t("about.equipment.description")}
            </p>
        <div className="lssm:my-[24px] md:my-[83px] p-[32px] border-[1px] border-solid border-border-color rounded">
            <div className="grid lssm:grid-cols-1 sm:grid-cols-2  md:grid-cols-3 2xl:grid-cols-4 gap-[30px]">

                {
                    deviceData.devices.map(device => {
                        return (
                            <div key={device.id} className="h-[400px] flex flex-col rounded-md border-[1px] border-solid border-border-color ">
                                <div>
                                    <img src={device.topicImage?.imageUrl ?? ""} className="w-full object-cover h-[298px]" alt="" />
                                </div>
                                <div className="flex-1 pt-[24px] ">
                                    <p className="uppercase lssm:text-px16 md:text-px20 font-bold text-text-primary text-center">{translate.isEnglish ?  device.descriptionVi : device.descriptionEn}</p>
                                </div>

                            </div>

                        )
                    })
                }

            </div>
                {
                     deviceData.devices.length > 0 ? (
                        <div className="py-[70px] flex lssm:justify-center md:justify-end">
                        <Pagination currenPage={deviceData.currentPage} setCurrentPage={setCurrentPage} total={total} />
                    </div>
                     ) : <div></div>
                }
         


        </div>
    </div>)

}