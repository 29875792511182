import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AiFillPlusCircle, AiOutlinePlusCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import DialogConfirm from "../../components/DialogConfirm";
import ModalCreateAlbum from "../../containers/Dashboard/LibraryImage/ModalCreateAlbum";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { deleteAlbum, getAlbum } from "../../reducers/album";
import { hideModal, showModal } from "../../reducers/modal";
import { pushPopup } from "../../reducers/popupSlice";
import albumService from "../../services/album";
import { Album } from "../../types";
import { hostBE } from "../../types/host";

export default function ManagerImages () {

    const dispatch = useAppDispatch();
    const albumData = useAppSelector(state => state.albumSlice);
    const [t] = useTranslation();
    const {isEnglish} = useAppSelector(state => state.translateSlice)

    const showModalAlbum = ()=> {
            dispatch(showModal(<ModalCreateAlbum/>))
    }

    useEffect(()=> {
        dispatch(getAlbum());
    }, [dispatch])

    const showDialogConfirm = (item:Album) => {
        dispatch(showModal(<DialogConfirm message={`Bạn có chắc chắn muốn xóa Album ${item.descriptionVi} không?`} onClick={()=> deleteAlbumMethod(parseInt(item.id+""))} />))
    }

    const deleteAlbumMethod = (id:number) => {
        albumService.deleteAlbum(id).then((data)=> {
            if(data) {
                dispatch(deleteAlbum(id));
                dispatch(hideModal());
                dispatch(pushPopup({
                    type: "SUCCESS",
                    message: t("notify.success", {name: t("notify.delete")})
                  }))
            }else {
                dispatch(hideModal());
         
                dispatch(pushPopup({
                    type: "ERROR",
                    message:  t("notify.error", {name: t("notify.delete")})
                  }))
            }
        }).catch(()=> {
            dispatch(hideModal());
         
            dispatch(pushPopup({
                type: "ERROR",
                message:  t("notify.error", {name: t("notify.delete")})
              }))
        })
    }

    return (
        <div className="flex flex-col">
             <h2 className="font-bold  text-text-primary text-center  mt-[74px] lssm:mb-[32px] md:mb-[107px] lssm:text-px20 md:text-[32px] 2xl:text-[48px]">{t("dashboard.imageLb.title")}</h2>

             <div className="mb-[40px] sm:hidden">
                    <Button onClick={showModalAlbum} color="empty" className="w-full h-[50px] text-primary text-px20 bg-[#EEF7FF]">
                    <div className="flex items-center">
                        <span className="mr-2"><AiOutlinePlusCircle /></span><span>{t("button.add")}</span>
                    </div>
                    </Button>
             </div>

             <div className="grid lssm:grid-cols-1 sm:grid-cols-3 xl:grid-cols-4 gap-x-[68px] gap-y-[100px] mb-[180px]">
                <div className="lssm:hidden sm:block">
                    <div className="h-[290px] w-full rounded-[10px] bg-[#F1F1F1] flex justify-center items-center" onClick={showModalAlbum}>
                        <span className="text-[72px] text-[#D9D9D9]"><AiFillPlusCircle /></span> 
                       
                    </div>
                    <p className="text-px16 mt-[20px] text-black-color text-center">{t("dashboard.imageLb.btn_add")}</p>

                </div>

                {
                    albumData.albums.map((item, index) => {
                        return (
                                <div key={index}>
                                    <div className="h-[290px] w-full rounded-[10px] cursor-default relative bg-[#F1F1F1] flex justify-center items-center overflow-hidden parentHover">
                                         <img src={item.topicImage?.imageUrl} alt="" className="w-full h-full object-cover" />
                                         <div className="absolute flex justify-center items-center inset-0 hoverChildren">
                                            <span className="cursor-pointer" onClick={()=>showDialogConfirm(item)}>
                                                <img src={`${hostBE}/fe/delete_confirm.png`} alt="" />
                                            </span>
                                                
                                         </div>
                                    </div>
                                    <Link to={item.id+""} className="lssm:text-px14 md:text-px16 mt-[20px] block text-black-color text-center hover:text-text-primary hover:underline">{isEnglish ? item.descriptionVi : item.descriptionEn}</Link>

                                </div>

                        )
                    })
                }


             </div>
           
        </div>
    )
}