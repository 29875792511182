import { useFormik } from "formik";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import TitlePage from "../../components/ManagerComponent/TitlePage";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { User } from "../../types";
import * as Yup from "yup";
import uploadService from "../../services/uploadImage";
import { updateCurrentUser } from "../../reducers/user";
import { hostBE } from "../../types/host";

function EditAccount() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [fileAvata, setFileAvata] = useState<any>(undefined);
  const [avataPreview, setAvataPreview] = useState<any>(undefined);
  const user = useAppSelector((state) => state.userSlice.currentUser);
  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      fullname: "",
      gender: "",
      birth: "",
      // email: "",
      phone: "",
      position: "",
    },
    validationSchema: Yup.object({
      fullname: Yup.string()
        .required(t("validate.required"))
        .min(5, t("validate.error.min", {name: t("form.name"), min: 5})),
      birth: Yup.string().required(t("validate.required")),
      // email: Yup.string()
      //   .required("Bắt buộc")
      //   .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Đây không phải địa chỉ email"),
      phone: Yup.string()
        .required(t("validate.required"))
        .matches(/^[0-9]{10}$/, t("validate.pattern", {name: t("contact.form.phone")})),
      position: Yup.string()
        .required(t("validate.required"))
        .min(3,  t("validate.error.min", {name: t("form.position"), min: 3})),
    }),
    onSubmit: async (values) => {
      let urlAvatar;
      if (fileAvata) {
        const formData = new FormData();
        formData.append("file", fileAvata);
        urlAvatar = await uploadService.upload(formData);
      }
      dispatch(
        updateCurrentUser({
          id: user?.id,
          ...values,
          avatarUrl: urlAvatar?.list[0].image_url ?? user?.avatarUrl + "",
          avatarPath: urlAvatar?.list[0].image_path ?? user?.avatarPath,
        })
      );
    },
  });

  useEffect(() => {
    const dateBirth = new Date(parseInt(user?.birth + ""));
    const year = dateBirth.getFullYear();
    const month = `00${dateBirth.getMonth() + 1}`.slice(-2);
    const day = `00${dateBirth.getDate()}`.slice(-2);

    formik.setValues({
      fullname: user?.fullname ?? "",
      gender: user?.gender ? "true" : "false",
      birth: user?.birth ? `${year}-${month}-${day}` : "",
      // email: user?.email + "",
      phone: user?.phone ?? "",
      position: user?.position ?? "",
    });
    setAvataPreview(user?.avatarUrl);
  }, []);

  const choseFile = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files;
    const toBase64 = (file: any) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    if (file) {
      setFileAvata(file[0]);
      toBase64(file[0])
        .then((result) => {
          setAvataPreview(result);
        })
        .catch((erro) => {});
    }
  };

  const goback = () => {
    navigate("/quanly/thongtintaikhoan");
  };
  return (
    <form
      onSubmit={formik.handleSubmit}
      className="w-full sm-390:px-[10px] mb-[60px]"
    >
      <TitlePage content="titleManager.changePassTitle" />
      <div className="flex items-center mb-16">
        <div className="w-[100px] h-[100px] xl:w-[140px]  xl:h-[140px]   rounded-[50%] relative flex items-center justify-center">
          <img
            className="rounded-[50%] w-full h-full"
            src={`${hostBE}/fe/loginImage.png`}
            alt="accountImage"
          />
          <img
            className="rounded-[50%] absolute top-auto translate-y-[0%] w-[85%] h-[85%] object-cover"
            src={avataPreview ? avataPreview : `${hostBE}/fe/logotitle.png`}
            alt="avataracount"
          />
        </div>
        <div className="h-full flex-col flex sm-390:ml-[30px] ml-6 ">
          <div className="flex items-center xl:mb-7 mb-3">
            <p className="sm-480:text-px20 sm-390:text-xl text-px14 font-bold">
              {user?.fullname}
            </p>
          </div>

          <div className="w-[100px] h-[30px] lg:w-[171px] lg:h-[50px] ">
            <label
              htmlFor="choseAvata"
              className=" lg:p-[10px] py-[6px] flex items-center justify-center bg-bg-lightgray rounded-md"
            >
              <input
                id="choseAvata"
                onChange={(event) => choseFile(event)}
                type="file"
                className="hidden"
              />
              <img src={`${hostBE}/fe/choseavata.svg`} alt="choseavata" />
            </label>
          </div>
        </div>
      </div>

      <div className="w-full flex flex-wrap justify-between">
        <div className="md:w-[48%] w-full flex flex-col mb-6">
          <label className="text-text-primary font-bold md:text-px20 text-px14 lg:mb-4 mb-[6px]">
            {t("form.name")}
            <span className="text-[#EB0000]">*</span>
          </label>
          <input
            id="nameUser"
            className="w-full px-3 py-3 border-border-gray border rounded-md focus:outline-none"
            type="text"
            value={formik.values.fullname}
            onChange={formik.handleChange}
            name="fullname"
          />
          <small className="text-px14 text-[#fe6a38]">
            {formik.errors.fullname}
          </small>
        </div>
        <div className="w-[48%] flex flex-col mb-6">
          <label className="text-text-primary font-bold md:text-px20 text-px14 lg:mb-4 mb-[6px]">
            {t("career.form.gender")}
            <span className="text-[#EB0000]">*</span>
          </label>

          <select
            className="w-full px-3 py-3 border-border-gray border rounded-md focus:outline-none"
            name="gender"
            onChange={formik.handleChange}
            value={formik.values.gender}
          >
            <option value="true"> {t("form.gender.male")}</option>
            <option value="false">{t("form.gender.female")}</option>
          </select>
          <small className="text-px14 text-[#fe6a38]">
            {formik.errors.gender}
          </small>
        </div>
        <div className="w-[48%] flex flex-col mb-6">
          <label className="text-text-primary font-bold md:text-px20 text-px14 lg:mb-4 mb-[6px]">
            {t("form.dateofbirth")}
            <span className="text-[#EB0000]">*</span>
          </label>
          <input
            className="w-full px-3 py-3 border-border-gray border rounded-md focus:outline-none"
            type="date"
            value={formik.values.birth}
            onChange={formik.handleChange}
            name="birth"
          />
          <small className="text-px14 text-[#fe6a38]">
            {formik.errors.birth}
          </small>
        </div>

        <div className="md:w-[48%] w-full flex flex-col mb-6">
          <label className="text-text-primary font-bold md:text-px20 text-px14 lg:mb-4 mb-[6px]">
            {t("contact.form.phone")}
            <span className="text-[#EB0000]">*</span>
          </label>
          <input
            className="w-full px-3 py-3 border-border-gray border rounded-md focus:outline-none"
            type="text"
            value={formik.values.phone}
            onChange={formik.handleChange}
            name="phone"
          />
          <small className="text-px14 text-[#fe6a38]">
            {formik.errors.phone}
          </small>
        </div>

        <div className="md:w-[48%] w-full flex flex-col mb-6">
          <label className="text-text-primary font-bold md:text-px20 text-px14 lg:mb-4 mb-[6px]">
            {t("form.position")}
            <span className="text-[#EB0000]">*</span>
          </label>
          <input
            className="w-full px-3 py-3 border-border-gray border rounded-md focus:outline-none"
            type="text"
            value={formik.values.position}
            onChange={formik.handleChange}
            name="position"
          />
          <small className="text-px14 text-[#fe6a38]">
            {formik.errors.position}
          </small>
        </div>
      </div>

      <div className="flex sm-480:justify-end justify-between xl:mt-[120px] mt-[60px] m992:mb-[60px] lg:mb-0">
        <button
          type="button"
          onClick={goback}
          className="py-[13px] mr-[10px] sm-390:px-6 px-0 sm-480:w-auto w-[48%] border rounded-md border-text-lightred text-text-lightred font-medium lssm:text-px14 md:text-px16 text-center"
        >
          {t("button.cancel")}
        </button>
        <button
          type="submit"
          className="py-[13px]  sm-390:px-6 px-0 sm-480:w-auto w-[48%] font-medium border rounded-md bg-primary text-white lssm:text-px14 md:text-px16 text-center"
        >
          {t("button.savesetting")}
        </button>
      </div>
    </form>
  );
}

export default EditAccount;
