type Props = {
  width: number | string;
  height: number | string;
};

export default function Map({ width, height }: Props) {
  return (
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3725.1449219951255!2d106.34816320000002!3d20.9868271!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31358334eb430f67%3A0x22dc4db5d4ec80aa!2sGLC%20Factory!5e0!3m2!1svi!2s!4v1676602605018!5m2!1svi!2s"
      width={width}
      height={height}
    ></iframe>
  );
}
