import clsx from "clsx";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Autoplay, FreeMode, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import convertToHtml from "../common/convertoHtml";
import Button from "../components/Button";
import CartProduct from "../components/cart/CartProduct";
import Diretional from "../components/cart/Diretional";
import MultiContact from "../components/MultiContact";
import { useAppDispatch, useAppSelector } from "../hooks/hook";
import { getProdutDetail } from "../reducers/ProductDetails";
import { getProductPublic } from "../reducers/productPublic";
import { hostBE } from "../types/host";

type propsUltilie = {
  urlImage: string;
  title: string;
  description: string;
};

const Utiliti = ({ urlImage, title, description }: propsUltilie) => {
  const { t } = useTranslation();
  return (
    <div className="2xl:w-[20%] m992:w-2/4 md:w-[46%] w-full flex items-center w-1920:mb-0 mb-7">
      <div className="w-auto m992:w-[30px] h-full flex justify-center items-center">
        <img className="2xl:w-auto w-full h-auto" src={urlImage} alt="ship" />
      </div>
      <div className="w-10/12 flex flex-col pl-7">
        <p className="m992:text-sm w-1920:text-base text-base  font-semibold text-white w-1920:mb-5 mb-0">
          {t(`${title}`)}
        </p>
        <p className="text-white m992:text-sm w-1920:text-base text-base font-normal m992:block hidden">
          {t(`${description}`)}
        </p>
      </div>
    </div>
  );
};

function ProductDetails() {
  const width = useMemo(() => {
    return window.innerWidth;
  }, []);
  const { isEnglish } = useAppSelector((state) => state.translateSlice);
  const { product, errorget } = useAppSelector((state) => state.producDetail);

  const { productPublicList } = useAppSelector((state) => state.productPublic);
  const navigator = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const param = useParams();
  const id = param?.id;
  const pagination = {
    clickable: true,
    renderBullet: function (index: any, className: any) {
      return (
        '<span class="' +
        className +
        '">' +
        `<img src=${product[0].productImageList[index].imageUrl} alt="anh" />` +
        "</span>"
      );
    },
  };
  const paginationsNew = { clickable: true };

  const ultilist = [
    {
      urlImage: `${hostBE}/fe/car.png`,
      title: "express.ship",
      description: "express.shipdes",
    },
    {
      urlImage: `${hostBE}/fe/monye.png`,
      title: "express.payal",
      description: "express.payal_des",
    },
    {
      urlImage: `${hostBE}/fe/clock.png`,
      title: "express.customer_care",
      description: "express.customer_care_des",
    },
    {
      urlImage: `${hostBE}/fe/clock2.png`,
      title: "express.trademark",
      description: "express.trademark_des",
    },
  ];

  const [showDetail, setShowDetail] = useState<boolean>(true);
  const handleContact = () => {
    navigator("/lien-he");
  };

  useEffect(() => {
    if (product.length > 0) {
      dispatch(
        getProductPublic({
          type: isEnglish ? "vi" : "en",
          search: null,
          categoryId: product[0].categoryId,
          page: 0,
          limit: 12,
          sort: 0,
        })
      );
    }
  }, [product]);
  useEffect(() => {
    if (id) {
      const ids = parseInt(id);
      dispatch(getProdutDetail(ids));
    }
  }, [id]);
  return (
    <div className="w-full">
      {product[0] ? (
        <div className="w-full">
          <div className="w-1920:px-[162px] xl:px-[80px] px-8 w-1920:mt-0 m992:pt-[53px] w-1920:pt-20 pb-12 flex m992:flex-row flex-col">
            <div
              className={clsx(
                "m992:w-[55%] w-full  h-auto",
                product[0].productImageList.length === 4 &&
                  "w-1920:h-[690px] 2xl:h-[635px] m992:h-[500px] md:h-[500px] sm:h-[400px]"
              )}
            >
              <Swiper
                loop={true}
                pagination={
                  product[0].productImageList.length === 4 ? pagination : false
                }
                modules={[Pagination]}
                className="mySwiper h-full product-detail"
              >
                {product[0].productImageList.map((item, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <img
                        className="w-full h-full"
                        src={item.imageUrl}
                        alt="slide"
                      />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
            <div className="m992:w-[45%] m992:p-0 pt-8 w-full  m992:pl-[75px] flex flex-col w-1920:mb-[150px]">
              <p className="text-primary sm-480:text-px32 text-xl w-1920:leading-[50px] font-semibold w-1920:mb-[50px] mb-[30px]">
                {isEnglish
                  ? product[0].titleVi.toUpperCase()
                  : product[0].titleEn.toUpperCase()}
              </p>

              <p className="sm-480:text-xl text-base m992:mb-6 font-bold mb-[30px] ">
                {t("cart.description")}
              </p>
              <p className="sm-480:text-base text-sm mb-[30px] m992:mb-20">
                {isEnglish
                  ? product[0].descriptionVi
                  : product[0].descriptionEn}
              </p>
              <Button
                onClick={handleContact}
                color="empty"
                className={clsx(
                  "sm:w-fit w-full  2xl:px-9 2xl:py-5 m992:pl-[14px]  sm-390:py-5 sm-390:pl-4 sm-390:pr-4 p-3 flex"
                )}
                disabled={false}
                type="button"
              >
                <img
                  className="sm-480:w-6 sm-480:h-6 mr-5 sm-390:mr-5 sm-390:w-[11px] sm-390:h-[11px]"
                  src={`${hostBE}/fe/Vectorlienhe.png`}
                  alt=""
                />
                <p className="sm-480:text-base text-sm font-normal text-[#1A73E8]">
                  {t("button.button_product")}
                </p>
              </Button>
            </div>
          </div>

          <div className="w-1920:px-[162px] lg:px-[30px] xl:px-[80px] pt-12 pb-16  justify-between lg:flex hidden">
            <div className="w-[60.2%]">
              <Diretional
                isweb={true}
                content="product-detail.description_table"
              />
              <div
                className="w-full h-auto min-h-[200px] py-12 px-11 rounded-r-md rounded-b-md border border-[E0E0E0] border-solid"
                dangerouslySetInnerHTML={{
                  __html: product[0]
                    ? isEnglish
                      ? convertToHtml(product[0].contentVi)
                      : convertToHtml(product[0].contentEn)
                    : "",
                }}
              ></div>
            </div>
            <div className="w-[39%] h-full">
              <Diretional
                isweb={true}
                content="product-detail.Specifications"
              />
              <div className="w-full h-auto p-5 rounded-r-md rounded-b-md border border-[#E0E0E0] border-solid">
                <table className="border-collapse w-full">
                  <tbody>
                    {product &&
                      product[0].specificationList.map((item, index) => {
                        return (
                          <Fragment key={`tr${item.id}`}>
                            <tr key={index} className="w-full">
                              <th
                                colSpan={2}
                                className="text-left text-white sm-480:text-xl text-base rounded-t-md border-none px-[14px] py-3 bg-bg_blue_bold"
                              >
                                {isEnglish ? item.nameVi : item.nameEn}
                              </th>
                            </tr>
                            {item.specificationDetailList.length > 0 &&
                              item.specificationDetailList.map(
                                (item2, index2) => {
                                  return (
                                    <tr key={`td${index2}`} className="w-full">
                                      <td className="w-1/4 border-dotted border-[2px] px-4 py-3 sm-480:text-base text-sm font-normal">
                                        {isEnglish
                                          ? item2.propertiesVi
                                          : item2.propertiesEn}
                                      </td>
                                      <td className="w-3/4 border-dotted border-[2px] px-4 py-3 sm-480:text-base text-sm font-normal">
                                        {isEnglish
                                          ? item2.valueVi
                                          : item2.valueEn}
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                          </Fragment>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {/* chi tiết sản phảm responsive */}
          <div className="px-0 pt-4 pb-[51px] lg:hidden block">
            <div className="w-full">
              <div className="w-full h-auto flex">
                <Diretional
                  content="product-detail.description_table"
                  isClick={showDetail}
                  onclickFunc={() => {
                    setShowDetail(true);
                  }}
                />

                <Diretional
                  content="product-detail.Specifications"
                  isClick={!showDetail}
                  onclickFunc={() => {
                    setShowDetail(false);
                  }}
                />
              </div>
              {showDetail ? (
                <div
                  className="w-full min-h-[200px] h-auto sm-390:py-12 pt-6 pb-9 md:px-11 sm:px-[30px] px-5 rounded-r-md rounded-b-md border border-[E0E0E0] border-solid"
                  dangerouslySetInnerHTML={{
                    __html: product[0]
                      ? isEnglish
                        ? convertToHtml(product[0].contentVi)
                        : convertToHtml(product[0].contentEn)
                      : "",
                  }}
                ></div>
              ) : (
                <div className="w-full h-full">
                  <div className="w-full h-auto rounded-r-md rounded-b-md border border-[#E0E0E0] border-solid sm:px-[30px]">
                    <table className="border-collapse w-full">
                      <tbody>
                        {product &&
                          product[0].specificationList.map((item, index) => {
                            return (
                              <Fragment key={`tr${item.id}`}>
                                <tr key={index} className="w-full">
                                  <th
                                    colSpan={2}
                                    className="text-left text-white sm-480:text-xl text-base rounded-t-md border-none px-[14px] py-3 bg-text-primary"
                                  >
                                    {isEnglish ? item.nameVi : item.nameEn}
                                  </th>
                                </tr>
                                {item.specificationDetailList.length > 0 &&
                                  item.specificationDetailList.map(
                                    (item2, index2) => {
                                      return (
                                        <tr
                                          key={`td${index2}`}
                                          className="w-full"
                                        >
                                          <td className="w-1/4 border-dotted border-[2px] px-4 py-3 sm-480:text-base text-sm font-normal">
                                            {isEnglish
                                              ? item2.propertiesVi
                                              : item2.propertiesEn}
                                          </td>
                                          <td className="w-3/4 border-dotted border-[2px] px-4 py-3 sm-480:text-base text-sm font-normal">
                                            {isEnglish
                                              ? item2.valueVi
                                              : item2.valueEn}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                              </Fragment>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="w-1920:px-[162px] xl:px-[100px] sm-390:px-12 px-[20px] py-6 flex items-center md:justify-between md:flex-row flex-wrap flex-col bg-bg_blue_bold">
            {ultilist.map((item, index) => {
              return (
                <Utiliti
                  urlImage={item.urlImage}
                  title={item.title}
                  description={item.description}
                  key={index}
                />
              );
            })}
          </div>

          <div className="w-1920:px-[162px] xl:px-[100px] sm:px-[30px] px-0 sm-480:mt-[107px] mt-12 mb">
            <p className="sm:font-bold sm-480:text-px32 text-xl sm-480:text-primary 2xl:mb-12 sm-480:mb-5 mb-4 text-black sm-480:text-left text-center  font-bold sm:pl-0 pl-[10px] ">
              {t("product-detail.produc_and")}
            </p>

            <div className="w-full h-auto rounded-r-md rounded-t-md lg:mb-16 mb-7 rounded-b-md border border-[#E0E0E0] border-solid sm:p-[30px] p-[10px]">
              {productPublicList.length > 0 && (
                <Swiper
                  loop={false}
                  slidesPerView={
                    width >= 1024 ?  4 : width >= 768 ? 3 : width >= 320 ? 2 : 1
                  }
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: true,
                  }}
                  freeMode={true}
                  spaceBetween={30}
                  // loopFillGroupWithBlank={true}
                  pagination={{
                    clickable: true,
                  }}
                  modules={[FreeMode, Pagination, Autoplay]}
                  className="mySwiper h-auto products-related 2xl:mb-9 mb-[10px]"
                >
                  {productPublicList.map((item, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <div className="w-full h-auto">
                          <CartProduct
                            cartContent={item}
                            propsClas="w-full"
                            isGrid={true}
                            onClickImage={() => {
                              navigator(`/chitietsanpham/${item.id}`);
                            }}
                          />
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full">
          <div>
            <p>{errorget}</p>
          </div>
        </div>
      )}

      <MultiContact />
    </div>
  );
}

export default ProductDetails;
