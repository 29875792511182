import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button";
import Input from "../../../components/CareerInput";
import { useAppDispatch, useAppSelector } from "../../../hooks/hook";
import { createAlbum, createAlbumSuccess } from "../../../reducers/album";
import { hideModal } from "../../../reducers/modal";
import { pushPopup } from "../../../reducers/popupSlice";
import albumService from "../../../services/album";


export default function ModalCreateAlbum() {
    const [value, setValue] = useState({
        descriptionVi: "",
        descriptionEn: ""
    })
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [t] = useTranslation();
    const {isEnglish} = useAppSelector(state => state.translateSlice)

    const handleChange = (event:ChangeEvent<HTMLInputElement>)=> {
            setValue({
                ...value,
                [event.currentTarget.name]: event.currentTarget.value
            });
    }

    const submitForm = ()=> {
        if(value.descriptionEn.trim() === "" || value.descriptionVi.trim() === "") {
            dispatch(pushPopup({
                type: "WARNING",
                message: isEnglish ?  "Vui lòng nhập đầy đủ các trường." : "Please enter all fields."
            }))

            return;
        }
        albumService.postAlbum({descriptionEn: value.descriptionEn, descriptionVi: value.descriptionVi}).then((data) => {
         dispatch(createAlbumSuccess(data));
         dispatch(pushPopup({
             type: "SUCCESS",
             message: t("notify.success", {name: t("button.add")})
         }))
         dispatch(hideModal());
         navigate(`/quanly/thuvien/${data.id+""}`);
        }).catch(error => {
         dispatch(pushPopup({
             type: "ERROR",
             message:error.response.data.message
         }))
        })
    }

    return (<div className="lssm:w-[280px] lsm-380:w-[370px] sm:w-[550px] md:w-[650px] lg:w-[670px] sc>768:px-[11px] sc>768:py-[20px] md:p-[40px] bg-white-color rounded-[10px] sc>768:rounded-b-[30px]">
            <h3 className="uppercase lssm:text-px20 md:text-[32px] text-text-primary text-center font-bold">{t("dashboard.imageLb.name_album")}</h3>
            <div className="py-[40px]">
                <label htmlFor="album-vi" className="mb-3 text-text-primary md:text-px20 font-bold">{t("dashboard.imageLb.name_vi")}</label>
                <Input id="album-vi" name="descriptionVi" value={value.descriptionVi} onChange={handleChange} />
            </div>

            <div className="pb-[40px]">
               <label htmlFor="album-en" className="mb-3 text-text-primary md:text-px20 font-bold">{t("dashboard.imageLb.name_en")}</label>
                <Input id="album-en" value={value.descriptionEn} name="descriptionEn" onChange={handleChange} />
            </div>
            
            <div className="flex justify-center">
                <Button onClick={()=> dispatch(hideModal())} color="empty" className="h-[50px] sc>768:text-px14 sc>768:flex-1 md:w-[85px] border-error_color text-error_color">{t("button.cancel")}</Button>
                <Button onClick={submitForm} color="primary" className="h-[50px] sc>768:text-px14 ml-3 sc>768:flex-1 md:w-[130px]">{t("button.confirm")}</Button>
            </div>
    </div>)
}