import Avatar from "../../components/Avatar";
import clsx from "clsx"
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { showModal } from "../../reducers/modal";
import DialogConfirm from "../../components/DialogConfirm";
import getDate from "../../common/getDate";
import { ROLE_ADMIN } from "../../types";
import { activeUser, deleteUserSlice } from "../../reducers/user";
import Loadding from "../../components/Loadding/Loadding";
import { hostBE } from "../../types/host";
import { useTranslation } from "react-i18next";





export default function User() {

    const dispatch = useAppDispatch();
    const userData = useAppSelector(state => state.userSlice)
    const {isEnglish} = useAppSelector(state => state.translateSlice)
    const [t] = useTranslation();
    
    


    const showModalDelete = (login:string)=> {
        dispatch(showModal(<DialogConfirm message={isEnglish ? "Xác nhận xóa người dùng này khỏi hệ thống ?" : "Confirm delete this user from the system?"} onClick={()=>handleDelete(login)} />))
    }



    const handleDelete = (login:string)=> {
       dispatch(deleteUserSlice(login))
    }

    const activeUserMethod = (id:any) => {
        dispatch(activeUser(id))
    }


    return (
        <div className="lssm:mt-[24px] md:mt-[50px]" >
                <div className={clsx("grid lssm:grid-cols-7 md:grid-cols-12 lssm:text-px14  sc>768:text-center md:text-px14 2xl:text-px16 text-text-primary font-bold  border-t-[1px] md:border-b-[1px] sc>768:border-x-[1px] border-solid sc>768:border-r-0 overflow-hidden sc>768:rounded-t-[10px] border-border-color", {"sc991:grid-cols-8 lg:grid-cols-11":userData.currentUser?.authorities?.name !== "ROLE_ADMIN"})}>
                        <h3 className="sc>768:border-r-[1px] lssm:col-span-2 md:col-span-3 sc>768:border-solid border-border-color sc>768:py-[20px] md:py-[50px]">{t("dashboard.user.list.account")}</h3>
                        <h3 className="sc>768:border-r-[1px] col-span-2 sc>768:border-solid border-border-color sc>768:py-[20px] md:py-[50px]">{t("dashboard.user.list.role")}</h3>
    
                        <h3 className="sc>768:hidden sc>768:py-[20px] col-span-2 md:py-[50px]">{t("dashboard.user.list.authorization")}</h3>
                        <h3 className="sc>768:border-r-[1px] col-span-2 sc>768:border-solid border-border-color sc>768:py-[20px] md:py-[50px]">{t("dashboard.user.list.phone")}</h3>
                        <h3 className="sc>768:hidden sc>768:border-r-[1px] col-span-2 sc>768:border-solid border-border-color sc>768:py-[20px] md:py-[50px]">{t("dashboard.user.list.status")}</h3>
            
                        {
                           userData.currentUser?.authorities?.name === "ROLE_ADMIN" && (

                                <h3 className="col-span-1  md:border-r-[1px] md:py-[50px] sc>768:py-[20px]">{t("dashboard.user.list.action")}</h3>
                            )
                        }
                </div>
                <div>
                    {
                        userData.listUsers.length === 0 && userData.isLoading ? <Loadding /> :  ( userData.listUsers.map((user, index)=> {
                            return (
                                    <div key={user.id} className={clsx("grid auto-cols-auto lssm:grid-cols-7  overflow-hidden md:grid-cols-12 sc>768:text-px14 md:border-b-[1px] border-solid border-border-color", {"bg-[#1a73e805]":index%2===0, "sc>768:border-b-[1px] sc>768:rounded-b-[12px]":index === userData.listUsers.length-1, "sc991:grid-cols-8 lg:grid-cols-11":userData.currentUser?.authorities?.name !== "ROLE_ADMIN"})}>
                                        <div className="flex lssm:col-span-2 md:col-span-3  items-center sc>768:justify-center sc>768:border-x-[1px] py-[17px]  border-solid border-border-color">
                                            <div className="sc991:hidden">
                                            <Avatar size={48} url={user.avatarUrl ?? `${hostBE}/fe/logotitle.png`}  />
                                            </div>
                                                <div className="md:ml-[12px]">
                                                    <h4 className=" lssm:text-px14 md:text-px12 xl:text-px16 text-black-color font-medium sc>768:text-center ">{user.fullname ?? t("dashboard.user.list.no_update")}</h4>
                                                    <p className="text-px14 md:text-[8px] xl:text-[16px] text-text-decoration w-[90%] font-light sc>768:hidden">{user.email ?? t("dashboard.user.list.no_update")}</p>
                                                </div>

                                        </div>

                                        <div className="flex  col-span-2  items-center sc>768:justify-center sc>768:border-r-[1px] py-[17px]  border-solid border-border-color">
                                        <div id="countries" className="sc>768:hidden flex justify-center items-center bg-gray-50 border outline-none border-gray-300 text-[#A8A8A8] lssm:text-px13 xl:text-px16 rounded-[5px] focus:ring-blue-500 focus:border-blue-500  2xl:w-[175px] max-w-full px-[18px] h-[40px] dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                        { user.authorities?.name === "ROLE_ADMIN"  ? "Admin" : t("dashboard.user.list.employee")}
                                    
                                        </div>
                                        <p className=" lssm:text-px14  md:text-px12 2xl:text-px13 text-[#A8A8A8] md:hidden"> { user.authorities?.name === "ROLE_ADMIN"  ? "Admin" : t("dashboard.user.list.employee") }</p>


                                        </div>

                                        <div className="sc>768:hidden col-span-2 flex items-center  sc>768:border-r-[1px] py-[17px]  border-solid border-border-color sc>768:px-[4px]">
                                            <p className="lssm:text-px14  md:text-px12 xl:text-px16 text-black-color">{getDate(parseInt(user.createdDate+"")) }</p>
                                        </div>

                                        <div className="flex col-span-2   items-center sc>768:justify-center sc>768:border-r-[1px] py-[17px]  border-solid border-border-color sc>768:px-[4px]">
                                        <p className="lssm:text-px14 sc>768:text-center md:text-px12 xl:text-px16 text-black-color">{user.phone ?? t("dashboard.user.list.no_update")}</p>
                                          
                                        </div>
                                        <div className="flex col-span-2 sc>768:hidden   items-center sc>768:justify-center sc>768:border-r-[1px] py-[17px]  border-solid border-border-color sc>768:px-[4px]">
                                        <p className="lssm:text-px14  sc>768:text-center font-bold xl:text-px16 text-black-color">{user.enabled ? t("dashboard.user.list.active") : t("dashboard.user.list.unActive") }</p>
                                          
                                        </div>
                                    {

                                        userData.currentUser?.authorities?.name === "ROLE_ADMIN" && user.authorities?.name !== "ROLE_SYSTEM"  && (
                                        <div className="col-span-1 w-full flex items-center justify-center md:border-r-[1px]">
                                            {
                                                userData.currentUser?.id !== user.id && (
                                                    <>
                                                    <div onClick={() => activeUserMethod(user.id)}>
                                                    {
                                                        user.enabled === true ? (
                                                            <span className="cursor-pointer flex justify-center lssm:mr-2 md:mr-4 w-[16px]" ><img src={`${hostBE}/fe/lock.svg`} className="w-[12px]" alt="delete" /></span>

                                                        ) : (
                                                            <span className="cursor-pointer flex justify-center lssm:mr-2 md:mr-4 w-[16px]"><img src={`${hostBE}/fe/unlock.svg`} className="w-[12px]" alt="delete" /></span>

                                                        )
                                                    }

                                                    </div>
                                                    <span className="cursor-pointer flex justify-center w-[16px]" onClick={()=>showModalDelete(user.id+"")}><img src={`${hostBE}/fe/delete_svg.svg`} className="w-[12px]" alt="delete" /></span>
                                                    </>

                                                )
                                            }
                                        </div>

                                        )

                                    }

                                    </div>

                                )

                            
                        }))
                        
                    }                    

                </div>

               

        </div>
    )
}