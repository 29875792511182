import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { BiSearch } from "react-icons/bi";
import { useNavigate, useSearchParams } from "react-router-dom";
import getDate from "../../common/getDate";
import Button from "../../components/Button";
import PageLoadMore from "../../components/pageLoadMore";
import Pagination from "../../components/Pagination";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import {
  getCareerReduce,
  searchCareer,
  setCurrentPage,
} from "../../reducers/career";
import { hostBE } from "../../types/host";

const LIMIT = 10;

export default function CareerContainer() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const career = useAppSelector((state) => state.careerSlice);
  const [value, setValue] = useState<string>();
  const [t] = useTranslation();
  const translate = useAppSelector((state) => state.translateSlice);
  const [searchParam, setSearchParam] = useSearchParams();

  useEffect(() => {
    if (value === undefined || value.trim() === "") {
      dispatch(getCareerReduce({ page: career.currentPage, limit: LIMIT }));
    } else {
      dispatch(
        searchCareer({
          keyword: value,
          type: translate.isEnglish ? "vi" : "en",
          option: { page: career.currentPage, limit: LIMIT },
        })
      );
    }
  }, [career.currentPage]);

  useEffect(() => {
    if (value !== undefined) {
      dispatch(
        searchCareer({
          keyword: value,
          type: translate.isEnglish ? "vi" : "en",
          option: { page: 1, limit: LIMIT },
        })
      );
      setSearchParam({ page: "1" });
    }
  }, [value]);

  const handleChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.currentTarget.value);
  };

  const goToDetail = (value: any) => {
    navigate(`${value}`);
  };

  const showModalDispatch = (id: string) => {
    navigate(`bieu-mau/${id}`);
  };

  const total = useMemo(() => {
    return Math.ceil(career.total / LIMIT);
  }, [career.total]);

  return (
    <>
      <div className="border-b-[2px] flex items-center  mb-[82px] h-[60px] border-solid border-border-color focus-within:border-primary duration-150">
        <input
          onChange={handleChangeValue}
          value={value ?? ""}
          className="h-full px-1 flex-1 text-px16 border-none outline-none"
          placeholder={translate.isEnglish ? "Nhập tìm kiếm ..." : "Search..."}
        />
        <span className="px-3 font-semibold  h-full text-[32px] flex items-center text-primary">
          <BiSearch />{" "}
        </span>
      </div>

      <div>
        {career.listCareer.length > 0 ? (
          career.listCareer.map((value) => {
            return (
              <div
                key={value.id}
                className="flex justify-between lg:flex-row flex-col pb-[38px] mb-[38px] border-b-[1px] border-solid border-[#1d70b94d]"
              >
                <div className="flex lssm:flex-col sm:flex-row lg:mr-3">
                  <div className="flex items-center justify-center border-[1px] border-solid ] lssm:h-[200px] sm:h-auto sm:w-[250px] w-auto rounded-md sm:mr-[25px]">
                    <img
                      src={
                        value.avatarUrl
                          ?  value.avatarUrl : `${hostBE}/fe/anficoLogo.png`
                      }
                      className="w-[95%] h-[95%] rounded"
                      alt="tuyen dung"
                    />
                  </div>
                  <div className="lssm:text-px14 md:text-px16 text-text-decoration lssm:mb-1 sm:mb-0">
                    <h4 className="text-px20 mb-[30px] font-semibold sc>768:text-px20 lssm:mt-4 sm:mt-0 lssm:text-center sm:text-left">
                      {translate.isEnglish ? value.titleVi : value.titleEn}
                    </h4>

                    <p className="">
                      {t("career.detail.wage")}:{" "}
                      <span className="text-[#DE2020]">
                      {value?.salaryVi &&  (translate.isEnglish ?  value?.salaryVi.match(/^[0-9]{0,}$/) ? new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(parseInt(value.salaryVi+"")) : value?.salaryVi :
                        value?.salaryEn.match(/^[0-9]{0,}$/) ? new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(parseInt(value.salaryEn+"")) : value?.salaryEn )}
                      </span>
                    </p>
                    <p className="my-[12px]">
                      {t("career.detail.date")}: {getDate(value.dateExpiration)}
                    </p>
                    <p className="">{translate.isEnglish ? value.workplaceVi : value.workplaceEn}</p>
                  </div>
                </div>

                <div className="flex lssm:flex-col sm:flex-row lg:flex-col lssm:items-center justify-center lg:mt-0 lssm:mt-[27px] md:mt-[50px]">
                  <Button
                    color="primary"
                    className="h-[60px] font-bold sc>768:text-px14 lssm:min-w-full sm:min-w-[220px]"
                    onClick={() => showModalDispatch(value.id + "")}
                  >
                    <span className="flex h-fit">
                      <img
                        src={`${hostBE}/fe/tuyen_dung.svg`}
                        className="mr-[24px] w-[20px] object-contain"
                        alt=""
                      />{" "}
                    {t("career.btn_recruit")}
                    </span>
                  </Button>

                  <Button
                    color="empty"
                    onClick={() => goToDetail(value.id)}
                    className="lssm:ml-0 sm:ml-[24px] md:ml-4 lg:ml-0 h-[60px] font-bold text-text-primary mt-[24px] lssm:mt-[24px] sm:mt-0 lg:mt-[24px] sc991:mt-0 sc>768:text-px14 lssm:min-w-full sm:min-w-[220px] "
                  >
                      {t("career.btn_view")}
                  </Button>
                </div>
              </div>
            );
          })
        ) : (
          <div className="h-[300px] flex justify-center items-center">
            {translate.isEnglish ? "Không có dữ liệu" : "No data"}
          </div>
        )}
        {career.listCareer.length > 0 ? (
          <div className="mt-[90px]">
            <Pagination
              currenPage={career.currentPage}
              setCurrentPage={setCurrentPage}
              total={total}
            />
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </>
  );
}
