import _debounce from "lodash/debounce";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import Button from "../../components/Button";
import CartProject from "../../components/cart/CartProject";
import DialogConfirm from "../../components/DialogConfirm";
import Loadding from "../../components/Loadding/Loadding";
import InputSearch from "../../components/ManagerComponent/InputSearch";
import TitlePage from "../../components/ManagerComponent/TitlePage";
import Pagination from "../../components/Pagination";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { hideModal, showModal } from "../../reducers/modal";
import { pushPopup } from "../../reducers/popupSlice";
import {
  getProject,
  searchProject,
  setCurrenPage,
} from "../../reducers/projectmanagerSlice";
import projectServiece from "../../services/project";
import { hostBE } from "../../types/host";

function ProjectManager() {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const navigator = useNavigate();
  const dispatch = useAppDispatch();
  const { projectList, error, loadding, total, currentPage } = useAppSelector(
    (state) => state.projectManagerSlice
  );
  const { isEnglish } = useAppSelector((state) => state.translateSlice);
  const [valueSearch, setValueSearch] = useState<string>("");

  const handleChangeInputSearch = (inputData: string) => {
    navigator("");
    dispatch(setCurrenPage(1));
    setValueSearch(inputData);
  };
  const handleAddProduct = () => {
    navigator("add");
  };
  const handleEditProducts = async (id: number) => {
    navigator(`edit/${id}`);
  };
  const deleteProject = async (id: number) => {
    try {
      const result = await projectServiece.deleteProject(id);
      if (result) {
        dispatch(
          pushPopup({
            type: "SUCCESS",
            message: isEnglish
              ? "Xóa dự án thành công."
              : "Delete project successfully.",
          })
        );
        if (currentPage === 1) {
          dispatch(getProject({ page: currentPage, limit: 12 }));
        } else {
          dispatch(setCurrenPage(1));
        }
        navigator("");
      }
    } catch (error) {
      dispatch(
        pushPopup({
          type: "WARNING",
          message: isEnglish
            ? "Không xóa được dự án!"
            : "Can't delete project!",
        })
      );
    }
    dispatch(hideModal());
  };

  const handleDelete = (id: number) => {
    dispatch(
      showModal(
        <DialogConfirm
          message="Bạn có chắc muốn xóa dự án này không ?"
          onClick={() => {
            deleteProject(id);
          }}
        />
      )
    );
  };

  // handle debounce iuputSearch
  const handleDebounceFn = (keySearch: string) => {
    if (keySearch != "") {
      dispatch(
        searchProject({
          type: isEnglish ? "vi" : "en",
          keySearch: keySearch,
          option: {
            page: currentPage - 1,
            limit: 12,
          },
        })
      );
    }
  };
  const debounceFn = useCallback(_debounce(handleDebounceFn, 1000), [
    valueSearch,
    currentPage,
  ]);

  useEffect(() => {
    if (searchParams.get("page")) {
      const numberPage = Number(searchParams.get("page"));
      setCurrenPage(numberPage);
    }
  }, [searchParams]);

  useEffect(() => {
    if (valueSearch != "") {
      if (currentPage == 1) {
        debounceFn(valueSearch);
      } else {
        dispatch(
          searchProject({
            type: isEnglish ? "vi" : "en",
            keySearch: valueSearch,
            option: {
              page: currentPage - 1,
              limit: 12,
            },
          })
        );
      }
    } else {
      dispatch(getProject({ page: currentPage, limit: 12 }));
    }

    return () => {
      debounceFn.cancel();
    };
  }, [currentPage, valueSearch]);

  return (
    <div className="w-full">
      <TitlePage content="titleManager.projectTitle" />
      <div className="w-full flex sm:flex-row sm:flex-wrap flex-col justify-between items-center px-[10px]">
        <div className="2xl:w-3/4 m992:w-2/3 sm:w-[48%] w-full sm:mb-0 mb-4">
          <InputSearch
            ChangeInputFc={(param) => {
              handleChangeInputSearch(param);
            }}
          />
        </div>
        <div className="2xl:w-[24%] m992:w-[31%] sm:w-[48%] w-full">
          <Button
            color="primary"
            onClick={() => {
              handleAddProduct();
            }}
            className="w-full w-1920:py-[18px] xl:py-[17px] py-[12px] px-4 w-1920:px-10 2xl:px-0 flex "
            disabled={false}
            type="button"
          >
            <img className="mr-3" src={`${hostBE}/fe/add1.png`} alt="add" />
            <p className="sm-480:text-base text-sm font-medium text-white">
              {t("button.button_addProject")}
            </p>
          </Button>
        </div>
      </div>
      {error ? (
        <div className="w-full sm:py-[60px] py-10 h-[200px] flex items-center justify-center">
          <p className="w-full text-text-red text-lg text-center">{error}</p>
        </div>
      ) : loadding ? (
        <div className="w-full sm:py-[60px] py-10 h-screen flex items-center justify-center">
          <Loadding />
        </div>
      ) : (
        <Fragment>
          <div className="w-full h-auto sm:py-[60px] py-10 flex flex-wrap">
            {projectList.length > 0 ? (
              projectList.map((product, index) => {
                return (
                  <CartProject
                    key={index}
                    cartContent={product}
                    outstanding={true}
                    isGrid={true}
                    onClick={() => handleEditProducts(product.id)}
                    onClickDelete={() => handleDelete(product.id)}
                  />
                );
              })
            ) : (
              <div className="w-full min-h-[200px]">
                <p className="text-primary text-xl text-center">
                  Không có sản phẩm nào.
                </p>
              </div>
            )}
          </div>
          <div className="w-full  flex items-center justify-end mb-24">
            {total > 0 && (
              <Pagination
                total={Math.ceil(total / 12)}
                currenPage={currentPage}
                setCurrentPage={setCurrenPage}
              />
            )}
          </div>
        </Fragment>
      )}
    </div>
  );
}

export default ProjectManager;
