import { hostBE } from "../../types/host";

export default function Banner() {


  return (
    <div className="h-auto max-h-max">
      <img 
       src={`${hostBE}/fe/dulich65anfico.jpg`}
      alt="banner"
      className="w-full banner_home_primary_images object-contain  sm-480:object-fill lg:object-fill w-1920:object-fill"
      />
    </div>
  );
}
