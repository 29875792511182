import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { categoryType, ProducPublicType, ProductItems, productPublicType, resultProductPublic } from "../typeProps/Productype";


interface ProductPublic {
    total:number;
    currentPage: number;
    loadding:boolean;
    productPublicList: ProducPublicType[]
    error:null | string;
    productActiveHome: categoryType[]
}

const initialState: ProductPublic = {
    total: 0,
    loadding: false,
    error: null,
    productPublicList: [],
    currentPage: 1,
    productActiveHome: localStorage.getItem("productActive") ? JSON.parse(localStorage.getItem("productActive")+"") : []
  };

export const productPublic = createSlice({
    name: "productPublic",
    initialState,
    reducers: {
        setCurrenPage(state,action:PayloadAction<number>){
            state.currentPage = action.payload;
        },
        getProductPublic(state,action: PayloadAction<productPublicType>){
            state.loadding = true;
            state.error=null
        },
        getProductPublicSuccess(state, action:PayloadAction<resultProductPublic>){
            const result = action.payload;
            state.total = result.total;
            state.productPublicList = result.list;
            state.loadding = false;
            state.error=null
        },
        getProductPublicFail(state,action:PayloadAction<string>){
            state.error = action.payload;
        },
        getProductActiveHome: (state) => {

        },
        getProductActiveHomeSuccess: (state, action:PayloadAction<categoryType[]>) => {
                state.productActiveHome = action.payload;
        }
    }
})

export const {getProductPublic, getProductPublicSuccess,getProductPublicFail,setCurrenPage, getProductActiveHome, getProductActiveHomeSuccess} = productPublic.actions;

export default productPublic.reducer;