import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Pagination from "../../components/Pagination";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { getAlbumImage } from "../../reducers/album";
import { showModal } from "../../reducers/modal";
import albumService from "../../services/album";
import { Album } from "../../types";
import AlbumModal from "./AlbumModal";


export default function AlbumDetail() {

    const param = useParams();
    const [albumParent, setAlbumParent] = useState<Album>()
    const dispatch = useAppDispatch();
    const albumData = useAppSelector(state => state.albumSlice);
    const translate = useAppSelector(state => state.translateSlice)


    useEffect(()=> {
        albumService.getById(parseInt(param.id+"")).then(data => {
            setAlbumParent(data)
        })

        dispatch(getAlbumImage(parseInt(param.id+"")))

    }, [param.id])



    const showModalAlbum = (i:number)=> {   
           dispatch(showModal( <AlbumModal currentIndex={i} />))
           
    }

    
    return (
        <div>
              <h2 className="text-text-primary  lssm:text-px20  md:text-[28px]  xl:text-[48px] font-bold lssm:mb-[40px]  uppercase md:mb-[80px] 2xl:mb-[133px] text-center">{translate.isEnglish ? albumParent?.descriptionVi : albumParent?.descriptionEn}</h2>
            <div className="grid lssm:grid-cols-1 lsm-380:grid-cols-2 md:grid-cols-4 2xl:grid-cols-5 gap-2">
                    {
                        albumData.albumImages.map((item, index)=> (
                            <div key={index} className="" onClick={()=>showModalAlbum(index)}>
                                <div className="rounded-md h-[300px] overflow-hidden">
                                    <img src={item.imageUrl} alt="" className="w-full h-full object-cover" />
                                </div>
                            </div>

                        ))
                    }            
            </div>
            {/* <div className="flex justify-center mt-[40px]">
            <Pagination currenPage={currentPage} setCurrentPage={setCurrenPage} total={10} />
            </div>
             */}
        </div>
    )
}