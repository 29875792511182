import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import uploadService from "../../services/uploadImage";
import ButtonAddImage from "../Button/ButtonAddImage";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Navigation, Thumbs, Zoom } from "swiper";
import Editor from "../Editor";
import Button from "../Button";
import { pushPopup } from "../../reducers/popupSlice";
import newService from "../../services/newService";
import { useNavigate, useParams } from "react-router-dom";
import { News } from "../../types";
import resizeFile from "../../common/reSize";
import { hostBE } from "../../types/host";
import { useTranslation } from "react-i18next";

type Props = {
  titleVi: string;
  titleEn: string;
  descriptionVi: string;
  descriptionEn: string;
};

function CreateNews() {
  const [newValue, setNewValue] = useState<any>("");
  const [newValueEn, SetNewValueEn] = useState<any>("");
  const refNew = useRef<any>();
  const refNewEn = useRef<any>();
  const navigate = useNavigate();
  const [newsState, setSateNews] = useState<News>();

  const [fileImage, setFileImage] = useState<any>(undefined);
  const [filePreview, setFiilePreview] = useState<any>("");
  const [t] = useTranslation();
  const {isEnglish} = useAppSelector(state => state.translateSlice)

  const params = useParams();

  const [newData, setNewData] = useState<Props>({
    titleVi: "",
    titleEn: "",
    descriptionEn: "",
    descriptionVi: "",
  });

  useEffect(() => {
    if (params.id) {
      newService.getById(parseInt(params.id)).then((data) => {
        setSateNews(data);
        setNewData({
          titleEn: data.titleEn,
          titleVi: data.titleVi,
          descriptionEn: data.descriptionEn,
          descriptionVi: data.descriptionVi,
        });
        setFiilePreview(data.avatarUrl);

        setNewValue(data.contentVi);
        SetNewValueEn(data.contentEn);
      });
    }
  }, []);

  useEffect(() => {
    if (refNew.current && newValue) {
      refNew.current.render(JSON.parse(newValue));
    }
  }, [refNew.current, newValue]);

  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  const listImageFiles = useRef<
    {
      id: number;
      file: File | string;
      path: string;
    }[]
  >([]);

  const listImageFilesEn = useRef<
    {
      id: number;
      file: File | string;
      path: string;
    }[]
  >([]);

  const dispatch = useAppDispatch();
  const handleChoseFile = async (file: File) => {

    var reader = new FileReader();
    reader.onload = function(e:any) {
      setFiilePreview(e.target.result)
    };
    reader.readAsDataURL(file);

    if (file.size >= 26675200) {
      dispatch(
        pushPopup({
          message: t("notify.min_image", {size: "25MB"}),
          type: "WARNING",
        })
      );
    } else {
      // const newFile =await resizeFile(file, {width: 800px})
      let newFile = file;
      if (newFile.size > 670000) {
        newFile = await resizeFile(file, { width: 800, height: 533 });
      }
      const toBase64 = (newFile: any) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(newFile);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });
      if (newFile) {
        setFileImage(newFile);
        toBase64(newFile).then((result) => {
          setFiilePreview(result);
        });
      }
    }
  };
  const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    if (value.length < 1000) {
      setNewData({
        ...newData,
        [name]: value,
      });
    }
  };

  const submitfunc = async () => {
    const output = await refNew.current?.save();
    const outPutEn = await refNewEn.current?.save();

    if (
      newData.descriptionEn === "" ||
      newData.descriptionVi === "" ||
      newData.titleEn === "" ||
      newData.titleVi === "" ||
      output.blocks.length === 0 ||
      outPutEn.blocks.length === 0
    ) {
      dispatch(
        pushPopup({
          message: isEnglish ? "Vui lòng nhập đầy đủ các trường." : "Please enter all fields.",
          type: "WARNING",
        })
      );
    } else {
      if (listImageFiles.current.length > 0) {
        const form_data = new FormData();
        const listfile = listImageFiles.current.map((item) => item.file);

        listfile.forEach((file) => {
          form_data.append("file", file);
        });

        const data = await uploadService.upload(form_data);
        const listNewImage = listImageFiles.current;
        data.list.forEach((item, index) => {
          listNewImage[index].file = item.image_url;
          listNewImage[index].path = item.image_path;
        });
        listImageFiles.current = [...listNewImage];

        listImageFiles.current.forEach((item) => {
          if (output?.blocks[item.id].type === "image") {
            output.blocks[item.id].data.file.url = item.file;
            output.blocks[item.id].data.file.path = item.path;
          }
        });
      }
      const content = JSON.stringify(output);
      setNewValue(content);

      if (listImageFilesEn.current.length > 0) {
        const form_data = new FormData();
        const listfileEn = listImageFilesEn.current.map((item) => item.file);

        if (typeof listfileEn[0] !== "string") {
          listfileEn.forEach((file) => {
            form_data.append("file", file);
          });

          const data = await uploadService.upload(form_data);
          const listNewImage = listImageFilesEn.current;
          data.list.forEach((item, index) => {
            listNewImage[index].file = item.image_url;
            listNewImage[index].path = item.image_path;
          });
          listImageFilesEn.current = [...listNewImage];
        }

        listImageFilesEn.current.forEach((item) => {
          if (outPutEn?.blocks[item.id].type === "image") {
            outPutEn.blocks[item.id].data.file.url = item.file;
            outPutEn.blocks[item.id].data.file.path = item.path;
          }
        });
      }

      const contentEn = outPutEn ? JSON.stringify(outPutEn) : content;
      SetNewValueEn(contentEn);

      const formData = new FormData();
      formData.append("file", fileImage);
      let urlAvatar: any;
      if (fileImage) {
        urlAvatar = await uploadService.upload(formData);
      }

      if (!params.id) {
        newService
          .createNews({
            ...newData,
            avatarUrl: urlAvatar.list[0].image_url ?? "",
            avatarPath: urlAvatar.list[0].image_path ?? "",
            contentEn: contentEn,
            contentVi: content,
          })
          .then(() => {
            goBack();
            dispatch(
              pushPopup({
                type: "SUCCESS",
                message: t("notify.success", {name: t("button.add")})
              })
            );
          })
          .catch(() => {
            dispatch(
              pushPopup({
                type: "ERROR",
                message: t("notify.error",  {name: t("button.add")})
              })
            );
          });
      } else {
        newService
          .editNews({
            ...newData,
            avatarUrl: urlAvatar?.list[0].image_url ?? newsState?.avatarUrl,
            avatarPath: urlAvatar?.list[0].image_path ?? newsState?.avatarPath,
            contentEn: contentEn,
            contentVi: content,
            id: parseInt(params.id),
          })
          .then(() => {
            goBack();
            dispatch(
              pushPopup({
                type: "SUCCESS",
                message: t("notify.success", {name: t("button.edit")})
              })
            );
          })
          .catch(() => {
            dispatch(
              pushPopup({
                type: "ERROR",
                message: t("notify.error", {name: t("button.edit")})
              })
            );
          });
      }
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  const slideToTop = () => {
    window.scrollBy(0, -document.documentElement.scrollTop);
  };

  useEffect(() => {
    if (refNewEn.current) {
      refNewEn.current.isReady.then(() => {
        refNewEn.current.render(JSON.parse(newValueEn));
      });
    }
  }, [refNewEn]);

  const setContentForEn = () => {
    slideToTop();
  };

  return (
    <Swiper
      slidesPerView={1}
      allowTouchMove={false}
      navigation={{
        // Both prevEl & nextEl are null at render so this does not work
        prevEl: navigationPrevRef.current,
        nextEl: navigationNextRef.current,
      }}
      onSwiper={(swiper: any) => {
        // Delay execution for the refs to be defined
        setTimeout(() => {
          // Override prevEl & nextEl now that refs are defined
          swiper.params.navigation.prevEl = navigationPrevRef.current;
          swiper.params.navigation.nextEl = navigationNextRef.current;

          // Re-init navigation
          swiper.navigation.destroy();
          swiper.navigation.init();
          swiper.navigation.update();
        });
      }}
      // navigation={true}
      grabCursor={true}
      modules={[Navigation]}
      className="md:pl-[50px] md:ml-[-50px] md:mr-[-50px]"
    >
      <SwiperSlide className="w-full md:pr-[50px]">
        <div className="w-full h-auto mt-[50px] rounded-md mb-[62px]">
          <p className="lssm:text-px20 md:text-[32px] mb-[50px] uppercase text-center">{params.id ? "Chỉnh sửa tin tức" : "Tạo tin tức mới"}</p>

          <div className="w-full mb-[30px]">
            <p className="mb-[10px] text-primary lssm:text-px14 md:text-px20 font-bold">
             Tiêu đề tin tức
            </p>
            <input
              name="titleVi"
              onChange={(event) => handleInput(event)}
              value={newData.titleVi}
              type="text"
              className="rounded-md border focus:outline-none w-full p-3 text-base"
            />
          </div>
          <div className="w-full mb-[30px]">
            <p className="mb-[10px] text-primary lssm:text-px14 md:text-px20 font-bold">Mô tả</p>
            <input
              name="descriptionVi"
              onChange={(event) => handleInput(event)}
              value={newData.descriptionVi}
              type="text"
              className="rounded-md border focus:outline-none w-full p-3 text-base"
            />
          </div>

          <div className="w-full mb-[30px]">
            <p className="mb-[10px] text-primary lssm:text-px14 md:text-px20 font-bold">
              Nội dung tin tức
            </p>
            {/* <RichtextEditor
          onBlur={(value) => {
            setNewValue(value);
          }}
          value={newValue}
          config={{
            readonly: false,
            placeholder: "",
          }}
        /> */}
            <div className="border border-solid border-border-color rounded-[8px] p-2 lssm:w-full float-right">
              <Editor
                content={newValue}
                setContent={setNewValue}
                editorRef={refNew}
                listImageFiles={listImageFiles}
              />
            </div>
            <div className="clear-both"></div>
          </div>

          <div className="w-full mb-[70px]">
            <p className="mb-[10px] text-primary lssm:text-px14 md:text-px20 font-bold">
            Tải ảnh đại diện tin tức
            </p>

            <div className="py-5 px-5 rounded-md border border-border-gray flex sc>768:flex-col items-center">
              <div className="flex sc991:w-full md:h-full sc<992:flex-col">
                <p className="lssm:text-px14 md:text-px16 font-medium md:mt-[-35px]  text-text-medium sc991:hidden">
                {t("dashboard.news.create.form.upload_format")}
                </p>
                <ButtonAddImage
                  className="sc<992:mt-[50px] sc991:w-full"
                  onClickButton={handleChoseFile}
                />
              </div>

              <div className="flex flex-col sc991:mt-[26px] sc<992:ml-[120px] sc991:w-full">
                <p className="lssm:text-px14 md:text-px16 font-medium text-text-medium mb-3">
                {t("dashboard.news.create.form.image_preview")}:
                </p>
                <div className="sc991:w-full sc<992:w-[250px] h-[141px] sc991:h-[186px] flex items-center justify-center bg-bg-gray rounded-md ">
                  {filePreview ? (
                    <img
                      className="w-full h-full rounded-md"
                      src={filePreview}
                      alt="imagedefault"
                    />
                  ) : (
                    <img src={`${hostBE}/fe/imagedefault.png`} alt="imagedefault" />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-end">
            <button
              onClick={goBack}
              className="mr-[10px] sc>768:flex-1 md:w-[100px] h-[50px] border rounded-md border-text-lightred text-text-lightred font-medium lssm:text-px14 md:text-px16 text-center"
            >
              {t("button.cancel")}
            </button>
            <div
              ref={navigationNextRef}
              onClick={setContentForEn} 
              className="sc>768:flex-1 md:w-[165px] lssm:text-px14 md:text-px16 flex justify-center items-center bg-primary text-white-color rounded-[5px] ml-3 h-[50px]"
            >
             {t("button.button_next")}
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide className="w-full ">
        <div className="w-full h-auto mt-[50px] rounded-md mb-[62px]">
        <p className="lssm:text-px20 md:text-[32px] mb-[50px] uppercase text-center">{params.id ? "Edit news" : "CREATE NEWS"}</p>

          <div className="w-full mb-[30px]">
            <p className="mb-[10px] text-primary lssm:text-px14 md:text-px20 font-bold">
            News title
            </p>
            <input
              name="titleEn"
              onChange={(event) => handleInput(event)}
              value={newData.titleEn}
              type="text"
              className="rounded-md border focus:outline-none w-full p-3 text-base"
            />
          </div>
          <div className="w-full mb-[30px]">
            <p className="mb-[10px] text-primary lssm:text-px14 md:text-px20 font-bold">
            Description
            </p>
            <input
              name="descriptionEn"
              onChange={(event) => handleInput(event)}
              value={newData.descriptionEn}
              type="text"
              className="rounded-md border focus:outline-none w-full p-3 text-base"
            />
          </div>

          <div className="w-full mb-[30px]">
            <p className="mb-[10px] text-primary lssm:text-px14 md:text-px20 font-bold">
            Content
            </p>
            <div className="border border-solid border-border-color rounded-[8px] p-2 lssm:w-full float-right">
              <Editor
                content={newValueEn}
                setContent={SetNewValueEn}
                editorRef={refNewEn}
                listImageFiles={listImageFilesEn}
              />
            </div>
          </div>

          <div className="flex w-full justify-end">
            <div
              ref={navigationPrevRef}
              onClick={slideToTop}
              className="sc>768:flex-1 md:w-[100px] border-text-lightred border border-solid  mt-4  flex justify-center items-center text-text-lightred lssm:text-px14 md:text-px16 font-bold rounded-[5px] lssm:mr-2 md:mr-3 h-[50px]"
            >
             {t("button.button_prev")}
            </div>

            <Button
              color="primary"
              onClick={submitfunc}
              className=" font-medium border sc>768:flex-1 sc>768:text-px14 rounded-md  mt-4 h-[50px]"
            >
            {t("button.upload")}
            </Button>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
}

export default CreateNews;
