import { Outlet } from "react-router-dom";
import { hostBE } from "../types/host";

export default function Career() {
  return (
    <div>
      <div className="banner_home_primary_images ">
        <img
          src={`${hostBE}/fe/banner_tuyendung.jpg`}
          alt="banner tuyen dung"
          className="w-full banner_home_primary_images  object-cover"
        />
      </div>
      <div className="sxl:px-[300px] md:px-[100px] sc>768:px-[24px] sc991:my-[50px] sc<992:my-[150px]">
        <Outlet />
      </div>
    </div>
  );
}
