
import { useTranslation } from "react-i18next";
import ManagerCareerItem from "../../../components/ManagerCareerItem";
import {  useAppSelector } from "../../../hooks/hook";



export default function CareerList () {
    const career = useAppSelector(state => state.careerSlice)
    const [t] = useTranslation();

    return (
        career.listCareer.length > 0 ? (
        <div className="lssm:my-[38px] md:my-[80px]">
            <h3 className="text-px20 mb-[38px] text-text-primary font-medium sc>768:hidden">{t("dashboard.recruitment.list_recui")}</h3>
            <div>
                {
                    career.listCareer.map((value, index) => {
                        return <ManagerCareerItem career={value} key={index} />
                    })
                }
            </div>

          
        </div>

        ): <div className="h-[400px] w-full flex justify-center items-center">{t("common.no_data")}</div>
    )
}