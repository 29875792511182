import clsx from "clsx";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import CartProduct from "../components/cart/CartProduct";
import Checkbox from "../components/InputCheck/Checkbox";
import Pagination from "../components/Pagination";
import "../App.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks/hook";
import { setCurrenPage } from "../reducers/productPublic";
import MultiContact from "../components/MultiContact";
import { getProductPublic } from "../reducers/productPublic";
import Loadding from "../components/Loadding/Loadding";
import _debounce from "lodash/debounce";
import {
  changeCategoryChecked,
  deleteCategoryChecked,
  getCategory,
} from "../reducers/categorySlice";
import { AiOutlineSearch } from "react-icons/ai";
import { hostBE } from "../types/host";

function Products() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [sort, setSort] = useState<number>(0);
  const [inputSearch, setInputSearch] = useState<string>("");
  const dispatch = useAppDispatch();
  const { isEnglish } = useAppSelector((state) => state.translateSlice);
  const { total, loadding, productPublicList, error, currentPage } =
    useAppSelector((state) => state.productPublic);
  const { categoryList, categoryFilter } = useAppSelector(
    (state) => state.categorySlice
  );

  const { t, i18n } = useTranslation();
  const [showGrid, setGrid] = useState<boolean>(true);
  const [fillter, setFillter] = useState<boolean>(false);
  const filterRef = useRef<HTMLDivElement>(null);
  const showRef = useRef<HTMLImageElement>(null);

  const handleChangeSort = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = parseInt(event.target.value);
    setSort(value);
  };
  const changeInputSearch = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setCurrenPage(1));
    navigate("");
    setInputSearch(event.target.value);
  };

  // tìm kiếm
  const handleDebounceFn = (keySearch: string) => {
    const typeSearch = isEnglish ? "vi" : "en";
    const numberCategory = categoryFilter.length > 0 ? categoryFilter[0] : 0;

    if (keySearch != "") {
      dispatch(
        getProductPublic({
          type: typeSearch,
          search: keySearch,
          categoryId: numberCategory,
          page: currentPage - 1,
          limit: 12,
          sort: sort,
        })
      );
    }
  };
  const debounceFn = useCallback(_debounce(handleDebounceFn, 1000), [
    categoryFilter,
  ]);

  //
  const handleFillter = () => {
    setFillter(!fillter);
    window.scrollTo(0, 0);
  };
  const handleChange = (id: number) => {
    navigate("");
    dispatch(setCurrenPage(1));
    dispatch(changeCategoryChecked(id));
  };
  const handleCheckActive = (id: number) => {
    const ischeck = categoryFilter.find((item, index) => {
      if (item === id) {
        return item;
      }
    });
    if (ischeck) {
      return true;
    } else {
      return false;
    }
  };

  const handleImageClick = (id: number) => {
    navigate(`/chitietsanpham/${id}`);
  };

  useEffect(() => {
    const evenWindowfunc = (event: any) => {
      const windowTaget = event.target as HTMLElement;
      const filterTarget = filterRef.current;
      const showTarget = showRef.current;
      const check1 = showTarget?.contains(windowTaget);
      const check = filterTarget?.contains(windowTaget);
      if (!check && !check1) {
        setFillter(false);
      }
    };
    window.addEventListener("click", evenWindowfunc);

    return () => {
      window.removeEventListener("click", evenWindowfunc);
    };
  }, [filterRef.current]);

  useEffect(() => {
    if (searchParams.get("page")) {
      const numberPage = Number(searchParams.get("page"));

      dispatch(setCurrenPage(numberPage));
    }
  }, [searchParams]);

  useEffect(() => {
    dispatch(getCategory());
    return () => {
      dispatch(deleteCategoryChecked());
    };
  }, []);
  useEffect(() => {
    const languageType = isEnglish ? "vi" : "en";
    const numberCategory = categoryFilter.length > 0 ? categoryFilter[0] : 0;
    if (inputSearch != "") {
      if (currentPage == 1) {
        debounceFn(inputSearch);
      } else {
        dispatch(
          getProductPublic({
            type: languageType,
            search: inputSearch,
            categoryId: numberCategory,
            page: currentPage - 1,
            limit: 12,
            sort: sort,
          })
        );
      }
    } else {
      dispatch(
        getProductPublic({
          type: languageType,
          search: null,
          categoryId: numberCategory,
          page: currentPage - 1,
          limit: 12,
          sort: sort,
        })
      );
    }
    return () => {
      debounceFn.cancel();
    };
  }, [currentPage, inputSearch, sort, isEnglish, categoryFilter]);

  return (
    <div className="w-full relative">
      <div className="banner_home_primary_images">
        <img
          className="w-full banner_home_primary_images object-cover"
          src={`${hostBE}/fe/bannersanpham.jpg`}
          alt="banner"
        />
      </div>

      <div className="w-full w-1920:px-[90px] flex m992:flex-row  flex-col border-t-2 border-border-box min-h-[100px] ">
        <div className="m992:w-1/4 w-full sm-480:block hidden">
          <div className="w-full h-full w-1920:pl-[70px] 2xl:pl-[105px] m992:pl-[50px] m992:pr-[0px] sm:px-[50px] px-[25px] xl:pl-[100px] w-1920:pt-[76px]  sm-480:pt-3 m992:pt-8 border-r-2 border-border-box bg-white">
            <p className="m992:mb-12 font-normal text-primary text-xl sm-480:mb-5">
              {t("produc_public.produc_category")}
            </p>
            <div className="w-full m992:block flex items-center flex-row flex-wrap">
              {categoryList.length > 0 ? (
                categoryList.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="m992:w-full w-auto sm-480:mr-7 mr-3"
                    >
                      <Checkbox
                        checkedList={categoryFilter}
                        ischecked={handleCheckActive(item.id)}
                        idChecked={item.id}
                        name={item.nameVi}
                        nameEn={item.nameEn}
                        descriptionEn={item.descriptionEn}
                        descriptionVi={item.descriptionVi}
                        reveres={false}
                        idInput={`${item.nameVi}${index}`}
                        lable={item.nameVi}
                        value={item.id}
                        handleChange={(id: number) => handleChange(id)}
                      />
                    </div>
                  );
                })
              ) : (
                <div className="m992:w-full w-auto sm-480:mr-7 mr-3">
                  <p className="text-text-red text-center text-lg">
                    {t("produc_public.no_produc_category")}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="w-1920:px-[46px] m992:w-3/4 w-full m992:pr-6 m992:pl-5 sm:px-[25px] xl:pr-[75px] xl:pl-5">
          <div className="sm:px-[25px] px-[30px] w-full sm-480:flex sm-480:mt-8 w-1920:mt-[70px] sm:items-center justify-between sm-480:items-start hidden">
            <h5 className="w-1920:text-px32 xl:text-2xl text-xl font-bold sm:w-auto sm-480:w-[70%] text-text-primary">
              {t("produc_public.product")}
            </h5>
            <div>
              <img
                onClick={() => setGrid(true)}
                className="w-1920:w-[30px] w-1920:h-[30px] xl:w-[25px] xl:h-[25px] sm-480:w-5 sm-480:h-5 cursor-pointer inline-block mr-7"
                src={`${hostBE}/fe/selectGrid.png`}
                alt="grid"
              />
              <img
                onClick={() => setGrid(false)}
                className="w-1920:w-[30px] w-1920:h-[30px] xl:w-[25px] xl:h-[25px] sm-480:w-5 sm-480:h-5 inline-block cursor-pointer"
                src={`${hostBE}/fe/selectList.png`}
                alt="grid"
              />
            </div>
          </div>

          <div className="2xl:px[25px] sm:px-[25px] px-[30px] w-full flex items-center flex-wrap justify-between mt-12 sm-480:pb-8 w-1920:pb-16 pb-3">
            <div className="sm-480:w-[65%] w-full mb-3 flex items-center justify-between">
              <div className="w-[85%] sm-480:w-full w-1920:w-full flex border border-border-box  justify-between sm-480:rounded-r-none rounded-md">
                <div className="2xl:w-[60px] lg:w-11 2xl:h-[60px] h-[38px] w-10 rounded-l-md border-r border-border-box flex items-center justify-center">
                  <AiOutlineSearch className="text-2xl text-primary" />
                </div>
                <input
                  value={inputSearch}
                  onChange={(event) => changeInputSearch(event)}
                  className="w-[90%] sm-480:rounded-r-none rounded-md  focus:outline-none 2xl:py-[17px] xl:py-[6px] px-6"
                  type="text"
                  placeholder={isEnglish ? "Nhập tìm kiếm ..." : "Search"}
                />
              </div>

              <div className="border-border-box border rounded-md  w-10 h-10 flex items-center justify-center  sm-480:hidden">
                <img
                  ref={showRef}
                  onClick={handleFillter}
                  className="w-5 h-3 
                "
                  src={`${hostBE}/fe/VectorFilter.png`}
                  alt="filter"
                />
              </div>
            </div>

            <div className="sm-480:w-[30%] w-full flex justify-between sm-480:border border-border-box 2xl:h-[60px] h-10  rounded-md sm-480:mb-3 mb-5">
              <div className="hidden rounded-l-md 2xl:w-[60px] lg:w-11 2xl:h-[60px] w-10 h-[38px] border-r border-border-box sm-480:flex items-center justify-center">
                <img
                  className="w-5 h-3
                "
                  src={`${hostBE}/fe/VectorFilter.png`}
                  alt="filter"
                />
              </div>
              <select
                onChange={(event) => {
                  handleChangeSort(event);
                }}
                value={sort}
                className="sm-480:w-9/12 w-3/4 focus:outline-none selecte text-primary sm-480:text-base text-px13 sm-480:rounded-r-md sm:border-none border border-border-box sm:rounded-r-md sm:border-l-0 rounded-md"
              >
                <option value={0}>{t(`select_product.select1`)}</option>
                <option value={1}>{t(`select_product.select2`)}</option>
                <option value={2}>{t(`select_product.select3`)}</option>
                <option value={3}>{t(`select_product.select4`)}</option>
              </select>
              <div className="flex items-center justify-center sm-480:hidden ">
                <img
                  onClick={() => setGrid(true)}
                  className="w-5 h-5 cursor-pointer inline-block lsm-320:mr-5 mr-2"
                  src={`${hostBE}/fe/selectGrid.png`}
                  alt="grid"
                />
                <img
                  onClick={() => setGrid(false)}
                  className="w-5 h-5 first-letter:inline-block cursor-pointer"
                  src={`${hostBE}/fe/selectList.png`}
                  alt="grid"
                />
              </div>
            </div>
          </div>

          {error ? (
            <div className="w-full min-h-[200px]">
              <p className="text-text-red text-center">{error}</p>
            </div>
          ) : loadding ? (
            <div className="w-full min-h-[200px] flex items-center justify-center">
              <Loadding />
            </div>
          ) : (
            <div
              className={clsx(
                "w-full flex flex-wrap h-auto items-center xl:px-0 m992:px-[15px] sm:px-[20px] px-[25px]",
                !showGrid && "sm-390:flex-col flex-wrap"
              )}
            >
              {productPublicList.length > 0 ? (
                productPublicList.map((product, index) => {
                  return (
                    <CartProduct
                      key={index}
                      cartContent={product}
                      isGrid={showGrid}
                      onClickImage={() => {
                        handleImageClick(product.id);
                      }}
                    />
                  );
                })
              ) : (
                <div className="w-full min-h-[200px]">
                  <p className="text-text-red text-center">
                    {t("produc_public.no_product")}
                  </p>
                </div>
              )}
            </div>
          )}

          {total > 0 && (
            <div className="md:px-0 sm-480:px-5 m992:pb-[60px] pb-7 px-0 pt-9 py-0">
              <Pagination
                total={Math.ceil(total / 12)}
                currenPage={currentPage}
                setCurrentPage={setCurrenPage}
              />
            </div>
          )}
        </div>
      </div>

      {/* cart */}

      {/* position fillter */}

      {fillter && (
        <div className="bg-fillter w-full sm-480:hidden absolute top-0 right-0 h-full flex justify-end">
          <div
            ref={filterRef}
            className="sm-390:w-3/5 w-9/12 h-full bg-white px-4 pt-5 pb-20 border border-border-box"
          >
            <div className="h-full w-full">
              <p className="mb-7 text-right text-base text-black font-bold">
                {t("produc_public.produc_category")}
              </p>
              {categoryList.length > 0 ? (
                categoryList.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="m992:w-full w-auto sm-480:mr-7 mr-3"
                    >
                      <Checkbox
                        checkedList={categoryFilter}
                        ischecked={handleCheckActive(item.id)}
                        idChecked={item.id}
                        name={item.nameVi}
                        nameEn={item.nameEn}
                        descriptionEn={item.descriptionEn}
                        descriptionVi={item.descriptionVi}
                        reveres={true}
                        idInput={`${item.nameVi}${index}`}
                        lable={item.nameVi}
                        value={item.nameVi}
                        handleChange={(id: number) => handleChange(id)}
                      />
                    </div>
                  );
                })
              ) : (
                <div className="m992:w-full w-auto sm-480:mr-7 mr-3">
                  <p className="text-text-red text-center text-lg">
                    {t("produc_public.no_produc_category")}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <MultiContact />
    </div>
  );
}

export default Products;
