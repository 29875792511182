import clsx from "clsx";
import {
  ChangeEvent,
  MouseEventHandler,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import getDate from "../../common/getDate";
import Input from "../../components/InputImage";
import Pagination from "../../components/Pagination";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import useClickOutItem from "../../hooks/useClickOutItem";
import {
  getCandidateFilter,
  getCandidates,
  setCurrentPage,
} from "../../reducers/career";
import careerService from "../../services/career";
import { Recruit } from "../../types";
import { hostBE } from "../../types/host";

const LIMIT = 10;
export default function CandidateProfile() {
  const [changeValue, setChangeValue] = useState<string>("");
  const dispatch = useAppDispatch();
  const careerData = useAppSelector((state) => state.careerSlice);
  const [recruit, setRecruit] = useState<Recruit[]>([]);
  const refChildren = useRef<HTMLUListElement>(null);
  const [searchParam, setSearchParams] = useSearchParams();
  const [t] = useTranslation();
  const {isEnglish} = useAppSelector(state => state.translateSlice);

  const { ref, isShow, setShow } = useClickOutItem(false);
  useEffect(() => {
    careerService.getCareerForFilter().then((data) => {
      setRecruit(data);
    });
  }, []);

  useEffect(() => {
    if (changeValue.trim() === "") {
      dispatch(getCandidates({ page: careerData.currentPage, limit: LIMIT }));
    } else {
      const career = recruit.find((item) => item.titleVi === changeValue);

      dispatch(
        getCandidateFilter({
          id: parseInt(career?.id + ""),
          option: { page: careerData.currentPage, limit: LIMIT },
        })
      );
    }
  }, [careerData.currentPage]);

  useEffect(() => {
    if (changeValue.trim() !== "") {
      const career = recruit.find((item) => item.titleVi === changeValue);
      dispatch(
        getCandidateFilter({
          id: parseInt(career?.id + ""),
          option: { page: 1, limit: LIMIT },
        })
      );
      setSearchParams({ page: "1" });
      dispatch(setCurrentPage(1));
    }
  }, [changeValue]);

  const handleAction = (event: any) => {
    if (event.target.tagName === "LI") {
      if (event.target.innerText === "Tất cả") {
        setChangeValue("");
        dispatch(getCandidates({ page: careerData.currentPage, limit: LIMIT }));
      } else {
        setChangeValue(event.target.innerText);
      }
    }

    setShow(!isShow);
  };

  const total = useMemo(() => {
    return Math.ceil(careerData.totalCandidates / LIMIT);
  }, [careerData.totalCandidates]);
  return (
    <div>
      <h2 className="text-center text-text-primary lssm:text-px20 md:text-[48px] font-bold mt-[74px] mb-[48px]">
      {t("dashboard.candidate.title")}
      </h2>
      <div className="w-full h-[60px] border border-solid border-border-color focus-within:border-primary flex items-center rounded-[5px]">
        <div className="h-full px-[24px] flex items-center border-r border-solid border-border-color">
          <img src={`${hostBE}/fe/VectorFilter.png`} alt="" />
        </div>
        <div
          className="relative w-full select-parent-dashboard"
          onClick={handleAction}
          ref={ref}
        >
          <Input value={changeValue} readOnly={true} className="z-10" />
          <ul
            ref={refChildren}
            className={clsx(
              "w-full border border-solid border-primary border-border-color select-children-dashboard_unactive shadow-lg bg-white-color list-none rounded-b-[12px]",
              { "select-children-dashboard": isShow }
            )}
          >
            <li className="w-full h-[30px] flex items-center hover:bg-[#f5f5f5] cursor-pointer px-2">
              Tất cả
            </li>

            {recruit.map((item) => (
              <li
                key={item.id}
                className="w-full h-[30px] flex items-center hover:bg-[#f5f5f5] cursor-pointer px-2"
              >
                {item.titleVi}
              </li>
            ))}
          </ul>
        </div>
      </div>

      {careerData.listCandidates.length > 0 ? (
        <div className="my-[80px] grid lssm:grid-cols-1 xl:grid-cols-2 gap-x-[50px] gap-y-[40px]">
          {careerData.listCandidates.map((candidate) => {
            return (
              <Link
                key={candidate.id}
                to={candidate.id + ""}
                className="border border-solid border-border-solid rounded-[10px] flex sc>768:flex-col p-[20px] md:items-center"
              >
                <div className="flex justify-center items-center border border-solid border-border-color rounded-[5px] lssm:w-full md:w-[230px] h-auto">
                  <img
                    src={
                      candidate.recruit?.avatarUrl !== ""
                        ? candidate.recruit!.avatarUrl
                        : `${hostBE}/fe/anficoLogo.png`
                    }
                    className="w-full object-cover max-h-[160px] rounded"
                    alt=""
                  />
                </div>
                <div className="sc>768:mt-[25px] md:ml-[25px]">
                  <p className="lssm:text-px16 md:text-px20 text-text-decoration font-semibold">
                    {isEnglish ? candidate.recruit?.titleVi : candidate.recruit?.titleEn}
                  </p>
                  <p className="lssm:text-px14 md:text-px16 text-text-decoration mb-3 mt-[18px]">
                  { (isEnglish ? "Tên: " : "Name: ") +  candidate.fullname }
                  </p>
                  <p className="lssm:text-px14 md:text-px16 text-text-decoration">
                    { (isEnglish ? "Ngày nộp hồ sơ: " : "Submission date: ") + getDate(candidate.createdDate + "")}
                  </p>
                </div>
              </Link>
            );
          })}
        </div>
      ) : (
        <div className="h-[400px] flex justify-center items-center ">
        {t("common.no_data")}
        </div>
      )}
      {careerData.currentPage < total ? (
        <div className="mb-[50px]">
          <Pagination
            currenPage={careerData.currentPage}
            setCurrentPage={setCurrentPage}
            total={total}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
