import HttpService from "../configs/api";
import { categoryType, ProducPublicType, ProductItems, productParam } from "../typeProps/Productype";
import { ResponsiveData } from "../types";
import getApi from "./getApi";
import { Option } from "../types";
const url = getApi("product");

const producService = {
  post: (data: any): Promise<ResponsiveData<ProductItems>> => {
    return HttpService.axiosClient.post(url, data);
  },
  getProductManager: (
    option: Option
  ): Promise<ResponsiveData<ProductItems[]>> => {
    return HttpService.axiosClient.get(url, {
      params: { page: option.page, limit: option.limit },
    });
  },
  getProducDetail: (id: number): Promise<ProductItems> => {
    const urlDetail = getApi("product/detail");
    return HttpService.axiosClient.get(`${urlDetail}/${id}`);
  },
  putProducStatus: (id: number) => {
    const urlput = getApi("product/priority");
    return HttpService.axiosClient.put(`${urlput}/${id}`);
  },
  editProductService: (data: ProducPublicType): Promise<ProducPublicType> => {
    return HttpService.axiosClient.put(url, data);
  },

  getCategoryHome: ():Promise<ResponsiveData<categoryType>> => {
    const url = getApi("categories")
    return HttpService.axiosClient.get(url);
  },
  searchProductsManager :(data:productParam ):Promise<ProducPublicType[]>=> {
    const url = getApi("product/search")
    return HttpService.axiosClient.get(`${url}/${data.keySearch}`, {params: {page: data.option.page, limit: data.option.limit}});
  },

  deleteProduct: (id: number): Promise<boolean> => {
    return HttpService.axiosClient.delete(`${url}/${id}`);
  }

};

export default producService;
