import { Outlet } from "react-router-dom";
import Banner from "../containers/LiblaryImage/Banner";

export default function LiblaryImage() {

    return  (
        <div>
            <Banner />
           <div className="lssm:my-[40px]  md:my-[80px] 2xl:my-[133px] lssm:px-[24px] lg:px-[80px] w-1920:px-[162px]">
            <Outlet/>
           </div>
        </div>
    )
}