import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import Button from "../../../components/Button";
import { useAppDispatch } from "../../../hooks/hook";
import { searchCareer } from "../../../reducers/career";
import { hostBE } from "../../../types/host";

const LIMIT = 10

type Props = {
  value: string
  setValue: (key:any) => void
}

export default function CareerSearch({value, setValue}:Props) {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [searchParam, setSearchParam] = useSearchParams();
    const [t] = useTranslation();

    const handleChangeValue = (event:ChangeEvent<HTMLInputElement>) => {
      setValue(event.currentTarget.value); 
 
    }


    useEffect(() => {
      if(value !== undefined) {
        dispatch(searchCareer({keyword: value, type: "vi" ,option: {page: 1, limit: LIMIT}}));
        setSearchParam({page: "1"});
      }
    }, [value])

    



    return (
        <div className="w-full flex items-center sc>768:flex-col">
          <div className="flex-1 flex sc>768:w-full items-center border-[1px] border-solid border-border-color min-h-[50px] rounded-md overflow-hidden  bg-white focus-within:border-primary">
            <input value={value ?? ""} onChange={handleChangeValue} className="h-full flex-1 pl-1 outline-none sc>768:order-2" />
            <span className="px-[15px] sc>768:order-1"><img src={`${hostBE}/fe/VectorSearch.png`} alt="" /></span>
          </div>
            
           <Button onClick={()=>navigate("create")} color="primary" className="flex items-center justify-center rounded-md h-[50px] sc>768:w-full md:w-[325px]  md:ml-[24px] sc>768:mt-[24px]">
              <span><img src={`${hostBE}/fe/addUser.png`} alt=""/></span><span className="sc>768:text-px14 md:text-px20 font-medium ml-2">{t("dashboard.recruitment.create_recui")}</span>
           </Button>

        </div>
    )
} 