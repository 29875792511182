
import Button from "../../../components/Button"
import Input from "../../../components/InputImage"
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import {Navigation} from "swiper";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup"
import uploadService from "../../../services/uploadImage";
import { useAppDispatch } from "../../../hooks/hook";
import careerService from "../../../services/career";
import { useNavigate, useParams } from "react-router-dom";
import { Recruit } from "../../../types";
import { pushPopup } from "../../../reducers/popupSlice";
import { FaBriefcase, FaMoneyBill, FaUsers } from "react-icons/fa";
import { RiMedalFill } from "react-icons/ri";
import { MdAddPhotoAlternate, MdOutlineTransgender } from "react-icons/md";
import { hostBE } from "../../../types/host";
import { useTranslation } from "react-i18next";

export default function EditCareer() {
    const navigationPrevRef = React.useRef(null)
    const navigationNextRef = React.useRef(null)
    const param = useParams();
    const navigate = useNavigate();
    const [recruit, setRecruit] = useState<Recruit>();
    const [t] = useTranslation();
    // const [careerData, setCareerData] = useState<Recruit>(null);

    const [file, setFile] = useState<File | null>(null)
    const [urlImagePreview, setUrlPreview] = useState<string>("");
    const dispatch = useAppDispatch();

    const formik = useFormik({
        initialValues: {
            titleEn: "",
            titleVi: "",
            workplaceEn: "",
            workplaceVi: "",
            dateExpiration: "",
            describeVi: "",
            interestVi: "",
            requireVi: "",
            describeEn: "",
            interestEn: "",
            requireEn: "",
            salaryEn: "",
            salaryVi: "",
            numPerson: "",
            levelEn: "",
            levelVi: "",
            genderVi: "",
            genderEn: "",
            experienceVi: "",
            experienceEn: "",
            workingFormEn: "",
            workingFormVi: ""
        },
        validationSchema: Yup.object({
            titleEn: Yup.string().required(t("validate.error.required")),
            titleVi: Yup.string().required(t("validate.error.required")),
            workplaceEn: Yup.string().required(t("validate.error.required")),
            workplaceVi: Yup.string().required(t("validate.error.required")),
            dateExpiration: Yup.string().required(t("validate.error.required")),
            describeVi: Yup.string().required(t("validate.error.required")),
            interestVi: Yup.string().required(t("validate.error.required")),
            requireVi: Yup.string().required(t("validate.error.required")),
            describeEn: Yup.string().required(t("validate.error.required")),
            interestEn: Yup.string().required(t("validate.error.required")),
            requireEn: Yup.string().required(t("validate.error.required")),
            salaryVi: Yup.string().required(t("validate.error.required")),
            salaryEn: Yup.string().required(t("validate.error.required")),
            numPerson: Yup.string().required(t("validate.error.required")).matches(/^[0-9]*$/, t("validate.error.pattern",{name: t("dashboard.recruitment.create.form.number")})),
            levelVi: Yup.string().required(t("validate.error.required")),
            levelEn: Yup.string().required(t("validate.error.required")),
            genderVi: Yup.string().required(t("validate.error.required")),
            genderEn: Yup.string().required(t("validate.error.required")),
            experienceVi: Yup.string().required(t("validate.error.required")),
            experienceEn: Yup.string().required(t("validate.error.required")),
            workingFormVi: Yup.string().required(t("validate.error.required")),
            workingFormEn: Yup.string().required(t("validate.error.required")),
            
        }) ,
        onSubmit: async (values) => {
            const formData = new FormData();
            let fileUrl;
            if(file) {
                formData.append("file", file);
                 fileUrl = await uploadService.upload(formData)

            }

            careerService.update({
                ...values,
                avatarUrl: fileUrl?.list[0].image_url ?? recruit?.avatarUrl+"",
                avatarPath : fileUrl?.list[0].image_path ?? recruit?.avatarPath+"",
                id: parseInt(param.idEdit+"")
            }).then((res:Recruit) => {
                navigate("/quanly/tuyendung")
                dispatch(pushPopup({
                    type: "SUCCESS",
                    message: t("notify.success", {name: t("notify.update")})
                }))
            }).catch(()=> {
                dispatch(pushPopup({
                    type: "SUCCESS",
                    message: t("notify.error", {name: t("notify.update")})
                }))
            })

       
            

            

        }
    })

    useEffect(()=> {
        if(param.idView || param.idEdit) {
            const id:any = param.idView ?? param.idEdit
         
            careerService.getById(id).then((data:Recruit) => {
                const dateString = new Date(data.dateExpiration);
                const date = dateString.setDate(dateString.getDate() + 3); 
                const defaultValue = new Date(date).toISOString().split('T')[0]
                setRecruit(data);
                    formik.setValues({
                        titleEn: data.titleEn,
                        titleVi: data.titleVi,
                        workplaceVi: data.workplaceVi,
                        workplaceEn: data.workplaceEn,
                        dateExpiration: defaultValue,
                        describeEn: data.describeEn,
                        describeVi: data.describeVi,
                        interestEn: data.interestEn,
                        interestVi: data.interestVi,
                        requireEn: data.requireEn,
                        requireVi: data.requireVi,
                        salaryVi: data.salaryVi,
                        salaryEn: data.salaryEn,
                        numPerson: data.numPerson,
                        levelVi: data.levelVi,
                        levelEn: data.levelEn,
                        genderVi: data.genderVi,
                        genderEn: data.genderEn,
                        experienceVi: data.experienceVi,
                        experienceEn: data.experienceEn,
                        workingFormVi:  data.workingFormVi,           
                        workingFormEn:  data.workingFormEn             
                    })
                    setUrlPreview(data.avatarUrl)
            })

        }

    }, [param])

    const handleChangeFile = (event:ChangeEvent<HTMLInputElement>) => {
        const file = event.currentTarget.files![0]
        if(file.size < 26675200) {
            setFile(file)
            setUrlPreview(URL.createObjectURL(file));
        }else {
            dispatch(pushPopup({
                message: t("notify.min_image", {size: "25MB"}),
                type: "WARNING"
            }))
        }
    }

    const navigateEdit = () => {
        navigate(`/quanly/tuyendung/edit/${param.idView}`)
    }

    const onInputClick = (e:React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        e.currentTarget.value = ""
    }

    const slideToTop = () => {
        window.scrollBy(0, -document.documentElement.scrollTop);
    }

    return <form onSubmit={formik.handleSubmit}>
                        
                    <Swiper
                    slidesPerView={1}
                    allowTouchMove={false}
                    navigation={{
                        // Both prevEl & nextEl are null at render so this does not work
                        prevEl: navigationPrevRef.current,
                        nextEl: navigationNextRef.current,
                    }}
                    onSwiper={(swiper:any) => {
                        // Delay execution for the refs to be defined
                        setTimeout(() => {
                        // Override prevEl & nextEl now that refs are defined
                        swiper.params.navigation.prevEl = navigationPrevRef.current
                        swiper.params.navigation.nextEl = navigationNextRef.current
                
                        // Re-init navigation
                        swiper.navigation.destroy()
                        swiper.navigation.init()
                        swiper.navigation.update()
                        })
                    }}
            
                    // navigation={true}
                    grabCursor={true}
                    modules={[ Navigation]}
                    className="w-full"
                >
                    
                             <SwiperSlide className="w-full">
                                <div>
                                <h2 className="uppercase text-[48px] sc>768:text-px20 text-text-primary font-bold text-center py-[74px] lssm:pb-[32px] md:pb-[117px]">{param.idView ? t("dashboard.recruitment.detail.title") : t("dashboard.recruitment.edit.title") }</h2>

                                    <div className=" mb-[80px] flex">
                                        <div className="grid lssm:grid-cols-1 md:grid-cols-2 gap-x-5 w-full">
                                        <div className="md:col-span-2">
                                            <label className="lssm:text-px14 md:text-px20 font-bold text-text-primary mb-4 block">{t("dashboard.recruitment.create.form.title")}</label>
                                          <Input readOnly={param.idView ? true : false} value={formik.values.titleVi} onChange={formik.handleChange} name="titleVi" />
                                          {formik.errors.titleVi &&  <small className="mt-1 text-px12 text-error_color">{formik.errors.titleVi}</small>}
                                        </div>

                                        <div className="md:col-span-2 mt-[32px]">
                                            <label className="lssm:text-px14 md:text-px20 font-bold text-text-primary mb-4 block">{t("dashboard.recruitment.create.form.upload_a")}:</label>
                                            <div className="border border-solid border-border-color p-[20px] grid lssm:grid-cols-1 md:grid-cols-2 outline-none rounded-[5px]">
                                                {
                                                    !param.idView && (
                                                    <div className="">
                                                            <p className="text-[#636363] mb-[30px] lssm:text-px14 md:text-px16 font-medium">{t("dashboard.recruitment.create.form.update_format")}</p>
                                                    
                                                        <label htmlFor='career-banner' className="lssm:w-full md:w-[220px] h-[120px] border-2 border-dashed border-primary text-text-primary flex flex-col items-center justify-center rounded-[5px]">
                                                             <span className="text-primary lssm:text-[32px] md:text-[48px]"><MdAddPhotoAlternate /></span>
                                                            <span className="mt-[32px]">{t("dashboard.recruitment.create.form.upload_i")}</span>
                                                            <input onChange={handleChangeFile} onClick={onInputClick} hidden type="file" id="career-banner" />
                                                        </label>
                                                    </div>
                                                        
                                                    )
                                                    
                                                 }


                                                    <div className="">
                                                            <p className="text-[#636363] mb-[30px] lssm:text-px14 md:text-px16 font-medium">{t("dashboard.recruitment.create.form.preview_i")}:</p>
                                                    
                                                        <div  className="lssm:w-full md:w-[220px] h-[120px] bg-[#6363631a] border-primary text-text-primary flex flex-col items-center justify-center rounded-[5px] overflow-hidden">
                                                            {
                                                                urlImagePreview !== "" ? (
                                                                    <img src={urlImagePreview} alt="" className="w-full h-full object-contain" />
                                                                ): (
                                                                    <img src={`${hostBE}/fe/previewImage.png`} alt="" />
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                            </div>
                                        </div>

                                        <div className="mt-[32px]">
                                            <label className="lssm:text-px14 md:text-px20 font-bold text-text-primary mb-4 block">{t("dashboard.recruitment.create.form.wage")}</label>
                                        <Input readOnly={param.idView ? true : false} value={formik.values.salaryVi} name="salaryVi" onChange={formik.handleChange} label={<FaMoneyBill/> } />
                                        {formik.errors.salaryVi &&  <small className="mt-1 text-px12 text-error_color">{formik.errors.salaryVi}</small>}

                                        </div>

                                        <div className="mt-[32px]">
                                            <label className="lssm:text-px14 md:text-px20 font-bold text-text-primary mb-4 block">{t("dashboard.recruitment.create.form.number")}</label>
                                            <Input readOnly={param.idView ? true : false} value={formik.values.numPerson} name="numPerson" onChange={formik.handleChange} label={<FaUsers />} />
                                        {formik.errors.numPerson &&  <small className="mt-1 text-px12 text-error_color">{formik.errors.numPerson}</small>}

                                        </div>

                                        <div className="mt-[32px]">
                                            <label className="lssm:text-px14 md:text-px20 font-bold text-text-primary mb-4 block">{t("dashboard.recruitment.create.form.working_form")}</label>
                                        <Input readOnly={param.idView ? true : false} value={formik.values.workingFormVi} name="workingFormVi" onChange={formik.handleChange}  label={<FaBriefcase />} />
                                        {formik.errors.workingFormVi &&  <small className="mt-1 text-px12 text-error_color">{formik.errors.workingFormVi}</small>}

                                        </div>

                                        <div className="mt-[32px]">
                                            <label className="lssm:text-px14 md:text-px20 font-bold text-text-primary mb-4 block">{t("dashboard.recruitment.create.form.level")}</label>
                                            <Input readOnly={param.idView ? true : false} value={formik.values.levelVi} name="level" onChange={formik.handleChange}  label={ <RiMedalFill />}/>
                                        {formik.errors.levelVi &&  <small className="mt-1 text-px12 text-error_color">{formik.errors.levelVi}</small>}

                                        </div>

                                        <div className="mt-[32px]">
                                            <label className="lssm:text-px14 md:text-px20 font-bold text-text-primary mb-4 block">{t("dashboard.recruitment.create.form.gender")}</label>
                                        <Input value={formik.values.genderVi} name="gender" onChange={formik.handleChange} label={ <MdOutlineTransgender />} />
                                        {formik.errors.genderVi &&  <small className="mt-1 text-px12 text-error_color">{formik.errors.genderVi}</small>}

                                        </div>

                                        <div className="mt-[32px]">
                                            <label className="lssm:text-px14 md:text-px20 font-bold text-text-primary mb-4 block">{t("dashboard.recruitment.create.form.experience")}</label>
                                            <Input readOnly={param.idView ? true : false} value={formik.values.experienceVi} name="experienceVi" onChange={formik.handleChange} image="exprientce_svg.svg" />
                                        {formik.errors.experienceVi &&  <small className="mt-1 text-px12 text-error_color">{formik.errors.experienceVi}</small>}

                                        </div>  

                                        <div className="mt-[32px] md:col-span-2">
                                            <label className="lssm:text-px14 md:text-px20 font-bold text-text-primary mb-4 block">{t("dashboard.recruitment.create.form.work_place")}</label>
                                            <Input readOnly={param.idView ? true : false} value={formik.values.workplaceVi} name="workplaceVi" onChange={formik.handleChange} />
                                        {formik.errors.workplaceVi &&  <small className="mt-1 text-px12 text-error_color">{formik.errors.workplaceVi}</small>}

                                        </div>

                                        <div className="mt-[32px] md:col-span-2">
                                            <label className="lssm:text-px14 md:text-px20 font-bold text-text-primary mb-4 block">{t("dashboard.recruitment.create.form.description")}</label>
                                        <textarea readOnly={param.idView ? true : false} value={formik.values.describeVi} name="describeVi" onChange={formik.handleChange} className="w-full p-2 lssm:h-[400px] md:h-[500px] border border-solid border-border-color focus-within:border-blue-500 outline-none rounded-[5px]">

                                        </textarea>
                                        {formik.errors.describeVi &&  <small className="mt-1 text-px12 text-error_color">{formik.errors.describeVi}</small>}

                                        </div>


                                        <div className="mt-[32px] md:col-span-2">
                                            <label className="lssm:text-px14 md:text-px20 font-bold text-text-primary mb-4 block">{t("dashboard.recruitment.create.form.required")}</label>
                                        <textarea readOnly={param.idView ? true : false} value={formik.values.requireVi} name="requireVi" onChange={formik.handleChange} className="w-full p-2 h-[300px] border border-solid border-border-color focus-within:border-blue-500 outline-none rounded-[5px]">

                                        </textarea>
                                        {formik.errors.requireVi &&  <small className="mt-1 text-px12 text-error_color">{formik.errors.requireVi}</small>}
                                        
                                        </div>

                                        <div className="mt-[32px] md:col-span-2">
                                            <label className="lssm:text-px14 md:text-px20 font-bold text-text-primary mb-4 block">{t("dashboard.recruitment.create.form.benefit")}</label>
                                        <textarea readOnly={param.idView ? true : false} value={formik.values.interestVi} name="interestVi" onChange={formik.handleChange} className="w-full p-2 h-[300px] border border-solid border-border-color focus-within:border-blue-500 outline-none rounded-[5px]">

                                        </textarea>
                                        {formik.errors.interestVi &&  <small className="mt-1 text-px12 text-error_color">{formik.errors.interestVi}</small>}

                                        </div>

                                        <div className="mt-[32px]">
                                            <label className="lssm:text-px14 md:text-px20 font-bold text-text-primary mb-4 block">{t("dashboard.recruitment.create.form.date")}</label>
                                            <Input readOnly={param.idView ? true : false} value={formik.values.dateExpiration} onChange={formik.handleChange} name="dateExpiration" type="date"  />
                                        {formik.errors.dateExpiration &&  <small className="mt-1 text-px12 text-error_color">{formik.errors.dateExpiration}</small>}

                                        </div>  

                                        <div className="md:col-span-2 flex justify-end mt-[32px]">
                                         
                                            {
                                                param.idView ? (
                                                    <Button onClick={navigateEdit} type="button" color="empty" className="h-[50px] lssm:text-px14 md:text-px16 w-[293px] sc>768:flex-1 mr-3">{t("dashboard.recruitment.detail.btn_update")}</Button>

                                                ): (
                                                    <Button onClick={()=>navigate(-1)} color="empty" className="sc>768:flex-1 sc>768:text-px14 mr-3">{t("button.button_prev")}</Button>
                                                    )
                                                }
                                                <div ref={navigationNextRef} onClick={slideToTop} className="sc>768:flex-1 md:w-[165px]  flex justify-center items-center bg-primary text-white-color lssm:text-px14 md:text-px16 font-bold rounded-[5px]  h-[50px]">{t("button.button_next")}</div>
                                        </div>

                                    </div>
                                </div>
                                </div>
                                </SwiperSlide>

                                <SwiperSlide className="w-full">
                                <div>
                                <h2 className="uppercase text-[48px] sc>768:text-px20 text-text-primary font-bold text-center py-[74px] lssm:pb-[32px] md:pb-[117px]">{param.idView ? "News details" : "Edit news" }</h2>

                                    <div className=" mb-[80px] flex">
                                        <div className="grid lssm:grid-cols-1 md:grid-cols-2 gap-x-5 w-full">
                                        <div className="md:col-span-2">
                                            <label className="lssm:text-px14 md:text-px20 font-bold text-text-primary mb-4 block">Recruitment title</label>
                                           <Input readOnly={param.idView ? true : false} value={formik.values.titleEn} onChange={formik.handleChange} name="titleEn" />
                                           {formik.errors.titleEn &&  <small className="mt-1 text-px12 text-error_color">{formik.errors.titleEn}</small>}
                                        </div>
                            
                                        <div className="mt-[32px]">
                                            <label className="lssm:text-px14 md:text-px20 font-bold text-text-primary mb-4 block">Wage offer</label>
                                        <Input readOnly={param.idView ? true : false} value={formik.values.salaryEn} name="salaryEn" onChange={formik.handleChange} label={<FaMoneyBill/> } />
                                        {formik.errors.salaryEn &&  <small className="mt-1 text-px12 text-error_color">{formik.errors.salaryEn}</small>}

                                        </div>

                                        <div className="mt-[32px]">
                                            <label className="lssm:text-px14 md:text-px20 font-bold text-text-primary mb-4 block">Number of vacancies</label>
                                            <Input readOnly={param.idView ? true : false} value={formik.values.numPerson} name="numPerson" onChange={formik.handleChange} label={<FaUsers />} />
                                        {formik.errors.numPerson &&  <small className="mt-1 text-px12 text-error_color">{formik.errors.numPerson}</small>}

                                        </div>

                                        <div className="mt-[32px]">
                                            <label className="lssm:text-px14 md:text-px20 font-bold text-text-primary mb-4 block">Working form</label>
                                        <Input readOnly={param.idView ? true : false} value={formik.values.workingFormEn} name="workingFormEn" onChange={formik.handleChange}  label={<FaBriefcase />} />
                                        {formik.errors.workingFormEn &&  <small className="mt-1 text-px12 text-error_color">{formik.errors.workingFormEn}</small>}

                                        </div>

                                        <div className="mt-[32px]">
                                            <label className="lssm:text-px14 md:text-px20 font-bold text-text-primary mb-4 block">Work rank</label>
                                            <Input readOnly={param.idView ? true : false} value={formik.values.levelEn} name="level" onChange={formik.handleChange}  label={ <RiMedalFill />}/>
                                        {formik.errors.levelEn &&  <small className="mt-1 text-px12 text-error_color">{formik.errors.levelEn}</small>}

                                        </div>

                                        <div className="mt-[32px]">
                                            <label className="lssm:text-px14 md:text-px20 font-bold text-text-primary mb-4 block">Gender</label>
                                        <Input value={formik.values.genderEn} name="genderEn" onChange={formik.handleChange} label={ <MdOutlineTransgender />} />
                                        {formik.errors.genderEn &&  <small className="mt-1 text-px12 text-error_color">{formik.errors.genderEn}</small>}

                                        </div>

                                        <div className="mt-[32px]">
                                            <label className="lssm:text-px14 md:text-px20 font-bold text-text-primary mb-4 block">Experience</label>
                                            <Input readOnly={param.idView ? true : false} value={formik.values.experienceEn} name="experienceEn" onChange={formik.handleChange} image="exprientce_svg.svg" />
                                        {formik.errors.experienceEn &&  <small className="mt-1 text-px12 text-error_color">{formik.errors.experienceEn}</small>}

                                        </div>  

                                        <div className="mt-[32px] md:col-span-2">
                                            <label className="lssm:text-px14 md:text-px20 font-bold text-text-primary mb-4 block">Work location</label>
                                            <Input readOnly={param.idView ? true : false} value={formik.values.workplaceEn} name="workplaceEn" onChange={formik.handleChange} />
                                        {formik.errors.workplaceEn &&  <small className="mt-1 text-px12 text-error_color">{formik.errors.workplaceEn}</small>}

                                        </div>

                                        <div className="mt-[32px] md:col-span-2">
                                            <label className="lssm:text-px14 md:text-px20 font-bold text-text-primary mb-4 block">Job description</label>
                                        <textarea readOnly={param.idView ? true : false}  value={formik.values.describeEn} name="describeEn" onChange={formik.handleChange}  className="w-full p-2 lssm:h-[400px] md:h-[500px] border border-solid border-border-color focus-within:border-blue-500 outline-none rounded-[5px]">

                                        </textarea>
                                        {formik.errors.describeEn &&  <small className="mt-1 text-px12 text-error_color">{formik.errors.describeEn}</small>}

                                        </div>


                                        <div className="mt-[32px] md:col-span-2">
                                            <label className="lssm:text-px14 md:text-px20 font-bold text-text-primary mb-4 block">Employee requirements</label>
                                        <textarea readOnly={param.idView ? true : false} value={formik.values.requireEn} name="requireEn" onChange={formik.handleChange}  className="w-full p-2 h-[300px] border border-solid border-border-color focus-within:border-blue-500 outline-none rounded-[5px]">

                                        </textarea>
                                        {formik.errors.requireEn &&  <small className="mt-1 text-px12 text-error_color">{formik.errors.requireEn}</small>}

                                        </div>

                                        <div className="mt-[32px] md:col-span-2">
                                            <label className="lssm:text-px14 md:text-px20 font-bold text-text-primary mb-4 block">Employee benefits</label>
                                        <textarea readOnly={param.idView ? true : false} value={formik.values.interestEn} name="interestEn" onChange={formik.handleChange}  className="w-full p-2 h-[300px] border border-solid border-border-color focus-within:border-blue-500 outline-none rounded-[5px]">

                                        </textarea>
                                        {formik.errors.interestEn &&  <small className="mt-1 text-px12 text-error_color">{formik.errors.interestEn}</small>}

                                        </div>

                                    <div className="md:col-span-2 flex justify-end mt-[32px]">
                                         
                                            <div ref={navigationPrevRef} onClick={slideToTop} className="sc>768:flex-1 md:w-[165px] flex justify-center items-center border border-solid border-[#F20E0E]  text-[#F20E0E] mr-3 lssm:text-px14 md:text-px16  rounded-[5px] ml-3 h-[50px]">{t("button.button_prev")}</div>
                                
                                                {
                                                    param.idEdit && (
                                                        <Button type="submit" color="primary" className="sc>768:flex-1 sc>768:text-px14 md:w-[292px]">{t("dashboard.recruitment.edit.btn_save")}</Button>

                                                    )
                                                }
                                             </div>

                                    </div>
                                </div>
                                </div>
                                </SwiperSlide>


                             
                   
                </Swiper>
                       

         </form>
}