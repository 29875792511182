import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import getDate from "../../common/getDate";
import careerService from "../../services/career";
import uploadService from "../../services/uploadImage";
import { Candidate } from "../../types";
import { saveAs } from "file-saver";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { useTranslation } from "react-i18next";

export default function CandidateProfileDetail() {
  const [candidate, setCandidates] = useState<Candidate>();
  const param = useParams();
  const [t] = useTranslation();

  useEffect(() => {
    careerService.getCandidatesById(parseInt(param.id + "")).then((data) => {
      setCandidates(data);
    });
  }, []);

  const downloadFile = () => {
    saveAs(
      candidate?.cvUrl + "",
      "CV" + candidate?.fullname + candidate?.cvUrl.slice(-4)
    );
  };

  return (
    <div>
      <h2 className="text-center text-text-primary lssm:text-px20 md:text-[48px] font-bold mt-[74px] lssm:mb-[32px] lg:mb-[117px] uppercase">
       {t("dashboard.candidate.detail.title")}
      </h2>
      <div className="grid lssm:grid-cols-2 lg:grid-cols-3 gap-y-[44px]">
        <div className="pb-[24px] border-b border-solid border-border-color">
          <p className="lssm:text-px14 md:text-px20 font-bold text-text-primary mb-[12px]">
          {t("career.form.name")}
          </p>
          <span className="text-[#272727] lssm:text-px14 md:text-px16">{candidate?.fullname}</span>
        </div>

        <div className="pb-[24px] border-b border-solid border-border-color">
          <p className="lssm:text-px14 md:text-px20 font-bold text-text-primary mb-[12px]">
          {t("career.form.gender")}
          </p>
          <span className="text-[#272727] lssm:text-px14 md:text-px16">
            {candidate?.gender ? t("form.gender.male") :  t("form.gender.female")}
          </span>
        </div>

        <div className="pb-[24px] border-b border-solid border-border-color">
          <p className="lssm:text-px14 md:text-px20 font-bold text-text-primary mb-[12px]">
          {t("career.form.birth")}
          </p>
          <span className="text-[#272727] lssm:text-px14 md:text-px16">
            {getDate(candidate?.birthday + "")}
          </span>
        </div>

        <div className="pb-[24px] border-b border-solid border-border-color">
          <p className="lssm:text-px14 md:text-px20 font-bold text-text-primary mb-[12px]">
          {t("career.form.email")}
          </p>
          <span className="text-[#272727] lssm:text-px14 md:text-px16">{candidate?.email}</span>
        </div>

        <div className="pb-[24px] border-b border-solid border-border-color">
          <p className="lssm:text-px14 md:text-px20 font-bold text-text-primary mb-[12px]">
          {t("career.form.phone")}
          </p>
          <span className="text-[#272727] lssm:text-px14 md:text-px16">{candidate?.phone}</span>
        </div>

        <div className="pb-[24px] border-b border-solid border-border-color">
          <p className="lssm:text-px14 md:text-px20 font-bold text-text-primary mb-[12px]">
          {t("career.form.address")}
          </p>
          <span className="text-[#272727] lssm:text-px14 md:text-px16">{candidate?.address}</span>
        </div>

        <div className="pb-[24px] border-b border-solid border-border-color lssm:col-span-2 lg:col-span-3">
          <p className="lssm:text-px14 md:text-px20 font-bold text-text-primary mb-[12px]">
          {t("career.form.education")}
          </p>
          <span className="text-[#272727] lssm:text-px14 md:text-px16">{candidate?.education}</span>
        </div>

        <div className="pb-[24px] border-b border-solid border-border-color lssm:col-span-2 lg:col-span-3">
          <p className="lssm:text-px14 md:text-px20 font-bold text-text-primary mb-[12px]">
          {t("career.form.experience")}
          </p>
          <span className="text-[#272727] lssm:text-px14 md:text-px16">{candidate?.experience}</span>
        </div>

        <div className="pb-[24px] border-b border-solid border-border-color lssm:col-span-2 lg:col-span-3">
          <p className="lssm:text-px14 md:text-px20 font-bold text-text-primary mb-[12px]">
          {t("career.form.goal")}
          </p>
          <span className="text-[#272727] lssm:text-px14 md:text-px16">{candidate?.goal}</span>
        </div>

        <div className="pb-[24px] border-b border-solid border-border-color lssm:col-span-2 lg:col-span-3">
          <p className="lssm:text-px14 md:text-px20 font-bold text-text-primary mb-[12px]">
          {t("career.form.presenter")}
          </p>
          <span className="text-[#272727] lssm:text-px14 md:text-px16">{candidate?.presenter}</span>
        </div>

        <div className="pb-[24px sc>768:col-span-2">
          <p className="lssm:text-px14 md:text-px20 font-bold text-text-primary mb-[12px]">
          {t("dashboard.candidate.detail.btn_save")}
          </p>
          <div className="flex w-full sc>768:justify-center mb-[24px]">
              <div
                onClick={downloadFile}
                className="w-[200px]  cursor-pointer lssm:text-px14 md:text-px16 h-[50px] rounded-xl text-white-color flex justify-center items-center font-medium bg-primary"
              >
                <span className="mr-2 text-[28px] text-white-color">
                <FaCloudDownloadAlt />
                </span>
                {t("dashboard.candidate.detail.btn_save")}
              </div>
          </div>
        </div>
      </div>
    </div>
  );
}
