import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { getAlbum } from "../../reducers/album";


export default function ListImage () {

    const dispatch = useAppDispatch()
    const albumData = useAppSelector(state => state.albumSlice.albums);
    const translate = useAppSelector(state => state.translateSlice)
    const [t] = useTranslation();

    useEffect(()=> {
        dispatch(getAlbum())
    }, [])


    return (
        <div className="">
                <h2 className="text-text-primary lssm:text-px20 md:text-[32px] xl:text-[48px] font-bold lssm:mb-[27px] uppercase  md:mb-[80px] 2xl:mb-[133px] text-center">{t("library_image.title")}</h2>
            <div className="grid lssm:grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-[50px]">
                {
                    albumData.map(item => {
                        return (
                        <Link to={item.id+""} key={item.id} className="">
                            <div className="h-[460px] rounded-md overflow-hidden">
                                <img src={item.topicImage?.imageUrl} alt="" className="w-full h-full object-cover" />
                            </div>
                            <p className="lssm:mt-[20px] md:mt-[40px] 2xl:mt-[60px] lssm:text-px14 sc>768:font-semibold md:text-px20 text-center">{translate.isEnglish ? item.descriptionVi : item.descriptionEn}</p>
                        </Link>
                        )
                    })
                }   
            </div>
                {
                    albumData.length === 0 && (
                        <div className="flex justify-center items-center h-[300px]">{translate.isEnglish ? "Không có dữ liệu" : "No data"}</div>
                    )
                }           
        </div>

    )
}