import { useTranslation } from "react-i18next";
import { MdClear } from "react-icons/md";
import Button from "../../components/Button";
import Input from "../../components/CareerInput";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ChangeEvent, useEffect, useState } from "react";
import { Recruit } from "../../types";
import { useNavigate, useParams } from "react-router-dom";
import careerService from "../../services/career";
import uploadService from "../../services/uploadImage";
import { pushPopup } from "../../reducers/popupSlice";
import { hostBE } from "../../types/host";

export default function FormModal() {
  const [t] = useTranslation();
  const [fileCv, setFileCv] = useState<File | null>(null);
  const [careerData, setCareer] = useState<Recruit>();
  const [messageCV, setMessageCv] = useState<string>(
    t("career.form.no_file_up")
  );
  const translate = useAppSelector((state) => state.translateSlice);
  const params = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      fullname: "",
      gender: "true",
      birthday: "",
      phone: "",
      email: "",
      address: "",
      education: "",
      experience: "",
      goal: "",
      presenter: "",
    },
    validationSchema: Yup.object({
      fullname: Yup.string()
        .required(t("validate.error.required"))
        .min(
          3,
          t("validate.error.min", { name: t("career.form.name"), min: 3 })
        ),
      gender: Yup.string().required(t("validate.error.required")),
      birthday: Yup.string().required(t("validate.error.required")),
      phone: Yup.string()
        .required(t("validate.error.required"))
        .matches(
          /^[0-9]{10}$/,
          t("validate.error.pattern", { name: t("career.form.phone") })
        ),
      email: Yup.string()
        .required(t("validate.error.required"))
        .matches(
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
          t("validate.error.pattern", { name: t("career.form.email") })
        ),
      address: Yup.string()
        .required(t("validate.error.required"))
        .min(
          3,
          t("validate.error.min", { name: t("career.form.address"), min: 3 })
        ),
      education: Yup.string()
        .required(t("validate.error.required"))
        .min(
          5,
          t("validate.error.min", { name: t("career.form.education"), min: 5 })
        ),
      experience: Yup.string()
        .required(t("validate.error.required"))
        .min(
          5,
          t("validate.error.min", {
            name: t("career.form.experience"),
            min: 5,
          })
        ),
      goal: Yup.string()
        .required(t("validate.error.required"))
        .min(
          5,
          t("validate.error.min", { name: t("career.form.goal"), min: 5 })
        ),
    }),
    onSubmit: (values) => {
      if (fileCv) {
        const formData = new FormData();
        formData.append("file", fileCv);
        uploadService.uploadFile(formData).then((data) => {
          careerService
            .postCandidate(parseInt(params.id + ""), {
              ...values,
              cvUrl: data.fileUrl,
              cvPath: data.filePath,
              gender: values.gender === "true" ? true : false,
            })
            .then((data) => {
              dispatch(
                pushPopup({
                  type: "SUCCESS",
                  message: t("career.detail.successCandidate"),
                })
              );
              navigate(-1);
            })
            .catch(() => {
              dispatch(
                pushPopup({
                  type: "ERROR",
                  message: t("career.detail.errorCandidate"),
                })
              );
            });
        });
      }
    },
  });

  useEffect(() => {
    careerService.getById(parseInt(params.id + "")).then((data) => {
      setCareer(data);
    });
  }, [params]);

  const handleChangeFile = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files![0];

    if (file.size > 10000000) {
      setMessageCv(t("validate.error.size", { size: "10MB" }));
    } else {
      setFileCv(file);
      setMessageCv(file.name);
    }
  };

  return (
    <div className="2xl:px-[200px] md:px-[30px] sc>768:px-[24px]  h-auto  py-[40px] bg-white-color rounded-[10px] ">
      <div className="flex lssm:text-px16 font-bold md:text-[32px] items-center pb-[40px] border-b-[1px] border-solid border-[#ffffff4d]">
        <h2 className="text-text-primary uppercase sc>768:text-center">
          {t("career.form.title")}:{" "}
          {translate.isEnglish ? careerData?.titleVi : careerData?.titleEn}
        </h2>
      </div>

      <form onSubmit={formik.handleSubmit} className="grid grid-cols-2 gap-x-6">
        <div className="flex flex-col sc>768:col-span-2 mb-6">
          <label className="text-text-primary lssm:text-px14 md:text-px16 font-bold mb-4">
            {t("career.form.name")}
            <span className="text-text-red">*</span>
          </label>
          <Input
            value={formik.values.fullname}
            onChange={formik.handleChange}
            name="fullname"
          />
          <small className="text-px14 text-[#fe6a38]">
            {formik.errors.fullname}
          </small>
        </div>

        <div className="flex flex-col sc>768:col-span-2 mb-6">
          <label className="text-text-primary lssm:text-px14 md:text-px16 font-bold mb-4">
            {t("career.form.gender")}
            <span className="text-text-red">*</span>
          </label>
          <select
            value={formik.values.gender}
            onChange={formik.handleChange}
            name="gender"
            className="border-[1px] px-1 border-solid border-text-primary outline-none  rounded-[10px] w-full h-[50px]"
          >
            <option value="true">{t("form.gender.male")}</option>
            <option value="false">{t("form.gender.female")}</option>
          </select>
          <small className="text-px14 text-[#fe6a38]">
            {formik.errors.gender}
          </small>
        </div>

        <div className="flex flex-col sc>768:col-span-2 mb-6">
          <label className="text-text-primary lssm:text-px14 md:text-px16 font-bold mb-4">
            {t("career.form.birth")}
            <span className="text-text-red">*</span>
          </label>
          <Input
            type="date"
            value={formik.values.birthday}
            name="birthday"
            onChange={formik.handleChange}
          />
          <small className="text-px14 text-[#fe6a38]">
            {formik.errors.birthday}
          </small>
        </div>

        <div className="flex flex-col sc>768:col-span-2  mb-6">
          <label className="text-text-primary lssm:text-px14 md:text-px16 font-bold mb-4">
            {t("career.form.phone")} <span className="text-text-red">*</span>
          </label>
          <Input
            value={formik.values.phone}
            name="phone"
            onChange={formik.handleChange}
          />
          <small className="text-px14 text-[#fe6a38]">
            {formik.errors.phone}
          </small>
        </div>

        <div className="flex flex-col mb-6 col-span-2">
          <label className="text-text-primary lssm:text-px14 md:text-px16 font-bold mb-4">
            {t("career.form.email")} <span className="text-text-red">*</span>
          </label>
          <Input
            type="email"
            value={formik.values.email}
            name="email"
            onChange={formik.handleChange}
          />
          <small className="text-px14 text-[#fe6a38]">
            {formik.errors.email}
          </small>
        </div>

        <div className="flex flex-col mb-6 col-span-2">
          <label className="text-text-primary lssm:text-px14 md:text-px16 font-bold mb-4">
            {t("career.form.address")} <span className="text-text-red">*</span>
          </label>
          <Input
            value={formik.values.address}
            name="address"
            onChange={formik.handleChange}
          />
          <small className="text-px14 text-[#fe6a38]">
            {formik.errors.address}
          </small>
        </div>

        <div className="flex flex-col mb-6 col-span-2">
          <label className="text-text-primary lssm:text-px14 md:text-px16 font-bold mb-4">
            {t("career.form.education")}{" "}
            <span className="text-text-red">*</span>
          </label>
          <textarea
            value={formik.values.education}
            name="education"
            onChange={formik.handleChange}
            className="border-[1px] px-1 py-1 border-solid border-border-color hover:border-text-primary outline-none  rounded-[10px] w-full"
            rows={5}
          ></textarea>
          <small className="text-px14 text-[#fe6a38]">
            {formik.errors.education}
          </small>
        </div>

        <div className="flex flex-col mb-6 col-span-2">
          <label className="text-text-primary lssm:text-px14 md:text-px16 font-bold mb-4">
            {t("career.form.experience")}
            <span className="text-text-red">*</span>
          </label>
          <textarea
            value={formik.values.experience}
            name="experience"
            onChange={formik.handleChange}
            className="border-[1px] px-1 py-1 border-solid border-border-color hover:border-text-primary outline-none  rounded-[10px] w-full"
            rows={5}
          ></textarea>
          <small className="text-px14 text-[#fe6a38]">
            {formik.errors.experience}
          </small>
        </div>

        <div className="flex flex-col mb-6 col-span-2">
          <label className="text-text-primary lssm:text-px14 md:text-px16 font-bold mb-4">
            {t("career.form.goal")} <span className="text-text-red">*</span>
          </label>
          <textarea
            value={formik.values.goal}
            name="goal"
            onChange={formik.handleChange}
            className="border-[1px] px-1 py-1 border-solid border-border-color hover:border-text-primary outline-none  rounded-[10px] w-full"
            rows={5}
          ></textarea>
          <small className="text-px14 text-[#fe6a38]">
            {formik.errors.goal}
          </small>
        </div>

        <div className="flex flex-col mb-6 col-span-2">
          <label className="text-text-primary lssm:text-px14 md:text-px16 font-bold mb-4">
            {t("career.form.presenter")}
          </label>
          <Input
            value={formik.values.presenter}
            name="presenter"
            onChange={formik.handleChange}
          />
          <small className="text-px14 text-[#fe6a38]">
            {formik.errors.presenter}
          </small>
        </div>

        <div className="flex flex-col mb-6 col-span-2">
          <label className="text-text-primary lssm:text-px14 md:text-px16 font-medium mb-4">
            {t("career.form.btn_up_file")}
            <span className="text-text-red">*</span>
          </label>
          <div className="flex lsm-380:items-center lssm:flex-col lsm-380:flex-row">
            <label className="w-[160px] sc>768:w-[130px] h-[50px] flex justify-center text-white-color lssm:text-px14 md:text-px16 font-bold sc>768:text-px14 bg-primary rounded-[10px] items-center">
              <span className="mr-[12px]">
                <img src={`${hostBE}/fe/icon_dowloadcv.png`} alt="icon" />
              </span>{" "}
              {t("career.form.btn_up_file")}
              <input
                hidden
                type="file"
                onChange={handleChangeFile}
                accept="application/pdf, application/vnd.ms-excel"
              />
            </label>
            <i className="ml-4 text-text-gray font-light lssm:text-px14 md:text-px16 sc>768:text-px12">
              {messageCV}
            </i>
          </div>
        </div>

        <div className="col-span-2 flex md:justify-end items-center">
          <Button
            onClick={() => navigate(-1)}
            type="button"
            className="text-[#F20E0E] border-[#F20E0E] sc>768:text-px14 h-[50px] sc>768:w-full"
            color="empty"
          >
            {t("career.form.btn_cancer")}
          </Button>
          <Button
            type="submit"
            className="sc>768:text-px14 lssm:ml-[10px] md:ml-[16px] h-[50px] sc>768:w-full md:w-[120px]"
            color="primary"
          >
            {t("career.form.btn_submit")}
          </Button>
        </div>
      </form>
    </div>
  );
}
