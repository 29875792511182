import ProjectDetail from "../../containers/Project/ProjectDetail";
import Relatedproject from "../../containers/Project/RelatedProject";



export default function DetailProject() {

    return (
        <div className="w-1920:px-[162px] flex sc991:flex-col sc>768:px-[24px] md:px-[100px] xl:px-[80px]" >
            <div className="sc<992:flex-[2]">
           <ProjectDetail />
            </div>
            <div  className="sc<992:flex-1">
                <Relatedproject />
            </div>
        </div>

    )


}