import clsx from "clsx";
import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiFillPlusCircle } from "react-icons/ai";
import { useParams } from "react-router-dom";
import Button from "../../components/Button";
import Checkbox from "../../components/Checkbox";
import DialogConfirm from "../../components/DialogConfirm";
import Pagination from "../../components/Pagination";
import ModalCreateAlbum from "../../containers/Dashboard/LibraryImage/ModalCreateAlbum";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { createImageToAlbum, deleteImageAlbum, getAlbumImage } from "../../reducers/album";
import { hideModal, showModal } from "../../reducers/modal";
import { pushPopup } from "../../reducers/popupSlice";
import albumService from "../../services/album";
import uploadService from "../../services/uploadImage";
import { Album } from "../../types";
import { hostBE } from "../../types/host";

const LIMIT = 20;

export default function LiblaryImagesDetail () {

    const [itemsDelete, setItemDeletes] = useState<number[]>([])
    const [isEdit, setEdit] = useState<boolean>(false)
    const [currentPage, setCurrentPage] = useState<any>(1);
    const dispatch = useAppDispatch();
    const [albumDetail, setAlbumDetail] = useState<Album>();
    const param = useParams();
    const {t} = useTranslation();

    const albumData = useAppSelector(state => state.albumSlice)
    const [value, setValue] = useState({
        descriptionEn: "",
        descriptionVi: ""
    });
    const refCheckboxs = useRef<any>([])

    useEffect(()=> {
        albumService.getById(parseInt(param.id+"")).then((data)=> {
            setAlbumDetail(data)
            setValue({
                descriptionEn: data.descriptionEn,
                descriptionVi: data.descriptionVi
            })
        })


    }, [])

    useEffect(()=> {
        dispatch(getAlbumImage(parseInt(param.id+"")))
    }, [currentPage])

    const handleChangeDelete = (event:ChangeEvent<HTMLInputElement>, value:number)=> {
        const isCheck = event.currentTarget.checked;
        if(isCheck) {
            setItemDeletes([...itemsDelete, value]);
        }else {
            const newArr = itemsDelete.filter(item => item !== value); 
            setItemDeletes(newArr);
           
        }


    }

    const showModalConfirmDelete = ()=> {
        if(itemsDelete.length > 0) {
            dispatch(showModal(<DialogConfirm message={`Bạn có chắc chắn muốn xóa ${itemsDelete.length} ảnh trong album “${albumDetail?.descriptionVi}” không ?`}  onClick={deleteImage} />))
        }
    }

    const deleteImage = ()=> {
        albumService.deleteImageAlbum(itemsDelete).then(data => {
            if(data) {
                dispatch(deleteImageAlbum(itemsDelete))
                dispatch(pushPopup({
                    type: "SUCCESS",
                    message: "Xóa ảnh thành công"
                }))
                dispatch(hideModal())
            }
        }).catch(()=> {
            dispatch(pushPopup({
                type: "ERROR",
                message: "Lỗi hệ thống"
            }))
            dispatch(hideModal())

        })
    }

    const handleChangeImage = (event:ChangeEvent<HTMLInputElement>) => {
       //@ts-ignore
       const files:any[] = [...event.currentTarget.files]
        const formData = new FormData();
        files.forEach((file:File, index) => {
            if(file.size < 26675200){
                formData.append("file", file);
            }else {
                if(files.length >= 2) {
                    dispatch(pushPopup({
                        message: t("notify.min_images", {no: index + 1, size: "25MB"}),
                        type: "WARNING"
                    }))
                } else {
                    dispatch(pushPopup({
                        message: t("notify.min_image", {size: "25MB"}),
                        type: "WARNING"
                    }))
                }
            }
        })


        uploadService.upload(formData).then(data => {
            const listImageAlbum = data.list.map(item => ({imageUrl: item.image_url, imagePath: item.image_path}));
            albumService.postImageAlbum(parseInt(param.id+""), listImageAlbum).then(data => {
                dispatch(createImageToAlbum(data.list))
                dispatch(pushPopup({
                    type: "SUCCESS",
                    message: t("notify.success", {name: t("notify.add")})
                }))
                setItemDeletes([])
            }).catch(error => {
                dispatch(pushPopup({
                    type: "ERROR",
                    message: t("notify.system_error")
                }))
            })

        })
       

    }

    const updateAlbum = () => {
        if(value.descriptionVi !== albumDetail?.descriptionVi || value.descriptionEn !== albumDetail?.descriptionEn) {
            albumService.updateAlbum({
                ...albumDetail,
                descriptionEn: value.descriptionEn,
                descriptionVi: value.descriptionVi
            }).then((data)=> {
                setAlbumDetail(data);
                dispatch(pushPopup({
                    type:"SUCCESS",
                    message: t("notify.success", {name: t("notify.update")})
                }))
                setEdit(false)
            }).catch(()=> {
                dispatch(pushPopup({
                    type:"ERROR",
                    message: t("notify.error", {name: t("notify.update")})
                }))
            })
        }
      
    }

    const onInputClick = (e:React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        e.currentTarget.value = ""
    }


    const handleChangeData = (event:ChangeEvent<HTMLInputElement>) => {
        const target = event.currentTarget;
        setValue({
            ...value,
            [target.name]: target.value
        })
    }

    const total = useMemo(()=> {
        return Math.ceil(albumData.total/LIMIT);
    }, [albumData.total])

    return (
        <div className="flex flex-col">
            <div className="mt-[74px] lssm:mb-[32px] md:mb-[107px] flex flex-wrap sc>768:justify-between">
           
                <div className=" sc>768:order-2 sc>768:mt-[50px] flex items-center  sc>768:w-[48%]">
                    <span onClick={()=> setEdit(!isEdit)} className="mr-5 cursor-pointer w-[40px]  sc>768:hidden"><img src={`${hostBE}/fe/edit_icon.png`} alt="" /></span>
                    <Button color="empty" onClick={()=> setEdit(!isEdit)} className="cursor-pointer w-full md:hidden h-[50px] text-text-primary sc>768:text-px14"><img className="mr-2" src={`${hostBE}/fe/editblue.png`} alt="" />{t("dashboard.imageLb.name_album")}</Button>

                </div>
                      {isEdit ?  <div className="flex  md:flex-1  flex-wrap sc>768:w-full">
                        <div className="flex flex-col md:flex-1 sc>768:w-full">
                            <input onChange={handleChangeData} value={value.descriptionVi} name="descriptionVi" type="text" className="h-[50px] w-full outline-none  px-5 border border-solid border-[#1a73e880] focus-within:border-primary"  /> 
                            <input onChange={handleChangeData} value={value.descriptionEn} name="descriptionEn" type="text" className="h-[50px] mt-[10px] outline-none  px-5 border border-solid border-[#1a73e880] focus-within:border-primary"  /> 
                        </div>
                        <Button onClick={updateAlbum} color="primary" className={clsx("h-[50px] sc>768:w-full md:w-[180px] sc>768:my-[5px] md:mx-[5px] md:ml-[27px]", {"opacity-60":value.descriptionVi===albumDetail?.descriptionVi&&value.descriptionEn===albumDetail.descriptionEn})}>Xác nhận</Button>
                      </div> : (<div className="sc>768:w-full"><h2 className="font-bold text-text-primary text-center  lssm:text-px20 md:text-[32px]">{albumDetail?.descriptionVi}</h2></div>)} 
         
                <div className="flex justify-end  w-full  mt-[50px] sc>768:order-3 sc>768:w-[48%]">

                    <Button color="primary" className="!bg-[#D70000] h-[50px] w-[120px] lssm:text-px14 md:text-px16 font-bold sc>768:w-full" onClick={showModalConfirmDelete}>{t("button.delete")}</Button>
                </div>
            </div>


             <div className="grid lssm:grid-cols-1 lsm-380:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 lssm:gap-x-[20px] md:gap-x-[40px] xl:gap-x-[68px] lssm:gap-y-[40px] md:gap-y-[60px] xl:gap-y-[100px] lssm:mb-[50px] md:mb-[180px]">
                <div>
                    <label htmlFor="add_image_album" className="h-[290px] w-full rounded-[10px] bg-[#F1F1F1] sc>768:h-[164px] flex flex-col justify-center items-center">
                        <span className="text-[72px] text-[#D9D9D9]"><AiFillPlusCircle /></span> 
                        <p className="lssm:text-px14 md:text-px16 mt-[20px] text-[#A7A7A7] sc>768:hidden text-center">{t("dashboard.imageLb.btn_add")}</p>
                        <input onChange={handleChangeImage} type="file" onClick={onInputClick} id="add_image_album" multiple={true} hidden />
                    </label>

                </div>

                {
                    albumData.albumImages.map((item, index) => {
                        return (
                                <div key={item.id}>
                                    <div className="h-[290px] w-full rounded-[10px] bg-[#F1F1F1] sc>768:h-[164px] flex justify-center items-center overflow-hidden relative">
                                        <img src={item.imageUrl} alt="" className="w-full h-full  object-cover" />
                                        <div className="absolute top-5 left-5 w-[25px] h-[25px] rounded-[100rem] bg-white">
                                            <Checkbox refChild={(element:any)=> refCheckboxs.current[index] = element} onChange={(event:ChangeEvent<HTMLInputElement>)=> handleChangeDelete(event, parseInt(item.id+""))} id={`album_${index}`} />
                                        </div>
                                    </div>
                                
                                </div>

                        )
                    })
                }


             </div>
             
                {/* <div className="flex justify-center mb-[92px]">
                         <Pagination currenPage={currentPage} setCurrentPage={setCurrentPage} total={total} />

                </div> */}
           
        </div>
    )
}