import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { FreeMode, Navigation, Thumbs, Zoom } from "swiper";
import { useState } from "react";
import React from "react";
import { MdKeyboardBackspace } from "react-icons/md";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { hideModal, showModal } from "../../reducers/modal";
import ViewImage from "./ViewImage";
import { saveAs } from "file-saver";
import { hostBE } from "../../types/host";
import clsx from "clsx";
type Props = {
  currentIndex: number;
};

export default function AlbumModal({ currentIndex }: Props) {
  const [activeThumb, setThumbActive] = useState<any>();
  const dispatch = useAppDispatch();
  const albumData = useAppSelector((state) => state.albumSlice);

  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  const [cindex, setCuindex] = useState(currentIndex);

  const upload = () => {
    let url = albumData.albumImages[cindex].imageUrl;
    saveAs(url, "hình ảnh" + albumData.albumImages[cindex].imageUrl.slice(-4));
  };

  return (
    <div className="fixed inset-0 bg-[#0000004d] flex justify-center sm:items-center">
      <div className="w-[100%]  sc>768:h-[100%] h-[100vh] bg-[#212124]   relative">
        <div
          className="absolute lssm:top-0 md:top-[10px] left-[10px]  text-px20 mt-2 md:block z-10"
          onClick={() => dispatch(hideModal())}
        >
          <span className="text-white-color flex items-center cursor-pointer">
            <MdKeyboardBackspace className="mr-2" /> Quay lại
          </span>
        </div>
        <div className="relative lssm:px-[40px] md:px-[100px] 2xl:px-[214px]">
          <Swiper
            slidesPerView={1}
            spaceBetween={30}
            thumbs={{
              swiper:
                activeThumb && !activeThumb.destroyed ? activeThumb : null,
            }}
            loop={false}
            onActiveIndexChange={(index) => {
              setCuindex(index.activeIndex);
            }}
            initialSlide={currentIndex}
            zoom={true}
            navigation={{
              // Both prevEl & nextEl are null at render so this does not work
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
            }}
            onSwiper={(swiper: any) => {
              // Delay execution for the refs to be defined
              setTimeout(() => {
                // Override prevEl & nextEl now that refs are defined
                swiper.params.navigation.prevEl = navigationPrevRef.current;
                swiper.params.navigation.nextEl = navigationNextRef.current;

                // Re-init navigation
                swiper.navigation.destroy();
                swiper.navigation.init();
                swiper.navigation.update();
              });
            }}
            // navigation={true}
            grabCursor={true}
            modules={[Navigation, Thumbs, Zoom, FreeMode]}
            className="sc>768:h-[500px] md:h-[750px] w-1920:h-[800px]  mb-[50px] md:mt-[50px] swiper-item"
          >
            {albumData.albumImages.map((item, index: any) => {
              return (
                <SwiperSlide key={index} className="flex justify-center w-full">
                  <img
                    src={item.imageUrl}
                    className="w-full h-full object-contain"
                    alt="ảnh mạng nhé"
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>

          <div
            ref={navigationPrevRef}
            className="absolute z-10 top-[50%] left-[18px] cursor-pointer translate-y-[-50%]"
          >
            <img src={`${hostBE}/fe/preBtn.png`} alt="pre" />
          </div>
          <div
            ref={navigationNextRef}
            className="absolute top-[50%] z-10 right-[18px]  cursor-pointer translate-y-[-50%]"
          >
            <img src={`${hostBE}/fe/nextBtn.png`} alt="next" />
          </div>
        </div>
        <div className="relative">
          <Swiper
            slidesPerView={9}
            // spaceBetween={30}
            initialSlide={currentIndex}
            loop={false}
            // pagination={{
            //   clickable: true,
            // }}
            // navigation={true}
            onSwiper={setThumbActive}
            watchSlidesProgress={true}
            freeMode={true}
            modules={[Navigation, Thumbs, FreeMode]}
            className={clsx("h-[80px]  mb-[30px] swiper-item-thumb sc>768:w-full sc>768:mb-[20px] md:w-[400px] lssm:bottom-[-20px] md:bottom-[unset] max-w-full absolute  md:left-[50%] ", {"lssm:left-[50%] lssm:translate-x-[-30%] md:translate-x-[-50%]":albumData.albumImages.length < 7, "lssm:left-[20%] lssm:translate-x-[-20%] md:translate-x-[-50%]":albumData.albumImages.length >= 7})}
          >
            {albumData.albumImages.map((item, index) => {
              return (
                <SwiperSlide
                  key={index}
                  className="flex justify-center w-[28px] h-[28px]"
                >
                  <img
                    className="max-h-[32px] max-w-[32px] min-w-[32px] min-h-[32px] object-cover rounded-md"
                    src={item.imageUrl}
                    alt="ảnh mạng nhé"
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
        <div className="md:hidden absolute bottom-3 px-[40px] w-[100%]">
          <button
            onClick={upload}
            className="text-px16 w-[100%] h-[50px] my-[30px] text-white-color border-[1px] border-solid border-white-color rounded-xl bg-[#e0ffff1a]"
          >
            Tải ảnh
          </button>
        </div>

        <div className="absolute bottom-[50px] right-[40px] sc>768:hidden">
          <button onClick={upload} className="text-white ">
            <img src={`${hostBE}/fe/downloadImage.png`} alt="btn-download" />
          </button>

    
        </div>
      </div>
    </div>
  );
}
