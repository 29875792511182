import { t } from "i18next";
import React, { ChangeEvent, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiFillDelete } from "react-icons/ai";
import resizeFile from "../../common/reSize";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { deleteHistory, putHistory } from "../../reducers/history";
import { showModal } from "../../reducers/modal";
import { pushPopup } from "../../reducers/popupSlice";
import historyService from "../../services/history";
import uploadService from "../../services/uploadImage";
import { HistoryState } from "../../types";
import { hostBE } from "../../types/host";
import DialogConfirm from "../DialogConfirm";

type Props = {
  lable: string;
  description: string;
  titleUpload: string;
  historyData: HistoryState;
  isView: boolean;
  setFileYear?: (File: File) => void;
  setHistoryData?: (data: HistoryState) => void | undefined;
  setSubmit?: (data: boolean) => void;
  isEnglish: boolean;
  refChi?: any;
  isEdit: boolean;
};
function HistoryEdit({
  lable,
  description,
  titleUpload,
  historyData,
  isView,
  setFileYear,
  setHistoryData,
  isEnglish,
  setSubmit,
  refChi,
  isEdit,
}: Props) {
  const [messageYear, setMessageYear] = useState("");
  const [editValue, setEditValue] = useState<HistoryState>(historyData);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const translate = useAppSelector((state) => state.translateSlice);
  useEffect(() => {
    setEditValue(historyData);
  }, [historyData]);

  const changeFile = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files![0];
    if (isEdit) {
      if (file.size < 26675200) {
        const formData = new FormData();
        const newFile: any = await resizeFile(file, {
          width: 315,
          height: 210,
        });
        formData.append("file", newFile);
        uploadService.upload(formData).then((data) => {
          dispatch(
            putHistory({
              ...editValue,
              imagePath: data.list[0].image_path,
              imageUrl: data.list[0].image_url,
            })
          );
        });
      } else {
        dispatch(
          pushPopup({
            message: t("notify.min_image", { size: "25MB" }),
            type: "WARNING",
          })
        );
      }
    } else {
      setFileYear?.(file);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.value.match(/^[0-9]{0,}$/)) {
      setEditValue({
        ...editValue,
        [event.currentTarget.name]: event.currentTarget.value,
      });
      setMessageYear("");
    } else {
      setMessageYear(
        translate.isEnglish ? "Vui lòng nhập số" : "Please enter the number"
      );
    }
  };

  const HandleCheckYear = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    if (!isView) {
      if (value !== historyData.year) {
        historyService.checkYear(parseInt(value)).then((data) => {
          if (!data) {
            setMessageYear(
              translate.isEnglish
                ? `Năm ${value} đã tồn tại`
                : `Year ${value} already exists`
            );
            setSubmit?.(false);
          } else {
            dispatch(
              putHistory({
                ...editValue,
              })
            );
            setMessageYear("");
            setSubmit?.(true);
          }
        });
      }
    }
  };

  const handleChangeText = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.currentTarget.value;
    if (value.length <= 1000) {
      setHistoryData?.({
        ...historyData,
        [event.currentTarget.name]: event.currentTarget.value,
      });
      setEditValue({
        ...editValue,
        [event.currentTarget.name]: event.currentTarget.value,
      });
    }
  };

  const updateDescription = () => {
    if (isEdit) {
      if (
        editValue.descriptionVi !== historyData.descriptionVi ||
        editValue.descriptionEn !== historyData.descriptionEn
      )
        dispatch(
          putHistory({
            ...editValue,
          })
        );
    }
  };

  const showDialogConfirm = () => {
    dispatch(
      showModal(
        <DialogConfirm
          message="Bạn có muốn xóa lịch sử này không?"
          onClick={handleDeleteHistory}
        />
      )
    );
  };

  const handleDeleteHistory = () => {
    dispatch(deleteHistory(parseInt(historyData.id + "")));
  };

  return (
    <div
      className="w-full h-auto flex flex-wrap justify-between mb-[50px] history-wap relative"
      ref={refChi}
    >
      <div className="w-full md:w-[32%] h-full">
        <label
          htmlFor=""
          className="text-primary lssm:text-px14 md:text-px20  font-bold"
        >
          {lable}
        </label>
        <input
          type="text"
          value={editValue.year}
          readOnly={isView}
          onChange={handleChange}
          name="year"
          onBlur={HandleCheckYear}
          className="focus:outline-none focus:border-primary rounded-md border border-border-gray w-full px-5 py-[15px] lssm:text-px14 md:text-px16 font-medium text-black mt-4 mb-1"
        />
        <p className="lssm:text-px12 md:text-px14 text-[#EE0000]">
          {messageYear}
        </p>

        <label
          htmlFor={isView ? "" : "choseFile" + historyData.id}
          className="w-full h-[184px] flex border-dotted border-2 border-primary items-center justify-center flex-col rounded-md mt-8"
        >
          {editValue.imageUrl !== "" ? (
            <img
              src={editValue.imageUrl}
              alt=""
              className="w-full max-h-full object-contain"
            />
          ) : (
            <>
              <img src={`${hostBE}/fe/addImage.png`} alt="anh" />
              <p className="font-normal mt-3 text-center text-base">
                {titleUpload}
              </p>
            </>
          )}
          {!isView && (
            <input
              readOnly={isView}
              onChange={changeFile}
              id={"choseFile" + historyData.id}
              type="file"
              className="hidden"
            />
          )}
        </label>
      </div>
      <div className="md:w-[62%] w-full h-full flex flex-col items-start sc>768:mt-[32px]">
        <label className="text-primary lssm:text-px14 md:text-px20  font-bold">
          {description}
        </label>
        <textarea
          rows={10}
          onChange={handleChangeText}
          onBlur={updateDescription}
          readOnly={isView}
          value={isEnglish ? editValue.descriptionEn : editValue.descriptionVi}
          name={isEnglish ? "descriptionEn" : "descriptionVi"}
          className="focus:outline-none mt-4 sc>768:text-px14 rounded-sm w-full py-5 px-[23px] border border-border-gray focus:border-primary"
        ></textarea>
        <div className="flex justify-end w-full mt-1">
          <p className="lssm:text-px12 md:text-px14 text-right text-text-gray">
            {isEnglish
              ? editValue.descriptionEn?.length ?? 0
              : editValue.descriptionVi?.length ?? 0}
            /1000
          </p>
        </div>
      </div>
      <div
        className="w-[40px] h-[40px] flex justify-center items-center bg-error_color text-white-color rounded-b-[10px] absolute  history-wap_child bottom-0 translate-y-[-100%] left-0 cursor-pointer"
        onClick={showDialogConfirm}
      >
        <span className="text-px20">
          <AiFillDelete />
        </span>
      </div>
    </div>
  );
}

export default HistoryEdit;
