import clsx from "clsx";
import { t } from "i18next";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import resizeFile from "../../common/reSize";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { getCategory } from "../../reducers/categorySlice";
import { pushPopup } from "../../reducers/popupSlice";
import { editProject } from "../../reducers/projectmanagerSlice";
import projectServiece from "../../services/project";
import uploadService from "../../services/uploadImage";
import { hostBE } from "../../types/host";
import Editor from "../Editor";
import { LableContent } from "./LableContent";

type categoryType = {
  createdBy: string;
  createdDate: string;
  id: number;
  name: string;
  activated: boolean;
}[];

type ProductsProps = {
  handleCurrenPage?: () => void;
};

function ProducSlideProject({ handleCurrenPage }: ProductsProps) {
  const dispatch = useAppDispatch();
  const navigator = useNavigate();
  const param = useParams();
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const scroToTop = useRef<HTMLDivElement>(null);
  const [files, setFiles] = useState<any[] | []>([]);
  const [preview, setPreview] = useState<any[]>([]);
  const [contentvi, setContentVi] = useState<any>("");
  const [contentEn, setContentEn] = useState<any>("");
  const [isdisAble, setDisable] = useState<any>("");
  const { isEnglish } = useAppSelector((state) => state.translateSlice);
  const refNewVi = useRef<any>();
  const refNewEn = useRef<any>();
  const listImageFiles = useRef<
    {
      id: number;
      file: File | string;
    }[]
  >([]);
  const listImageFilesEn = useRef<
    {
      id: number;
      file: File | string;
    }[]
  >([]);

  const [projectinput, setProductInput] = useState({
    avatarUrl: "",
    avatarPath: "",
    descriptionEn: "",
    descriptionVi: "",
    titleEn: "",
    titleVi: "",
    createdDate: "",
  });

  const slideToTop = () => {
    if (scroToTop) {
      window.scrollTo(0, 0);
    }
  };
  const handleInput = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const name = event.target.name;
    const value = event.target.value;
    setProductInput({
      ...projectinput,
      [name]: value,
    });
  };

  const handleChoseFile = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files;
    if (file && files.length < 1 && preview.length < 1) {
      const FileSize = file[0].size;
      if (FileSize > 26675200) {
        dispatch(
          pushPopup({
            type: "WARNING",
            message: "Tệp ảnh không quá 25Mb",
          })
        );
      } else {
        const newFile = await resizeFile(file[0], { width: 870, height: 580 });
        setFiles([...files, newFile]);
        const namePreview = URL.createObjectURL(newFile);
        setPreview([...preview, namePreview]);
      }
    }
    event.target.value = "";
  };

  const handleDeletePreview = (indexDelete: number) => {
    const newPreview: string[] = preview.filter((item, index) => {
      return item != preview[indexDelete];
    });
    const newFiles = files.filter((item, index) => {
      return item != files[indexDelete];
    });
    setPreview([...newPreview]);
    setFiles([...newFiles]);
  };
  const hiddenformAdd = () => {
    if (handleCurrenPage) {
      handleCurrenPage();
    }
  };

  const handleSubmit = async () => {
    const output = await refNewVi.current?.save();
    const outPutEn = await refNewEn.current?.save();
    if (
      projectinput.titleEn == "" ||
      projectinput.titleVi == "" ||
      projectinput.descriptionEn == "" ||
      projectinput.descriptionVi == "" ||
      output.blocks.length === 0 ||
      outPutEn.blocks.length === 0
    ) {
      dispatch(
        pushPopup({
          message: isEnglish
            ? "Chưa nhập đầy đủ thông tin các trường"
            : "Full information not enter",
          type: "WARNING",
        })
      );
    } else {
      const formProjectSend: any = { ...projectinput };
      if (listImageFiles.current.length > 0) {
        const form_data = new FormData();
        const listfile = listImageFiles.current.map((item) => item.file);

        listfile.forEach((file) => {
          form_data.append("file", file);
        });
        const data = await uploadService.upload(form_data);
        const listNewImage = listImageFiles.current;
        data.list.forEach((item, index) => {
          listNewImage[index].file = item.image_url;
        });
        listImageFiles.current = [...listNewImage];

        listImageFiles.current.forEach((item) => {
          if (output?.blocks[item.id].type === "image") {
            output.blocks[item.id].data.file.url = item.file;
          }
        });
      }
      const content = JSON.stringify(output);
      setContentVi(content);
      if (listImageFilesEn.current.length > 0) {
        const form_data = new FormData();
        const listfileEn = listImageFilesEn.current.map((item) => item.file);
        listfileEn.forEach((file) => {
          form_data.append("file", file);
        });

        const data = await uploadService.upload(form_data);
        const listNewImage = listImageFilesEn.current;
        data.list.forEach((item, index) => {
          listNewImage[index].file = item.image_url;
        });
        listImageFilesEn.current = [...listNewImage];
        listImageFilesEn.current.forEach((item) => {
          if (outPutEn?.blocks[item.id].type === "image") {
            outPutEn.blocks[item.id].data.file.url = item.file;
          }
        });
      }
      const contentEn = JSON.stringify(outPutEn);
      setContentEn(contentEn);

      if (param.id) {
        if (preview.length > 0) {
          if (files.length > 0) {
            const data = new FormData();
            for (let i = 0; i < files.length; i++) {
              data.append("file", files[i]);
            }
            const { list } = await uploadService.upload(data);
            if (list) {
              formProjectSend.avatarUrl = list[0].image_url;
              formProjectSend.avatarPath = list[0].image_path;
            } else {
              dispatch(
                pushPopup({
                  type: "WARNING",
                  message: "Không tải được ảnh",
                })
              );
              return;
            }
          }
          formProjectSend.id = param.id;
          formProjectSend.contentVi = content;
          formProjectSend.contentEn = contentEn;
          dispatch(editProject(formProjectSend));
          navigator("/quanly/du_an");
        } else {
          dispatch(
            pushPopup({
              type: "WARNING",
              message: isEnglish
                ? "Bạn chưa chọn ảnh thay thế."
                : "You have not selected a replacement image.",
            })
          );
        }
      } else {
        if (files.length > 0) {
          const data = new FormData();
          for (let i = 0; i < files.length; i++) {
            data.append("file", files[i]);
          }
          const { list } = await uploadService.upload(data);
          formProjectSend.avatarUrl = list[0].image_url;
          formProjectSend.avatarPath = list[0].image_path;
          formProjectSend.contentEn = contentEn;
          formProjectSend.contentVi = content;
          setDisable(true);
          const result = await projectServiece.postProject(formProjectSend);
          if (result) {
            hiddenformAdd();
            dispatch(
              pushPopup({
                type: "SUCCESS",
                message: isEnglish
                  ? "Thêm dự án thành công."
                  : "Add successful project.",
              })
            );
            navigator("/quanly/du_an");
          } else {
            dispatch(
              pushPopup({
                type: "WARNING",
                message: isEnglish
                  ? "Không thêm dự án được."
                  : "Can't add projects.",
              })
            );
            setDisable(true);
          }
        } else {
          dispatch(
            pushPopup({
              type: "WARNING",
              message: isEnglish
                ? "Bạn chưa chọn ảnh."
                : "You have not selected a photo.",
            })
          );
        }
      }
    }
  };

  useEffect(() => {
    return () => {
      preview.length > 0 &&
        preview.forEach((item: string, index: number) => {
          URL.revokeObjectURL(item);
        });
    };
  }, []);
  useEffect(() => {
    dispatch(getCategory());
  }, []);
  useEffect(() => {
    if (param.id) {
      const idedit = parseInt(param.id);
      const callDetail = async () => {
        const editProductDetail = await projectServiece.getById(idedit);
        setProductInput({
          ...projectinput,
          ...editProductDetail,
        });
        setPreview([editProductDetail.avatarUrl]);
        setContentVi(editProductDetail.contentVi);
        setContentEn(editProductDetail.contentEn);
      };
      callDetail();
    }
  }, []);

  return (
    <div
      ref={scroToTop}
      className="w-full w-1920:my-[120px] mt-0  h-auto bg-white"
    >
      <Swiper
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        simulateTouch={false}
        onSwiper={(swiper: any) => {
          setTimeout(() => {
            swiper.params.navigation.prevEl = navigationPrevRef.current;
            swiper.params.navigation.nextEl = navigationNextRef.current;
            swiper.navigation.destroy();
            swiper.navigation.init();
            swiper.navigation.update();
          });
        }}
        modules={[Navigation]}
      >
        <SwiperSlide className="w-full sm-480:ml-[-2px] ml-0">
          <div className="w-full flex justify-end h-auto m992:pl-7 sm-480:pl-6">
            <div className="w-full h-auto bg-white rounded-[20px] sm-480:pb-[70px] w-1920:px-0 md:px-8 pr-[1px]">
              <p className="py-[50px] sm-480:text-px32 font-bold text-xl lg:text-black text-primary w-full text-center">
                {param.id ? "SỬA THÔNG TIN DỰ ÁN" : "THÊM DỰ ÁN MỚI"}
              </p>
              <LableContent content="Tải lên ảnh danh mục" />
              <div className="w-full flex flex-wrap xl:justify-start justify-between sm-480:mb-12 mb-[30px]">
                <div className="w-1920:w-[220px] xl:w-1/4 w-[48%] border-2 border-dashed border-primary rounded-md sm-480:h-[120px] h-[94px] flex items-center justify-center mb-[10px]">
                  <label
                    htmlFor="file"
                    className="flex flex-col cursor-pointer items-center justify-center"
                  >
                    <img
                      src={`${hostBE}/fe/addImage.png`}
                      alt="taianh"
                      className="w-auto h-auto mb-3"
                    />
                    <span className="font-normal text-primary sm-480:text-base text-sm">
                      Tải ảnh lên
                    </span>
                  </label>
                  <input
                    type="file"
                    onChange={(event) => {
                      handleChoseFile(event);
                    }}
                    id="file"
                    className="hidden"
                  />
                </div>

                {preview.length > 0 &&
                  preview.map((item: string, index: number) => {
                    return (
                      <div
                        key={index}
                        className="imagepreview w-1920:w-[220px] xl:w-1/4 w-[48%] lg:mb-0 mb-[10px] border-none rounded-md sm-480:h-[120px] h-[94px] xl:px-3 px-0"
                      >
                        <div className="w-full h-full relative rounded-md">
                          <div className="rounded-md border-solid border-4 border-text-primary img-delete absolute z-20 w-full h-full top-0 left-0 bg-bg-lightgray">
                            <div className="relative w-full h-full">
                              <img
                                onClick={() => {
                                  handleDeletePreview(index);
                                }}
                                className="absolute top-3 right-5 cursor-pointer"
                                src={`${hostBE}/fe/delete_icon.png`}
                                alt="delete"
                              />
                              <img
                                className="absolute bottom-3 left-5 cursor-pointer"
                                src={`${hostBE}/fe/startround.png`}
                                alt="anh"
                              />
                            </div>
                          </div>
                          <img
                            src={item}
                            alt="ảnh"
                            className="w-full h-full rounded-md"
                          />
                        </div>
                      </div>
                    );
                  })}
              </div>

              {/* tên sản phẩm input */}
              <div className="w-full sm-480:mb-12 mb-[30px]">
                <LableContent content="Tên dự án" />
                <input
                  value={projectinput.titleVi}
                  name="titleVi"
                  onChange={(event) => {
                    handleInput(event);
                  }}
                  type="text"
                  className="w-full px-5 py-3 sm-480:text-base text-sm focus:outline-none border border-border-gray rounded-md"
                />
              </div>
              {/* mô tả sản phẩm input */}

              <div className="w-full sm-480:mb-12 mb-[30px]">
                <LableContent content="Mô tả dự án" />
                <textarea
                  rows={10}
                  cols={100}
                  value={projectinput.descriptionVi}
                  name="descriptionVi"
                  onChange={(event) => {
                    handleInput(event);
                  }}
                  className="p-3 focus:outline-text-primary sm-480:text-base text-sm rounded-[10px] border border-border-gray w-full"
                ></textarea>
              </div>

              <div className="w-full sm-480:mb-12 mb-[30px]">
                <LableContent content="Chi tiết dự án" />

                <p className="mb-4 text-primary sm-480:text-base text-sm font-normal">
                  Văn bản chi tiết dự án
                </p>
                <div className="w-full h-auto flex flex-col mb-7 border border-border-gray ">
                  <Editor
                    content={contentvi}
                    setContent={setContentVi}
                    editorRef={refNewVi}
                    listImageFiles={listImageFiles}
                  />
                </div>
              </div>

              <div className="text-right mb-[60px]">
                <button
                  onClick={() => {
                    navigator("/quanly/du_an");
                  }}
                  className="2xl:py-[18px] py-[14px] mr-3 2xl:w-[180px] sm-390:w-[150px] w-[100px] border rounded-md border-text-lightred text-text-lightred font-medium sm-480:text-base text-sm text-center"
                >
                  Hủy
                </button>
                <button
                  onClick={slideToTop}
                  ref={navigationNextRef}
                  className="2xl:py-[18px] cursor-pointer py-[14px] inline-block font-medium 2xl:w-[180px] sm-390:w-[150px] w-[100px] border rounded-md bg-primary text-white sm-480:text-base text-sm text-center"
                >
                  Tiếp theo
                </button>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="w-full flex justify-end h-auto m992:pl-7 sm-480:pl-6">
            <div className="w-full h-auto bg-white rounded-[20px] sm-480:pb-[70px] w-1920:px-0 md:px-8 pl-1 sm-480:mr-2 mr-[4px]">
              <p className="py-[50px] sm-480:text-px32 font-bold text-xl lg:text-black text-primary w-full text-center">
                {param.id ? "EDIT PROJECT DETAIL" : "ADD NEW PROJECT"}
              </p>
              <LableContent content="Upload project picture" />
              <div className="w-full flex flex-wrap xl:justify-start justify-between sm-480:mb-12 mb-[30px]">
                <div className="w-1920:w-[220px] xl:w-1/4 w-[48%]  border-2 border-dashed border-primary rounded-md sm-480:h-[120px] h-[94px] flex items-center justify-center mb-[10px]">
                  <label className="flex flex-col cursor-not-allowed items-center justify-center">
                    <img
                      src={`${hostBE}/fe/addImage.png`}
                      alt="taianh"
                      className="w-auto h-auto mb-3"
                    />
                    <span className="font-normal text-primary sm-480:text-base text-sm">
                      Upload picture
                    </span>
                  </label>
                </div>

                {preview.length > 0 &&
                  preview.map((item: string, index: number) => {
                    return (
                      <div
                        key={index}
                        className="w-1920:w-[220px] xl:w-1/4 w-[48%] lg:mb-0 mb-[10px] border-none rounded-md sm-480:h-[120px] h-[94px] xl:px-3 px-0"
                      >
                        <img
                          src={item}
                          alt="ảnh"
                          className="w-full h-full rounded-md"
                        />
                      </div>
                    );
                  })}
              </div>

              {/* tên sản phẩm input */}
              <div className="w-full sm-480:mb-12 mb-[30px]">
                <LableContent content="Project" />
                <input
                  value={projectinput.titleEn}
                  name="titleEn"
                  onChange={(event) => {
                    handleInput(event);
                  }}
                  type="text"
                  className="w-full px-5 py-3 sm-480:text-base text-sm focus:outline-none border border-border-gray rounded-md"
                />
              </div>

              {/* mô tả sản phẩm input */}

              <div className="w-full sm-480:mb-12 mb-[30px]">
                <LableContent content="Project description" />
                <textarea
                  rows={10}
                  cols={100}
                  value={projectinput.descriptionEn}
                  name="descriptionEn"
                  onChange={(event) => {
                    handleInput(event);
                  }}
                  className="p-3 focus:outline-text-primary sm-480:text-base text-sm rounded-[10px] border border-border-gray w-full"
                ></textarea>
              </div>

              <div className="w-full sm-480:mb-12 mb-[30px]">
                <LableContent content="Project Information" />

                <p className="mb-4 text-primarysm-480:text-base text-sm font-normal">
                  Project detail
                </p>
                <div className="w-full h-auto flex flex-col mb-7 border border-border-gray ">
                  <Editor
                    content={contentEn}
                    setContent={setContentEn}
                    editorRef={refNewEn}
                    listImageFiles={listImageFilesEn}
                  />
                </div>
              </div>

              <div className="text-right mb-[60px]">
                <button
                  ref={navigationPrevRef}
                  onClick={slideToTop}
                  className="2xl:py-[18px] py-[14px] mr-3 2xl:w-[180px] sm-390:w-[150px] w-[100px] border rounded-md border-text-lightred text-text-lightred font-medium sm-480:text-base text-sm text-center"
                >
                  Go back
                </button>
                <button
                  disabled={isdisAble}
                  onClick={handleSubmit}
                  className={clsx(
                    isdisAble && "cursor-not-allowed",
                    "2xl:py-[18px] cursor-pointer py-[14px] inline-block font-medium 2xl:w-[180px] sm-390:w-[150px] w-[100px] border rounded-md bg-primary text-white sm-480:text-base text-sm text-center"
                  )}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}

export default ProducSlideProject;
