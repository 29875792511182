import { ChangeEvent, Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineCaretDown } from "react-icons/ai";
import { NavLink, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import Menu from "../../Layout/Menu";
import { logout } from "../../reducers/auth";
import { logoutUser } from "../../reducers/user";
import { listLink } from "../../Router/routeListManager";
import { hostBE } from "../../types/host";

type typeProps = {
  onClickFunc?: () => void;
};

function SiderightContainer({ onClickFunc }: typeProps) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isShowMenu, setShowMenu] = useState<boolean>(false);
  const user = useAppSelector((state) => state.userSlice.currentUser);
  const { isEnglish } = useAppSelector((state) => state.translateSlice);
  const { currentUser } = useAppSelector((state) => state.userSlice);
  const [inputValueSearch, setInputValueSearch] = useState<string>("");
  const handlelogout = () => {
    dispatch(logout());
    dispatch(logoutUser());
    navigate("/");
  };
  const handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValueSearch(event.target.value);
  };
  const handleResSearch = () => {
    if (inputValueSearch != "") {
      const formatKey = inputValueSearch.replace(/ /g, "-");
      navigate(`/search?search=${formatKey}`);
      setInputValueSearch("");
      setShowMenu(false);
    }
  };
  const handleSearchKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const keyCode = event.keyCode;
    const keySearch = event.target.value;
    if (keySearch != "" && keyCode === 13) {
      const formatKey = keySearch.replace(/ /g, "-");
      navigate(`/search?search=${formatKey}`);
      setInputValueSearch("");
      setShowMenu(false);
    }
  };
  return (
    <div className="w-full h-full 2xl:pt-[74px] pt-5 sticky top-0 left-0 scrollbar overflow-y-scroll">
      <div className="w-full lg:hidden block">
        <div className="w-full py-3 px-7">
          <div className="w-full relative">
            <input
              value={inputValueSearch}
              onChange={(event) => {
                handleChangeInput(event);
              }}
              onKeyUp={(event) => {
                handleSearchKeyUp(event);
              }}
              className="w-full py-1 px-6 text-base  rounded-[30px] border border-solid border-primary focus:outline-none"
              type="text"
              placeholder={isEnglish ? "Tìm kiếm ..." : "Search"}
            />
            <img
              onClick={handleResSearch}
              src={`${hostBE}/fe/VectorSearch.png`}
              alt="search"
              className="w-4 h-4 absolute top-[50%] sm-390:right-6 right-3 translate-y-[-50%] "
            />
          </div>
        </div>
        <div className="flex items-center px-6 py-3 border-b  border-b-border-box">
          <img
            src={
              currentUser && currentUser.avatarUrl
                ? currentUser.avatarUrl
                : `${hostBE}/fe/loginImage.png`
            }
            alt="login"
            className="w-10 h-10 mr-4 rounded-[50%]"
          />
          <p className="cursor-pointer md:text-lg sm-480:text-base text-xs">
            {currentUser && currentUser.fullname}
          </p>
        </div>
      </div>
      <div className="px-2 2xl:hidden block cursor-pointer mb-2">
        <div
          onClick={() => setShowMenu(!isShowMenu)}
          className="w-full flex items-center px-5 pt-3"
        >
          <p className="text-lg font-normal mr-1">Menu</p>
          <AiOutlineCaretDown className={isShowMenu ? "rotate-180" : ""} />
        </div>
        {isShowMenu && <Menu isborder={true} />}
      </div>
      <ul className="w-full h-auto">
        {listLink.map((item, index) => {
          return (
            <li key={`menu${index}`} className="w-full">
              <NavLink
                onClick={() => onClickFunc?.()}
                to={item.path}
                className={({ isActive }) =>
                  isActive
                    ? "w-full py-6 flex items-center 2xl:text-base lg:text-sm text-px14 font-normal manager-active  relative pl-[30px]"
                    : "w-full py-6 flex items-center 2xl:text-base lg:text-sm text-px14 font-norma pl-[30px]"
                }
              >
                <div className="xl:mr-6 mr-4">
                  <img src={item.urlImage} alt="anh" />
                </div>
                <>{t(`${item.content}`)}</>
              </NavLink>
            </li>
          );
          // ) : (
          //   <Fragment key={`frang${index}`}></Fragment>
          // );
        })}
        <li
          onClick={handlelogout}
          className="w-full py-6 mt-2 mb-4 flex items-center text-base font-norma cursor-pointer pl-[30px]"
        >
          <div className="xl:mr-6 mr-4">
            <img src={`${hostBE}/fe/navdangxuat.png`} alt="logout" />
          </div>
          {t("header.menu.menuManager.logout")}
        </li>
      </ul>
    </div>
  );
}

export default SiderightContainer;
